import React from 'react';
import { Button, Typography, Card, CardContent, Box } from '@mui/material';
import { getPromptOutputFields } from '../../../utils/prompt';
import { useDialog } from '../../../context/dialogProvider';
import { DIALOG_TYPE_INFO } from '../../../constants/dialog';

function StepDisplay({ step, infoList, prompts }) {
  const { openDialog } = useDialog();

  return (
    <Box>
      <Card sx={{ mb: 2 }} >
        <CardContent>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Input Fields:
            </Typography>
            {step.inputFields.length > 0 ? (
              step.inputFields.map((field, idx) => (
                <Button
                  key={idx}
                  color="primary"
                  variant='outlined'
                  sx={{ ml: 2, mb: 1 }}
                  onClick={() => {
                    if (infoList) {
                      openDialog(DIALOG_TYPE_INFO, {
                        title: field.name,
                        description: infoList[field.name],
                      });
                    }
                  }}
                >
                  {field.name}
                </Button>
              ))
            ) : (
              <Typography variant='body2'>No Input Fields</Typography>
            )}
          </Box>
          <Typography variant="subtitle1">Prompt:</Typography>
          <Button
            color="secondary"
            variant='outlined'
            sx={{ ml: 2, mb: 1 }}
            onClick={() => {
              if (infoList) {
                openDialog(DIALOG_TYPE_INFO, {
                  title: step.promptID,
                  description: prompts.find(p => p.id === step.promptID).content,
                });
              }
            }}
          >
            {step.promptID}
          </Button>
          <Typography variant="subtitle1">Output Fields:</Typography>
          {getPromptOutputFields(prompts.find(p => p.id === step.promptID)).map((field, idx) => (
            <Button
              key={idx}
              color="success"
              variant='outlined'
              sx={{ ml: 2, mb: 1 }}
              onClick={() => {
                if (infoList) {
                  openDialog(DIALOG_TYPE_INFO, {
                    title: field,
                    description: infoList[field],
                  });
                }
              }}
            >
              {field}
            </Button>
          ))}
        </CardContent>
      </Card>
    </Box>
  );
}

export default StepDisplay;
