import React, { useState } from 'react';
import { Grid, Typography, Paper, Container, TableHead, Table, TableContainer, TableCell, TableBody, TableRow, Button, Box, Menu, MenuItem } from '@mui/material';
import authFetch from '../../utils/auth';
import { useLydia } from '../../context/lydiaProvider';
import { END_TIME, FLOW_METRICS, LAST_30_DAYS, LAST_3_DAYS, LAST_7_DAYS, LAST_90_DAYS, START_TIME, USER_METRICS } from '../../constants/lydia';
import DateTimeFilter from './dateTimeFilter';
import NewUserAmountLineChart from './newUserAmountLineChart';


const defaultParams = {
  userMetricsParams: {
    [START_TIME]: '',
    [END_TIME]: '',
  },
  flowMetricsParams: {
    [START_TIME]: '',
    [END_TIME]: '',
  },
};

const defaultMetrics = {
  userMetrics: {
    totalUserNum: 0,
    recentUserCreatedTimeList: [],
  },
  flowMetrics: {
    all: {
      all: '',
    },
  },
}

function Dashboard() {
  const { env } = useLydia();
  const [metrics, setMetrics] = useState(defaultMetrics);
  const [params, setParams] = useState(defaultParams);


  // useEffect(() => {
  //   fetchAllMetrics(env, defaultParams);
  // }, [env]);

  // const fetchAllMetrics = async (env, params) => {
  //   await fetchUserMetrics(env, params.userMetricsParams);
  //   await fetchFlowMetrics(env, params.flowMetricsParams);
  // };

  const fetchUserMetrics = async (env, params) => {
    authFetch('/lydia/get-user-metrics', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        params: {
          ...params,
          env: env,
        }
      })
    })
      .then((data) => {
        setMetrics(prevState => ({
          ...prevState,
          userMetrics: data,
        }));
      })
      .catch((error) => console.error("There was an error!", error));
  };

  const fetchFlowMetrics = async (env, params) => {
    authFetch('/lydia/get-flow-metrics', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        params: {
          ...params,
          env: env,
        }
      })
    })
      .then((data) => {
        setMetrics(prevState => ({
          ...prevState,
          flowMetrics: data,
        }));
      })
      .catch((error) => console.error("There was an error!", error));
  };

  const handleUserFilterInputChange = (e) => {
    const { name, value } = e.target;
    setParams(prevState => ({
      ...prevState,
      userMetricsParams: {
        ...prevState.userMetricsParams,
        [name]: value,
      }
    }));
  }

  const handleFlowFilterInputChange = (e) => {
    const { name, value } = e.target;
    setParams(prevState => ({
      ...prevState,
      flowMetricsParams: {
        ...prevState.flowMetricsParams,
        [name]: value,
      }
    }));
  };

  const handleShortcutsButtonClick = (metricType, buttonId) => {
    const now = new Date();
    let startTime = '';
    let endTime = '';

    if (buttonId === LAST_90_DAYS) {
      const last90Days = new Date(now);
      last90Days.setDate(now.getDate() - 90);
      startTime = last90Days.toISOString().split('T')[0];
      endTime = now.toISOString().split('T')[0];
    }
    else if (buttonId === LAST_30_DAYS) {
      const last30Days = new Date(now);
      last30Days.setDate(now.getDate() - 30);
      startTime = last30Days.toISOString().split('T')[0];
      endTime = now.toISOString().split('T')[0];
    } else if (buttonId === LAST_7_DAYS) {
      const last7Days = new Date(now);
      last7Days.setDate(now.getDate() - 7);
      startTime = last7Days.toISOString().split('T')[0];
      endTime = now.toISOString().split('T')[0];
    } else if (buttonId === LAST_3_DAYS) {
      const last3Days = new Date(now);
      last3Days.setDate(now.getDate() - 3);
      startTime = last3Days.toISOString().split('T')[0];
      endTime = now.toISOString().split('T')[0];
    }

    if (metricType === USER_METRICS) {
      setParams(prevState => ({
        ...prevState,
        userMetricsParams: {
          ...prevState.userMetricsParams,
          [START_TIME]: startTime,
          [END_TIME]: endTime,
        }
      }));
    } else if (metricType === FLOW_METRICS) {
      setParams(prevState => ({
        ...prevState,
        flowMetricsParams: {
          ...prevState.flowMetricsParams,
          [START_TIME]: startTime,
          [END_TIME]: endTime,
        }
      }));
    }
  }

  return (
    <Container sx={{ mt: 3 }}>
      <Paper elevation={3} sx={{ p: 2, marginBottom: 4 }}>
        <Typography variant="h5" component="h2" marginBottom={2}>User Metrics</Typography>
        <Box>
          <Box>
            <DateTimeFilter env={env} params={params.userMetricsParams} handleFilterInputChange={handleUserFilterInputChange} fetchMetrics={fetchUserMetrics} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'right' }}>
            <UserMetricsTimeRangeMenu handleShortcutsButtonClick={handleShortcutsButtonClick} />
          </Box>
          <Box>
            {metrics.userMetrics.recentUserCreatedTimeList && <NewUserAmountLineChart data={metrics.userMetrics.recentUserCreatedTimeList} />}
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" marginBottom={2}>Total users: {metrics.userMetrics.totalUserNum}</Typography>
          </Grid>
          <Grid item xs={8}>

          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h5" component="h2" marginBottom={4}>Flow Metrics</Typography>
        <DateTimeFilter env={env} params={params.flowMetricsParams} handleFilterInputChange={handleFlowFilterInputChange} fetchMetrics={fetchFlowMetrics} />
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="flow metrics table">
            <TableHead>
              <TableRow>
                <TableCell>Flow Distribution</TableCell>
                <TableCell align="right">Total</TableCell>
                <TableCell align="right">in-progress</TableCell>
                <TableCell align="right">generating</TableCell>
                <TableCell align="right">completed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(metrics.flowMetrics).map(([flowType, flowTypeMetrics]) => (
                <TableRow
                  key={flowType}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {flowType}
                  </TableCell>
                  {Object.entries(flowTypeMetrics).map(([status, count]) => (
                    <TableCell key={status} align="right">{count}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
}

function UserMetricsTimeRangeMenu({ handleShortcutsButtonClick }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    handleShortcutsButtonClick(USER_METRICS, event.currentTarget.id);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='contained'
      >
        Time Range Shortcuts
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose} id={LAST_90_DAYS}>LAST 90 DAYS</MenuItem>
        <MenuItem onClick={handleClose} id={LAST_30_DAYS}>LAST 30 DAYS</MenuItem>
        <MenuItem onClick={handleClose} id={LAST_7_DAYS}>LAST 7 DAYS</MenuItem>
        <MenuItem onClick={handleClose} id={LAST_3_DAYS}>LAST 3 DAYS</MenuItem>
      </Menu>
    </div>
  );
}

export default Dashboard;