const CV_TEMPLATE_TYPE_1_BTN = 'cv-template-type-1-button';
const CV_TEMPLATE_TYPE_2_BTN = 'cv-template-type-2-button'
const CV_TEMPLATE_TYPE_3_BTN = 'cv-template-type-3-button'

const BTN_TYPE_BASIC = "btn-type-basic";
const BTN_TYPE_MENU = "btn-type-menu";
const DOWNLOAD_BTN = "download-button";
const SEND_EMAIL_BTN = "send-email-button";
const EDIT_AS_NEW_BTN = "edit-flow-button";
const CHANGE_STYLE_BTN = "change-style-button";
const EXP_REVIEW_BTN = "exp-review-button";
const CHECK_INFO_BTN = "check-info-button";
const DOWNLOAD_PDF_BTN = "download-pdf-button";
const DOWNLOAD_DOC_BTN = "download-doc-button";

const CONTENT_TYPE_ARTICLE = "content-type-article";
const CONTENT_TYPE_REPORT = "content-type-report";

module.exports = {
    CV_TEMPLATE_TYPE_1_BTN,
    CV_TEMPLATE_TYPE_2_BTN,
    CV_TEMPLATE_TYPE_3_BTN,
    BTN_TYPE_BASIC,
    BTN_TYPE_MENU,
    DOWNLOAD_BTN,
    SEND_EMAIL_BTN,
    EDIT_AS_NEW_BTN,
    CHANGE_STYLE_BTN,
    EXP_REVIEW_BTN,
    CHECK_INFO_BTN,
    DOWNLOAD_PDF_BTN,
    DOWNLOAD_DOC_BTN,
    CONTENT_TYPE_ARTICLE,
    CONTENT_TYPE_REPORT
}