import React from 'react';
import { List, ListItem, ListItemText, Divider } from '@mui/material';

export function KeyValueList({ data }) {
  if (!data) {
    return <></>
  }
  return (
    <List>
      {Object.entries(data).map(([key, value], index) => (
        <React.Fragment key={key}>
          <ListItem>
            <ListItemText primary={key} secondary={value} sx={{ whiteSpace: 'pre-line' }} />
          </ListItem>
          {index < Object.entries(data).length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
}