import * as React from 'react';
import PricingDisplay from '../components/pricing/pricingDisplay';
import FullLayout from '../components/layout/full';
import { Helmet } from 'react-helmet';

export default function Pricing() {
    return (
        <>
            <Helmet>
                <meta name="description" content='查看服务价目表.' />
            </Helmet>
            <FullLayout mainContent={<PricingDisplay />} />
        </>
    );

}