import * as React from 'react';
import Box from '@mui/material/Box';

export default function FullLayout({ mainContent }) {
    return (
        <Box sx={{ display: 'flex', marginTop: '64px' }}>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    minHeight: `calc(100vh - 64px)`,
                }}
            >
                {mainContent}
            </Box>
        </Box>
    );
}