import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Typography, Button, Paper } from '@mui/material';
import { getQuestionDisplay } from '../../../utils/questionnaire/question';
import Question from '../../questionnaire/question';
import { AVAILABLE_LOCALES } from '../../../constants/locale';
import { useInternal } from '../../../context/internalProvider';

function QuestionPreview({ question, onEdit }) {
  const { questions } = useInternal();

  const [selectedLocale, setSelectedLocale] = useState('en');
  const [questionDisplay, setQuestionDisplay] = useState(getQuestionDisplay(question, selectedLocale, questions));

  useEffect(() => {
    setQuestionDisplay(getQuestionDisplay(question, selectedLocale, questions));
  }, [question, selectedLocale, questions]);

  const handleLocaleChange = (event, newValue) => {
    setSelectedLocale(newValue);
  };

  if (!question) {
    return <Typography>Select a question to view its details.</Typography>;
  }

  return (
    <Paper elevation={3} sx={{ p: 2, ml: 3, mt: 2, mb: 2, mr: 3, overflow: 'auto', maxHeight: '80vh' }}>
      <Tabs value={selectedLocale} onChange={handleLocaleChange} aria-label="Locale tabs" variant="scrollable" scrollButtons="auto">
        {AVAILABLE_LOCALES.map((locale) => (
          <Tab label={locale} value={locale} key={locale} />
        ))}
      </Tabs>

      <Question questionDisplay={questionDisplay} infoList={{}} validationErrors={{}} />

      {onEdit && <Button onClick={onEdit} variant="contained" color="primary" sx={{ mt: 2 }}>
        Edit Question
      </Button>}
    </Paper>
  );
}

export default QuestionPreview;