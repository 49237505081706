import React from 'react';
import { Typography } from '@mui/material';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

const MarkdownParser = ({ text, textType = null, textAlignType = null }) => {
    const getMarkdownText = () => {
        const rawMarkup = marked(text, { breaks: true });
        const sanitizedMarkup = DOMPurify.sanitize(rawMarkup);
        return { __html: sanitizedMarkup };
    };

    return (
        <Typography variant={textType ? textType : ''} dangerouslySetInnerHTML={getMarkdownText()} textAlign={textAlignType ? textAlignType : ''}/>
    );
};

export default MarkdownParser;
