import * as React from 'react';
import BasicLayout from '../components/layout/basic';
import { HomeProvider } from '../context/homeProvider';
import MainDrawerLeft from '../components/leftbar/mainDrawer';
import InternalManager from '../components/internalMgr/mgr';

export default function InternalMgr() {
    return (
        <HomeProvider>
            <BasicLayout mainContent={<InternalManager />} leftDrawer={<MainDrawerLeft />} />
        </HomeProvider>
    );
} 