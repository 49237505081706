import * as React from 'react';
import Investigation from '../components/lydia/investigation';
import { useLydia } from '../context/lydiaProvider';
import BasicLayout from '../components/layout/basic';
import MainDrawerLeft from '../components/leftbar/mainDrawer';
import { Box, FormControl, InputLabel, MenuItem, Select, Tab, Tabs } from '@mui/material';
import Dashboard from '../components/lydia/dashboard';

const TAB_INVESTIGATION = 0;
const TAB_DASHBOARD = 1;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function Lydia() {
  const { env, setEnv } = useLydia();
  const [selectedTab, setSelectedTab] = React.useState(TAB_INVESTIGATION);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return <BasicLayout mainContent={
    <Box sx={{ borderBottom: 1, borderColor: 'divider', ml: 5, mt: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Investigation" id='tab-investigation' />
          <Tab label="Dashboard" id='tab-dashboard' />
        </Tabs>
        <FormControl sx={{ minWidth: 120, mr: 5 }}>
          <InputLabel id="env-select-label">ENV</InputLabel>
          <Select
            labelId="env-select-label"
            id="env-select"
            value={env}
            label="ENV"
            onChange={(e) => setEnv(e.target.value)}
          >
            <MenuItem value={'development'}>Dev</MenuItem>
            <MenuItem value={'production'}>Prod</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <CustomTabPanel value={selectedTab} index={TAB_INVESTIGATION}>
        <Investigation />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={TAB_DASHBOARD}>
        <Dashboard />
      </CustomTabPanel>
    </Box>
  } leftDrawer={<MainDrawerLeft />} />
}