import { Rating, Box, Typography } from '@mui/material';
import * as React from 'react';
import authFetch from '../../utils/auth';

async function submitRating(flowId, rating) {
    await authFetch('/flow/submit-rating', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            flowid: flowId,
            rating: rating
        })
    });
}

export default function ArticleRating({ flowId, rating }) {
    const handleRatingChange = async (rating) => {
        await submitRating(flowId, rating);
        window.location.reload();
    };
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '30px',
            alignSelf: 'stretch',
            borderTop: '2px solid #F0F0EB',
            borderBottom: '2px solid #F0F0EB',
        }}>
            <Typography variant="subtitle1" color={'subtitle.secondary'}> {(
                rating.rating ? rating.successText : rating.displayText
            )} </Typography>
            <Rating name="half-rating" defaultValue={0} value={rating.rating ? rating.rating : 0} precision={0.5} size='large' disabled={rating.rating ? true : false} onChange={(event, newValue) => { handleRatingChange(newValue); }} />
        </Box>
    );
}