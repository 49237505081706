import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LanguageIcon from '@mui/icons-material/Language';
import authFetch from '../../utils/auth';
import UserAvatar from './avatar';
import ProfileDialogMenuItem from './profileDialog';
import { AVAILABLE_LOCALES, LOCALE_DISPLAYS } from '../../constants/locale';
import AdminDialogMenuItem from './adminDialog';
import logo from '../../assets/logo_black.png';
import { useAuth } from '../../context/authProvider';
import SigninDialog from '../dialogs/signinDialog';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Button, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getIcon } from '../../utils/assets';
import MenuIcon from '@mui/icons-material/Menu';

const appBarHeight = 64
const defaultIconWidth = 182
const mobileIconWidth = 90
const mobileWindowWidth = 900

function HomeAppBar() {
  const navigate = useNavigate();
  const { isLoggedIn, userLoggedIn, userLoggedOut, openSignInDialog, openSignIn, closeSignIn, accountRefresh } = useAuth();
  const [accountDisplay, setAccountDisplay] = React.useState({});
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [signInDisplay, setSignInDisplay] = React.useState({});
  React.useEffect(() => {
    fetchAccountDisplay(userLoggedIn, userLoggedOut);
  }, [userLoggedIn, userLoggedOut, accountRefresh]);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElLang, setAnchorElLang] = React.useState(null);
  const [anchorElPoints, setAnchorElPoints] = React.useState(null);
  const [iconWidth, setIconWidth] = React.useState(defaultIconWidth);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSignOut = async () => {
    const response = await authFetch('/logout');
    window.location.href = response.redirectUrl;
  }

  const handleSignIn = async () => {
    openSignInDialog();
  }

  const handleOpenLangMenu = (event) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseLangMenu = () => {
    setAnchorElLang(null);
  };

  const handleOpenPointsMenu = (event) => {
    setAnchorElPoints(event.currentTarget);
  };

  const handleClosePointsMenu = () => {
    setAnchorElPoints(null);
  };

  const handleTopUp = async () => {
    setIsLoading(true);
    try {
      await authFetch('/user/topup');
      fetchAccountDisplay(userLoggedIn, userLoggedOut);
    } catch (error) {
      console.error('Top up failed:', error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleBuyPoints = () => {
    navigate('/pricing');
  }

  const updateUsername = async (newUsername) => {
    setIsLoading(true);
    try {
      const response = await authFetch('/user/username-update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: newUsername,
        }),
      });
      fetchAccountDisplay(userLoggedIn, userLoggedOut);
      return response;
    } catch (error) {
      console.error('Username update failed:', error);
    } finally {
      setIsLoading(false);
    }
  }

  const updateSubscription = async () => {
    const response = await authFetch('/payment/create-portal-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    fetchAccountDisplay(userLoggedIn, userLoggedOut);
    return response;
  }

  function fetchAccountDisplay(userLoggedIn, userLoggedOut) {
    authFetch('/user/account-display')
      .then((data) => {
        setAccountDisplay(data.accountDisplay);
        if (data.isLoggedIn) {
          setIsAdmin(hasUserTag(data.accountDisplay.userAccountData, 'admin'));
          userLoggedIn();
        } else {
          setSignInDisplay(data.accountDisplay.signInDisplay);
          userLoggedOut();
        }
      })
      .catch((error) => console.error("There was an error!", error));
  }

  // Include a method to handle language change if necessary
  const handleChangeLanguage = (lang) => {
    authFetch(`/change-lang/${lang}`)
      .then((data) => {
        window.location.reload();
      })
      .catch((error) => console.error("There was an error!", error));
  };

  React.useEffect(() => {
    const handleResize = () => {
      calculateIconWidth();
    };
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const calculateIconWidth = () => {
    if (window.innerWidth < mobileWindowWidth) {
      setIconWidth(mobileIconWidth)
    } else {
      setIconWidth(defaultIconWidth)
    }

    console.log(window.innerWidth)
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" color='bg_skin' sx={{
        height: appBarHeight,
        boxShadow: 'none',
        // width: '100vw',
      }}>
        <Toolbar disableGutters>
          <Box sx={{
            ml: 5,
            mr: 2,
            display: 'flex',
            height: '32px',
            alignItems: 'center',
          }}>
            <Box
              sx={{
                cursor: 'pointer', // Changes cursor to indicate clickable
                '&:hover': {
                  opacity: 0.8 // Changes the opacity on hover
                }
              }}
              onClick={() => navigate('/')}
            >
              <img src={logo} alt='' style={{ verticalAlign: 'middle', width: iconWidth, height: 'auto' }} />
            </Box>
            <Divider orientation='vertical' variant='middle' sx={{ ml: iconWidth / 40 }} />
            {accountDisplay.homeOption && (
              <Button variant='contained' onClick={() => navigate(accountDisplay.homeOption.redirectUrl)} sx={{ boxShadow: 'none' }}>
                {accountDisplay.homeOption.displayText}
              </Button>
            )}
          </Box>
          {accountDisplay.appbarOptions && (
            <>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{ display: { xs: 'block', md: 'none' } }}
                >
                  {accountDisplay.appbarOptions.map((option) => (
                    <MenuItem key={option.displayText} onClick={handleCloseNavMenu}>
                      <Typography sx={{ textAlign: 'center' }} onClick={() => navigate(option.redirectUrl)}>{option.displayText}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {accountDisplay.appbarOptions.map((option) => (
                  <Button
                    key={option.displayText}
                    onClick={() => navigate(option.redirectUrl)}
                    sx={{ my: 2, color: 'inherit', display: 'block', mr: 3 }}
                  >
                    {option.displayText}
                  </Button>
                ))}
              </Box>
            </>
          )}
          <Box sx={{ flexGrow: 0, mr: 2 }}>
            {accountDisplay.toolTips && (
              <Tooltip title={accountDisplay.toolTips.changeLanguage}>
                <IconButton onClick={handleOpenLangMenu} color="inherit">
                  <LanguageIcon />
                </IconButton>
              </Tooltip>
            )}
            <Menu
              sx={{ mt: '45px' }}
              id="lang-menu-appbar"
              anchorEl={anchorElLang}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElLang)}
              onClose={handleCloseLangMenu}
            >
              {AVAILABLE_LOCALES.map((locale) => (
                <MenuItem key={locale} onClick={() => handleChangeLanguage(locale)}>{LOCALE_DISPLAYS[locale]}</MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 0, mr: { xs: 2, md: 20 } }}>
            {isLoggedIn ? (
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '15px',
              }}>
                <Box sx={{
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    transition: 'all 0.6s',
                  }
                }}>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <UserAvatar accountDisplay={accountDisplay} />
                  </IconButton>
                </Box>
                <Box onClick={handleOpenPointsMenu} sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  userSelect: 'none',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    transition: 'all 0.6s',
                  }
                }}>
                  <img src={getIcon('icon-coins', '@2x')} height={'20px'} alt='coins'></img>
                  <Typography variant='appBar_price' textAlign='center' color='primary.main' >
                    {accountDisplay.userAccountData?.points + accountDisplay.userAccountData?.subscriptionPoints}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Button onClick={handleSignIn} sx={{ p: 0, minWidth: '100px' }} color="inherit">
                <AccountCircleOutlinedIcon sx={{ mr: 1 }} />
                <Typography variant='body1'>
                  {accountDisplay.buttonInfo && accountDisplay.buttonInfo.loginBtnText}
                </Typography>
              </Button>
            )}
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {isAdmin && accountDisplay && <AdminDialogMenuItem itemText={accountDisplay.avatarOptions.adminConsole.displayText} accountDisplay={accountDisplay} handleTopUp={handleTopUp} isLoading={isLoading} />}
              {accountDisplay.avatarOptions && <ProfileDialogMenuItem itemText={accountDisplay.avatarOptions.profile.displayText} accountDisplay={accountDisplay} updateUsername={updateUsername} updateSubscription={updateSubscription} isLoading={isLoading} />}
              {accountDisplay.avatarOptions && <MenuItem onClick={handleSignOut}>{accountDisplay.avatarOptions.logout.displayText}</MenuItem>}
            </Menu>
            <Menu
              sx={{ mt: '45px' }}
              id="points-menu-appbar"
              anchorEl={anchorElPoints}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElPoints)}
              onClose={handleClosePointsMenu}
              autoFocus={false}
            >
              {accountDisplay.pointsOptions && (<MenuItem onClick={handleBuyPoints} autoFocus={false}>{accountDisplay.pointsOptions.buyPoints.displayText}</MenuItem>)}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <SigninDialog open={openSignIn} handleClose={closeSignIn} signInDisplay={signInDisplay} />
    </Box>
  );
}

function hasUserTag(userAccountData, userTag) {
  return userAccountData && userAccountData.userTags && userAccountData.userTags.includes(userTag);
}

export default HomeAppBar;