import React, { useEffect, useState } from 'react';
import { Box, Button, MenuItem, Typography, TextField, IconButton, Stack, Divider } from '@mui/material';
import authFetch from '../../../utils/auth';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { QTYPE_QUESTION_GROUP, QTYPE_SINGLE_SELECT } from '../../../constants/question';
import { getAllSectionIDs } from '../../../utils/questionnaire/section';
import { useInternal } from '../../../context/internalProvider';
import { useDialog } from '../../../context/dialogProvider';

const defaultGenerationConfig = {
  default: '',
  exceptions: [],
}

const defaultException = {
  conditions: [],
  value: '',
}

const defaultCondition = {
  field: '',
  value: '',
}

function FlowGenerationBuilder({ flowConfigProp, onSubmit }) {
  const { showSuccessMessage, showErrorMessage } = useDialog();
  const { sections, questions, generations } = useInternal();
  const [currentGenerationConfig, setCurrentGenerationConfig] = useState(defaultGenerationConfig);
  const [flowQuestionIDs, setFlowQuestionIDs] = useState(new Set());
  const [flowSectionIDs, setFLowSectionIDs] = useState([]);

  const updateFlowQuestionIDs = (fc, sections, questions) => {
    const sectionIDs = getAllSectionIDs(fc.startingSectionID, sections);
    const flowSections = sections.filter(s => sectionIDs.has(s.id));
    let qIDs = new Set();
    flowSections.forEach(s => {
      s.questionIDs.forEach(qID => {
        const q = questions.find(qq => qq.id === qID);
        if (q.type === QTYPE_QUESTION_GROUP) {
          q.childQuestionIDs.forEach(cqID => {
            qIDs.add(cqID);
          })
        } else {
          qIDs.add(qID);
        }
      })
    });
    setFlowQuestionIDs(qIDs);
  }

  useEffect(() => {
    if (flowConfigProp) {
      const sIDs = getAllSectionIDs(flowConfigProp.startingSectionID, sections);
      setFLowSectionIDs([...sIDs]);
      if (flowConfigProp.generationConfig) {
        setCurrentGenerationConfig(flowConfigProp.generationConfig);
      }
      updateFlowQuestionIDs(flowConfigProp, sections, questions)
    }
  }, [flowConfigProp, sections, questions]);

  const handleSelectExceptionValue = (val, exIdx) => {
    const updatedExceptions = currentGenerationConfig.exceptions.map((ex, idx) => {
      if (idx === exIdx) {
        return { ...ex, value: val };
      }
      return ex;
    });
    setCurrentGenerationConfig(prev => ({
      ...prev,
      exceptions: updatedExceptions,
    }));
  }

  const handleAddException = () => {
    setCurrentGenerationConfig(prev => {
      return {
        ...prev,
        exceptions: [...prev.exceptions, defaultException]
      };
    });
  }

  const handleRemoveException = (exIdx) => {
    const updatedExceptions = currentGenerationConfig.exceptions.filter((ex, idx) => idx !== exIdx);
    setCurrentGenerationConfig(prev => ({
      ...prev,
      exceptions: updatedExceptions,
    }));
  };

  const handleConditionFieldChange = (val, exIdx, cIdx) => {
    const updatedExceptions = currentGenerationConfig.exceptions.map((ex, idx) => {
      if (idx === exIdx) {
        const updatedConditions = ex.conditions.map((c, i) => {
          if (i === cIdx) {
            return { ...c, field: val, value: '' };
          }
          return c;
        });
        return { ...ex, conditions: updatedConditions };
      }
      return ex;
    });
    setCurrentGenerationConfig(prev => ({
      ...prev,
      exceptions: updatedExceptions,
    }));
  }

  const handleConditionValueChange = (val, exIdx, cIdx) => {
    const updatedExceptions = currentGenerationConfig.exceptions.map((ex, idx) => {
      if (idx === exIdx) {
        const updatedConditions = ex.conditions.map((c, i) => {
          if (i === cIdx) {
            return { ...c, value: val };
          }
          return c;
        });
        return { ...ex, conditions: updatedConditions };
      }
      return ex;
    });
    setCurrentGenerationConfig(prev => ({
      ...prev,
      exceptions: updatedExceptions,
    }));
  }

  const handleAddCondition = (exIdx) => {
    const updatedExceptions = currentGenerationConfig.exceptions.map((ex, idx) => {
      if (idx === exIdx) {
        return { ...ex, conditions: [...ex.conditions, defaultCondition] };
      }
      return ex;
    });
    setCurrentGenerationConfig(prev => ({
      ...prev,
      exceptions: updatedExceptions,
    }));
  }

  const handleRemoveCondition = (exIdx, cIdx) => {
    const updatedExceptions = currentGenerationConfig.exceptions.map((ex, idx) => {
      if (idx === exIdx) {
        return { ...ex, conditions: ex.conditions.filter((c, i) => i !== cIdx) };
      }
      return ex;
    });
    setCurrentGenerationConfig(prev => ({
      ...prev,
      exceptions: updatedExceptions,
    }));
  };

  const handleSave = async () => {
    try {
      let fc = { ...flowConfigProp, generationConfig: currentGenerationConfig };
      const response = await authFetch('/dev/update-flowconfig', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ flowConfig: fc })
      });

      if (response.isSuccess === true) {
        showSuccessMessage('Flow config generation updated successfully!');
        setCurrentGenerationConfig(defaultGenerationConfig);
        onSubmit();
      } else {
        console.error("Invalid response!", response);
        showErrorMessage("Something went wrong.");
      }
    } catch (error) {
      console.error("There was an error!", error);
      showErrorMessage(error.message || "An unknown error occurred.");
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minWidth: 500,
      maxWidth: '100%', // Ensures responsiveness
      margin: 'auto',
      p: 5,
    }}>
      <Typography variant="h6" gutterBottom component="div" sx={{ textAlign: 'center' }}>
        Edit Flow Generation
      </Typography>

      <Typography variant="subtitle1" gutterBottom sx={{ mt: 2, fontWeight: 'bold' }}>
        Default Generation
      </Typography>
      <TextField sx={{ mt: 1 }}
        label="Default Generation"
        name="default-generation"
        select
        required
        fullWidth
        value={currentGenerationConfig.default}
        onChange={e => setCurrentGenerationConfig({ ...currentGenerationConfig, default: e.target.value })}
        helperText="Default generation plan if all the exceptions are not triggered"
      >
        {generations.filter(g => g.flowConfigID === flowConfigProp?.id).map(g => g.id).map(gID => (
          <MenuItem key={gID} value={gID}>
            {gID}
          </MenuItem>
        ))}
      </TextField>

      {currentGenerationConfig.exceptions.map((ex, exIdx) => (
        <Box sx={{ mt: 2 }} key={`ex-${exIdx}`}>
          <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }} >
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
              {`Exception ${exIdx + 1}`}
            </Typography>
            <IconButton onClick={() => handleRemoveException(exIdx)} color="error">
              <DeleteIcon />
            </IconButton>
          </Stack>
          <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="subtitle1" gutterBottom>
              Conditions
            </Typography>
            <Button startIcon={<AddIcon />} onClick={() => handleAddCondition(exIdx)} variant="text" size='small' sx={{ ml: 2 }}>
              Add
            </Button>
          </Stack>
          {ex.conditions.map((condition, cIdx) => (
            <Stack key={`ex-${exIdx}-cond-${cIdx}`} direction="row" sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <TextField sx={{ mr: 2 }}
                key={`ex-${exIdx}-cond-${cIdx}-field`}
                label="Condition Field"
                select
                required
                fullWidth
                value={currentGenerationConfig.exceptions[exIdx].conditions[cIdx].field}
                onChange={e => handleConditionFieldChange(e.target.value, exIdx, cIdx)}
              >
                <MenuItem key='section_id' value='currentSectionID' color='primary'>
                  current section ID
                </MenuItem>
                {questions.filter(q => (flowQuestionIDs.has(q.id) && q.type === QTYPE_SINGLE_SELECT)).map(q => q.id).map(qID => (
                  <MenuItem key={qID} value={qID}>
                    {qID}
                  </MenuItem>
                ))}
              </TextField>
              {currentGenerationConfig.exceptions[exIdx].conditions[cIdx].field !== '' && (
                <>
                  <Typography variant="h3" gutterBottom>
                    =
                  </Typography>
                  <TextField sx={{ ml: 2 }}
                    key={`ex-${exIdx}-cond-${cIdx}-value`}
                    label="Condition Value"
                    select
                    required
                    fullWidth
                    value={currentGenerationConfig.exceptions[exIdx].conditions[cIdx].value}
                    onChange={e => handleConditionValueChange(e.target.value, exIdx, cIdx)}
                  >
                    {currentGenerationConfig.exceptions[exIdx].conditions[cIdx].field !== 'currentSectionID'? (
                      questions.find(q => q.id === currentGenerationConfig.exceptions[exIdx].conditions[cIdx].field).options.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))
                    ) : (
                      flowSectionIDs.map(sID => (
                        <MenuItem key={sID} value={sID}>
                          {sID}
                        </MenuItem>
                      ))
                    )}
                  </TextField>
                </>
              )}
              <IconButton onClick={() => handleRemoveCondition(exIdx, cIdx)} color="error">
                <DeleteIcon />
              </IconButton>
            </Stack>
          ))}
          <TextField sx={{ mt: 3 }}
            key={`ex-${exIdx}-value`}
            label="Exception Generation"
            select
            required
            fullWidth
            value={currentGenerationConfig.exceptions[exIdx].value}
            onChange={e => handleSelectExceptionValue(e.target.value, exIdx)}
            helperText="Generation plan if conditions of this exception are met"
          >
            {generations.filter(g => g.flowConfigID === flowConfigProp.id).map(g => g.id).map(gID => (
              <MenuItem key={gID} value={gID}>
                {gID}
              </MenuItem>
            ))}
          </TextField>
          <Divider sx={{ mt: 2 }} />
        </Box>
      ))}

      <Button onClick={handleAddException} startIcon={<AddCircleOutlineIcon />} variant='outlined' sx={{ mt: 2 }}>
        Add Exception
      </Button>

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button fullWidth onClick={handleSave} variant="contained" color="primary">
          Update Flow Config
        </Button>
      </Box>
    </Box>
  );
}

export default FlowGenerationBuilder;
