import React, { createContext, useContext, useState, useEffect } from 'react';
import authFetch from '../utils/auth';
import { auth } from '../utils/firebaseConfig';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [useFirebase, setUseFirebase] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openSignIn, setOpenSignIn] = React.useState(false);
  const [accountRefresh, setAccountRefresh] = useState(0);
  const [isMobileAccess, setIsMobileAccess] = useState(false);

  const openSignInDialog = () => {
    setOpenSignIn(true);
  }
  const closeSignIn = () => {
    setOpenSignIn(false);
  }

  const userLoggedIn = () => {
    setIsLoggedIn(true);
  }
  const userLoggedOut = () => {
    setIsLoggedIn(false);
  }

  const refreshAccountDisplay = () => {
    setAccountRefresh(accountRefresh + 1);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      setIsMobileAccess(true);
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setIsMobileAccess(true);
    } else {
      setIsMobileAccess(false);
    }
  }, []);

  function finishSignIn(user) {
    user.getIdToken().then((idToken) => {
      authFetch('/login-callback/firebase', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken }),
        credentials: 'include'
      })
        .then(data => {
          if (data.success) {
            setUseFirebase(true);
            setIsLoggedIn(true);
            window.location.href = '/home';
          } else {
            window.location.href = '/';
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    });
  }

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        finishSignIn(user);
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
      });
  };

  const signInWithAuthing = () => {
    authFetch('/login-authing', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(data => {
        if (data.redirectUrl) {
          setIsLoggedIn(true);
          window.location.href = data.redirectUrl;
        } else {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <AuthContext.Provider value={{
      user, loading, isLoggedIn, openSignIn, accountRefresh, isMobileAccess,
      openSignInDialog, closeSignIn, userLoggedIn, userLoggedOut, signInWithGoogle, signInWithAuthing, refreshAccountDisplay
    }}>
      {!(useFirebase && loading) && children}
    </AuthContext.Provider>
  );
};
