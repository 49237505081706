
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import PricingTableSection from './pricingTableSection';

export default function PricingTable({ pricingTable }) {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            ml: '80px',
        }}>
            <Typography variant="h4" sx={{ color: 'primary.main', mb: '50px' }}>{pricingTable.title}</Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '1000px',
                bgcolor: 'bg_dark.main',
                borderRadius: '10px',
            }}>
                <Grid container sx={{ padding: '5px 20px 5px 20px' }}>
                    {Object.keys(pricingTable.properties).map((property, index) => (
                        <Grid key={index} item xs={pricingTable.properties[property].columnWidth}>
                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>{pricingTable.properties[property].displayText}</Typography>
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '1180px',
                    transform: 'translateX(-70px)',
                }}>
                    {Object.keys(pricingTable.categories).map((category, index) => (
                        <PricingTableSection key={index} category={pricingTable.categories[category]} property={pricingTable.properties}/>
                    ))}
                </Box>
                <Grid container sx={{ padding: '5px 20px 5px 20px' }}>
                    {Object.keys(pricingTable.properties).map((property, index) => (
                        <Grid key={index} item xs={3}>
                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>{pricingTable.properties[property].displayText}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
}