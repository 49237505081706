import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

export default function ExpertReviewDialog({ open, handleClose, handleConfirm, content }) {
  const [inboxAddress, setInboxAddress] = React.useState(content.userEmail);
  const [message, setMessage] = React.useState('');
  const [currencyType, setCurrencyType] = React.useState('USD');

  const handleEmailChange = (event) => {
    setInboxAddress(event.target.value);
  }
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const handleCurrencyChange = (event) => {
    setCurrencyType(event.target.value);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          const email = formJson.inboxAddress;
          handleConfirm([email, message, currencyType]);
          handleClose();
        },
      }}
      sx={{
        '& .MuiDialog-paper': {
          minWidth: 700,
        },
      }}
    >
      <DialogTitle>
        {content.title}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <List>
              <ListItem>
                <Typography variant="body1">{content.description}</Typography>
              </ListItem>
              {content.service1 && (
                <ListItem>
                  <SentimentSatisfiedAltIcon color='success' sx={{ mr: 1, ml: -0.5 }} />
                  <Typography variant="body1">{content.service1}</Typography>
                </ListItem>
              )}
              {content.service2 && (
                <ListItem>
                  <TrackChangesIcon color='success' sx={{ mr: 1, ml: -0.5 }} />
                  <Typography variant="body1">{content.service2}</Typography>
                </ListItem>
              )}
              {content.service3 && (
                <ListItem>
                  <ThumbUpOffAltIcon color='success' sx={{ mr: 1, ml: -0.5 }} />
                  <Typography variant="body1">{content.service3}</Typography>
                </ListItem>
              )}
            </List>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 2 }}>
              {content.emailLabel}
            </Typography>
            <TextField
              autoFocus
              required
              margin="dense"
              id="inbox-address-expert-review"
              name="inboxAddress"
              fullWidth
              variant="outlined"
              value={inboxAddress}
              placeholder={content.userEmail}
              onChange={handleEmailChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="message"
              label={content.messageLabel}
              type="text"
              fullWidth
              multiline
              rows={6}
              variant="outlined"
              value={message}
              placeholder={content.messagePlaceholder}
              onChange={handleMessageChange}
            />
            <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 2 }}>
              {content.reminder1}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {content.reminder2}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Box>
              <Typography variant="h5" color="primary" sx={{ pr: 1 }}>
                {content.prices.allPriceTexts[currencyType]}
              </Typography>
            </Box>
            <Box>
              <FormControl size='small' sx={{
                mt: 2,
              }}>
                <Select
                  labelId="currency-type-select-label"
                  id="currency-type-select"
                  value={currencyType}
                  onChange={handleCurrencyChange}
                  sx={{
                    width: 120,
                  }}
                >
                  {Object.entries(content.prices.currencies).map(([key, value]) => (
                    <MenuItem key={key} value={key}>{value}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' sx={{ boxShadow: 'none' }} onClick={() => handleClose()}>{content.cancelBtn}</Button>
        <Button variant='contained' sx={{ boxShadow: 'none' }} type='submit'>{content.confirmBtn}</Button>
      </DialogActions>
    </Dialog>
  );
}