const LAST_90_DAYS = "last90days";
const LAST_30_DAYS = "last30days";
const LAST_7_DAYS = "last7days";
const LAST_3_DAYS = "last3days";
const START_TIME = "startTime";
const END_TIME = "endTime";

const USER_METRICS = "userMetrics";
const FLOW_METRICS = "flowMetrics";

export {
    LAST_90_DAYS,
    LAST_30_DAYS,
    LAST_7_DAYS,
    LAST_3_DAYS,
    START_TIME,
    END_TIME,
    USER_METRICS,
    FLOW_METRICS,
};