import * as React from 'react';
import QuestionnaireManager from '../components/internal/questionnaireManager';
import InternalProvider from '../context/internalProvider';
import BasicLayout from '../components/layout/basic';
import MainDrawerLeft from '../components/leftbar/mainDrawer';

export default function Dev() {
  return <BasicLayout mainContent={
    <InternalProvider>
      <QuestionnaireManager />
    </InternalProvider>
  } leftDrawer={<MainDrawerLeft />}/>
}