import { Box, Skeleton } from "@mui/material";

export default function FlowCardSkeleton({ count = 4 }) {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 2,
        }}>
            {[...Array(count)].map((_, i) => (
                <Skeleton key={i} variant="rectangular" width={190} height={210} animation='wave' sx={{ borderRadius: 1 }} />
            ))}
            
        </Box>
    )
}