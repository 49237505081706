import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { FLOWTYPE_CV, FLOWTYPE_CVE, FLOWTYPE_CVP, FLOWTYPE_PSE, FLOWTYPE_RLE, FLOWTYPE_SS } from '../../constants/flow';
import CVTemplateV1 from '../dataDisplay/cvTemplate';
import CVTemplateV2 from '../dataDisplay/cvTemplate2';
import { CONTENT_TYPE_REPORT, CV_TEMPLATE_TYPE_1_BTN, CV_TEMPLATE_TYPE_2_BTN, CV_TEMPLATE_TYPE_3_BTN } from '../../constants/article';
import CVTemplateV3 from '../dataDisplay/cvTemplate3';
import MarkdownParser from '../dataDisplay/markdown';
import ReportTemplate from '../dataDisplay/reportTemplate';

export default function ArticleContent({ content, flowType, template, contentTypeIdentifier }) {
  return (<Box> {getContentByFlowType(content, flowType, template, contentTypeIdentifier)} </Box>);
}

function getAnalysisContent(content, flowType) {
  try {
    const polishContent = polishReportFormatter(JSON.parse(content));
    return <ReportTemplate content={polishContent} flowType={flowType} />;
  } catch (error) {
    return defaultDisplay(content);
  }
}

function getCVDisplay(resumeData, cvTemplateType) {
  switch (cvTemplateType) {
    case CV_TEMPLATE_TYPE_1_BTN:
      return <CVTemplateV1 resumeData={resumeData} />;
    case CV_TEMPLATE_TYPE_2_BTN:
      return <CVTemplateV2 resumeData={resumeData} />;
    case CV_TEMPLATE_TYPE_3_BTN:
      return <CVTemplateV3 resumeData={resumeData} />;
    default:
      return <CVTemplateV1 resumeData={resumeData} />;
  }
}

function getContentByFlowType(content, flowType, cvTemplateType, contentTypeIdentifier) {
  if (contentTypeIdentifier === CONTENT_TYPE_REPORT) {
    return getAnalysisContent(content, flowType);
  }
  switch (flowType) {
    case 'internal_test_1':
    case FLOWTYPE_CV:
    case FLOWTYPE_CVP:
      try {
        const resumeContent = JSON.parse(content);
        return getCVDisplay(resumeContent, cvTemplateType);
      } catch (e) {
        return defaultDisplay(content);
      }
    case FLOWTYPE_PSE:
    case FLOWTYPE_RLE:
    case FLOWTYPE_CVE:
      try {
        const evaluationContent = JSON.parse(content);
        if (!evaluationContent.evaluation_report.sections || !evaluationContent.evaluation_report.sections[0].criteria || !evaluationContent.evaluation_report.summary) {
          return defaultDisplay(content);
        }
        return <ReportTemplate content={evaluationContent} flowType={flowType} />;
      } catch (e) {
        return defaultDisplay(content);
      }
    case FLOWTYPE_SS:
      return <MarkdownParser text={content} />
    default:
      return defaultDisplay(content);
  }
}

function defaultDisplay(content) {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      alignSelf: 'center',
    }}>
      {content.split('\n').map((line, index) => (
        <Typography key={index} variant="article" gutterBottom sx={{ width: '100%', textAlign: 'justify' }}> {line} </Typography>
      ))}
    </Box>
  );
}

function polishReportFormatter(content) {
  const detailedComparativeAnalysis = content.detailedComparativeAnalysis;
  const newAnalysis = {};
  Object.entries(detailedComparativeAnalysis).forEach(([key, value]) => {
    if (!newAnalysis[value.section]) {
      newAnalysis[value.section] = [];
    }
    newAnalysis[value.section].push(value);
  });

  content.detailedComparativeAnalysis = newAnalysis;
  return content;
}