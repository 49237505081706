import React, { useState, useEffect, createContext, useContext } from 'react';
import authFetch from '../utils/auth';

const LydiaContext = createContext();

export const useLydia = () => useContext(LydiaContext);

const LydiaProvider = ({ children }) => {

  const [env, setEnv] = useState('development');
  const [prompts, setPrompts] = useState([]);
  const [generations, setGenerations] = useState([]);

  const fetchPrompts = () => {
    authFetch('/dev/list-prompts')
      .then((data) => {
        setPrompts(data.prompts);
      })
      .catch((error) => console.error("There was an error!", error));
  };

  const fetchGenerations = () => {
    authFetch('/dev/list-generations')
      .then((data) => {
        setGenerations(data.generations);
      })
      .catch((error) => console.error("There was an error!", error));
  };

  useEffect(() => {
    fetchPrompts();
    fetchGenerations();
  }, []);

  return (
    <LydiaContext.Provider value={{ generations, prompts, fetchGenerations, fetchPrompts, env, setEnv }}>
      {children}
    </LydiaContext.Provider>
  );

}

export default LydiaProvider;
