import { Badge, Box, Button, Dialog, DialogContent, DialogTitle, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { BackDropLoading } from '../loading/backDropLoading';
import { getIcon } from '../../utils/assets';
import UserAvatar from './avatar';
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';

export default function ProfileDialogMenuItem({ itemText, accountDisplay, updateUsername, updateSubscription, isLoading }) {
    const [open, setOpen] = React.useState(false);
    const [editingId, setEditingId] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [isEditConfirmDisabled, setIsEditConfirmDisabled] = React.useState(false);
    const profileInfo = accountDisplay.profileInfo;
    const userAccountData = accountDisplay.userAccountData;
    const buttonInfo = accountDisplay.buttonInfo;
    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleEditClick = (id, currentValue) => {
        setEditingId(id);
        setInputValue(currentValue);
    }
    const handleInputChange = (event) => {
        setIsEditConfirmDisabled((event.target.value.length < 1 || event.target.value.length > 20) ? true : false);
        setInputValue(event.target.value);
    }
    const handleInputConfirm = async (id) => {
        await handleAction(id);
        setEditingId(null);
    }
    const handleInputCancel = () => {
        setEditingId(null);
    }
    const handleAction = async (actionId) => {
        switch (actionId) {
            case 'username':
                const newUsername = inputValue;
                if (newUsername) {
                    await updateUsername(newUsername);
                }
                break;
            case 'subscription_points':
            case 'points':
                setOpen(false);
                navigate('/pricing');
                break;
            case 'subscription_status':
                await updateSubscription();
                break;
            default:
                console.log('default');
                break;
        }
    }
    return (
        <React.Fragment>
            <MenuItem key={itemText} onClick={handleClickOpen}>
                <Typography textAlign="center">{itemText}</Typography>
            </MenuItem>
            <Dialog open={open} onClose={handleClose} fullWidth={true} sx={{
                '& .MuiDialog-paper': {
                    display: 'flex',
                    width: '500px',
                    padding: '50px 0',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '40px',
                    borderRadius: '40px',
                    background: '#FFF',
                    overflow: 'hidden',
                },
            }}>
                {isLoading && <BackDropLoading />}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        color: "primary.contrastText",
                        zIndex: 1000,
                    }}
                >
                    <img src={getIcon('icon-close')} alt='' height={'20px'}/>
                </IconButton>
                <Box
                    sx={{
                        width: '455px',
                        height: '455px',
                        position: 'absolute',
                        right: '-215px',
                        top: '-220px',
                        borderRadius: '455px',
                        background: '#ffecc066',
                        mixBlendMode: 'multiply'
                    }}
                ></Box>
                <DialogTitle variant="h5" textAlign="center" color={'primary.main'}>
                    {profileInfo && profileInfo.title}
                </DialogTitle>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                    }}
                >
                    <Box>
                        <UserAvatar accountDisplay={accountDisplay} size={100} />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '& .MuiBadge-badge': {
                            zIndex: 0,
                            opacity: 0.5,
                        },
                    }}>
                        <Badge
                            badgeContent={<StarsRoundedIcon fontSize='medium' color={userAccountData && userAccountData.isSubscribed ? 'success' : 'secondary'} />}
                            size="large">
                            <Box sx={{ width: '100%'}}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {userAccountData && userAccountData.displayName}
                                </Typography>
                            </Box>
                        </Badge>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                            {userAccountData && userAccountData.email}
                        </Typography>
                    </Box>
                </Box>

                <DialogContent sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    gap: 4,
                }}>
                    {buttonInfo && profileInfo && profileInfo.infoItems.map((info) => (
                        <Box
                            key={info.id}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'left', 
                                height: '60px', 
                                width: '100%', 
                                fullWidth: true,
                                overflowWrap: 'break-word', // Ensures long text wraps
                            }}
                        >
                            <Typography variant="subtitle1"> {info.keyDisplay} </Typography>

                            <Box sx={{
                                border: '2px solid #F0F0EB',
                                borderRadius: '10px',
                                padding: 1,
                                height: 'auto',
                                gap: 1,
                            }}>
                                {editingId === info.id ? (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        gap: 0.5,
                                        padding: 1,
                                        width: '100%',
                                        height: '30px',
                                    }}>
                                        <TextField
                                            size="small"
                                            value={inputValue}
                                            onChange={(event) => handleInputChange(event)}
                                            autoFocus
                                            fullWidth
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        border: 'none',  // Remove the border
                                                    },
                                                    '&:hover fieldset': {
                                                        border: 'none'  // Ensure the border is removed even on hover
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        border: 'none'  // Ensure the border is removed when the field is focused
                                                    },
                                                },
                                                '& .MuiInputBase-input': {
                                                    fontFamily: '"Alibaba PuHuiTi", sans-serif', // Set the font family
                                                    fontSize: '16px',
                                                    color: '#686865',
                                                }
                                            }}
                                        />
                                        <Box sx={{ display: 'flex', flexDirection: 'row', maxWidth: '30%', height: '30px', gap: 0.5 }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size='small'
                                                sx={{ boxShadow: 'none' }}
                                                onClick={() => handleInputConfirm(info.id)}
                                                disabled={isEditConfirmDisabled}> {buttonInfo.confirmBtnText} </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size='small'
                                                sx={{ boxShadow: 'none' }}
                                                onClick={() => handleInputCancel()}> {buttonInfo.cancelBtnText} </Button>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                overflowWrap: 'break-word', // Ensures long text wraps
                                                fontWeight: 'bold',
                                                height: '30px',
                                                color: 'subtitle.secondary',
                                            }}
                                        >
                                            {info.valueDisplay}
                                            {info.isEditable && <IconButton aria-label="edit" onClick={() => handleEditClick(info.id, info.valueDisplay)}>
                                                <img src={getIcon('icon-edit@2x')} alt='' height={'20px'}/>
                                            </IconButton>}
                                            {info.isPoints && <IconButton aria-label="add" onClick={() => handleAction(info.id)}>
                                                    <img src={getIcon('icon-wallet@2x')} alt='' height={'20px'} />
                                            </IconButton>}
                                            {info.isSubscribed && <IconButton aria-label="settings" onClick={() => handleAction(info.id)}>
                                                    <img src={getIcon('icon-setting@2x')} alt='' height={'20px'} />
                                            </IconButton>}
                                            {info.isCopyable && info.valueDisplay !== '-' && <IconButton aria-label="copy" onClick={() => navigator.clipboard.writeText(info.valueDisplay)}>
                                                    <img src={getIcon('icon-copy@2x')} alt='' height={'20px'} />
                                            </IconButton>}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    ))}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}