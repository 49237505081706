import * as React from 'react';
import { useParams } from 'react-router-dom';
import authFetch from '../utils/auth';
import { actionHandler, createAction, fetchDialogInfo } from '../utils/action';
import { useDialog } from './dialogProvider';
import { DIALOG_TYPE_CONSUMPTION_CONFIRM, DIALOG_TYPE_EXPERT_REVIEW, DIALOG_TYPE_QUESTIONNAIRE_VIEWER, DIALOG_TYPE_RENAME_DOC, DIALOG_TYPE_SEND_EMAIL } from '../constants/dialog';
import { CV_TEMPLATE_TYPE_1_BTN, CV_TEMPLATE_TYPE_2_BTN, CV_TEMPLATE_TYPE_3_BTN, DOWNLOAD_DOC_BTN, DOWNLOAD_PDF_BTN } from '../constants/article'

const ArticleContext = React.createContext();

export const useArticle = () => React.useContext(ArticleContext);

export const ArticleProvider = ({ children }) => {
  const { flowid } = useParams();
  const [docName, setDocName] = React.useState(null);
  const [content, setContent] = React.useState(null);
  const [analysis, setAnalysis] = React.useState(null);
  const [btnGroups, setBtnGroups] = React.useState(null);
  const [rating, setRating] = React.useState(null);
  const [userEmail, setUserEmail] = React.useState(null);
  const [flowType, setFlowType] = React.useState(null);
  const [displayTexts, setDisplayTexts] = React.useState({});
  const [cvTemplateType, setCVTemplateType] = React.useState(CV_TEMPLATE_TYPE_1_BTN);

  const contentRef = React.useRef();

  const { openDialog, setIsLoading } = useDialog();

  const fetchArticleDisplay = (flowid) => {
    authFetch(`/article/article-display/${flowid}`)
      .then(data => {
        setContent(data.article.content);
        setAnalysis(data.analysis);
        setDocName(data.article.docName);
        setBtnGroups(data.btnGroups);
        setRating(data.rating);
        setUserEmail(data.userInfo.userEmail);
        setFlowType(data.flowType);
        setDisplayTexts(data.displayTexts);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  React.useEffect(() => {
    fetchArticleDisplay(flowid);
  }, [flowid]);

  const handleCVTemplateTypeChange = (event) => {
    setCVTemplateType(event.target.value);
  }

  const handleRenameDocButtonClick = async () => {
    let request = {
      dialogType: DIALOG_TYPE_RENAME_DOC,
    }
    const attachedData = {
      flowId: flowid,
      docName: docName,
      userEmail: userEmail,
      flowType: flowType,
    }
    const optionId = 'rename-doc-button';
    fetchDialogInfo(request, async (info) => {
      info.docName = attachedData.docName;
      openDialog(DIALOG_TYPE_RENAME_DOC, info, async (newDocName) => {
        attachedData.newDocName = newDocName;
        const action = createAction(optionId, attachedData);
        const response = await actionHandler(action);
        if (response) {
          fetchArticleDisplay(attachedData.flowId);
        }
      })
    });
  }

  const handleArticleButtonClick = async (event, optionId, productIds = null) => {
    let request = {};
    const attachedData = {
      flowId: flowid,
      docName: docName,
      userEmail: userEmail,
      flowType: flowType,
    }
    let action = {};
    switch (optionId) {
      case 'change-style-button':
        request = {
          dialogType: DIALOG_TYPE_CONSUMPTION_CONFIRM,
          consumptionUrl: '/flow/change-style',
          flowType: flowType,
        }
        fetchDialogInfo(request, async (info) => {
          openDialog(DIALOG_TYPE_CONSUMPTION_CONFIRM, info, async () => {
            action = createAction(optionId, attachedData);
            setIsLoading(true);
            const response = await actionHandler(action);
            setIsLoading(false);
            if (response) {
              window.location.href = response.flowURL;
            }
          })
        });

        break;
      case 'send-email-button':
        request = {
          dialogType: DIALOG_TYPE_SEND_EMAIL,
        }
        fetchDialogInfo(request, async (info) => {
          info.userEmail = attachedData.userEmail;
          openDialog(DIALOG_TYPE_SEND_EMAIL, info, async (inboxAddress) => {
            attachedData.template = cvTemplateType;
            attachedData.inboxAddress = inboxAddress;
            action = createAction(optionId, attachedData);
            setIsLoading(true);
            await actionHandler(action);
            setIsLoading(false);
          });
        });
        break;
      case 'exp-review-button':
        request = {
          dialogType: DIALOG_TYPE_EXPERT_REVIEW,
          flowType: flowType,
        }
        fetchDialogInfo(request, async (info) => {
          info.userEmail = attachedData.userEmail;
          openDialog(DIALOG_TYPE_EXPERT_REVIEW, info, async ([inboxAddress, message, currency]) => {
            attachedData.inboxAddress = inboxAddress;
            attachedData.message = message;
            attachedData.productId = productIds[flowType];
            attachedData.currency = currency;
            action = createAction(optionId, attachedData);
            setIsLoading(true);
            await actionHandler(action);
            setIsLoading(false);
          });
        });
        break;
      case DOWNLOAD_PDF_BTN:
        attachedData.template = cvTemplateType;
        action = createAction(optionId, attachedData);
        setIsLoading(true);
        await actionHandler(action);
        setIsLoading(false);
        break;
      case DOWNLOAD_DOC_BTN:
        break;
      case 'check-info-button':
        const info = {
          flowId: attachedData.flowId,
        }
        openDialog(DIALOG_TYPE_QUESTIONNAIRE_VIEWER, info);
        break;
      case 'edit-flow-button':
        request = {
          dialogType: DIALOG_TYPE_CONSUMPTION_CONFIRM,
          consumptionUrl: '/flow/edit-as-new',
          flowType: flowType,
        };
        fetchDialogInfo(request, async (info) => {
          openDialog(DIALOG_TYPE_CONSUMPTION_CONFIRM, info, async () => {
            action = createAction(optionId, attachedData);
            setIsLoading(true);
            const response = await actionHandler(action);
            setIsLoading(false);
            if (response) {
              window.location.href = response.flowURL;
            }
          });
        });
        break;
      case CV_TEMPLATE_TYPE_1_BTN:
        setCVTemplateType(CV_TEMPLATE_TYPE_1_BTN);
        break;
      case CV_TEMPLATE_TYPE_2_BTN:
        setCVTemplateType(CV_TEMPLATE_TYPE_2_BTN);
        break;
      case CV_TEMPLATE_TYPE_3_BTN:
        setCVTemplateType(CV_TEMPLATE_TYPE_3_BTN);
        break;
      default:
        console.log('Unknown \'optionId\': ' + optionId);
    }
  }

  return (
    <ArticleContext.Provider value={{
      docName,
      content,
      analysis,
      btnGroups,
      rating,
      userEmail,
      flowType,
      displayTexts,
      cvTemplateType,
      contentRef,
      handleCVTemplateTypeChange,
      handleRenameDocButtonClick,
      handleArticleButtonClick,
      fetchArticleDisplay
    }}>{children}</ArticleContext.Provider>
  );
}