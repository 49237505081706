import { Box, Skeleton } from "@mui/material";

export default function FlowOptionSkeleton() {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            // gap: 2,
        }}>
            <Skeleton width={200} height={70} animation='wave' sx={{ borderRadius: 1 }} />
        </Box>
    )
}