import * as React from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import { useHome } from '../../context/homeProvider';

export default function SearchBar() {
    const { searchOptions, handleSearchClick } = useHome();

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '440px' }}>
            {searchOptions && (
                <Autocomplete
                    id="grouped-search-bar"
                    options={searchOptions.sort()}
                    groupBy={(option) => option.category}
                    getOptionLabel={(option) => option.displayText}
                    sx={{ 
                        width: '100%',
                        '& .MuiOutlinedInput-root': { // locate the input element
                            height: '50px',
                            '& fieldset': { 
                                borderWidth: '0px',
                            },
                            '&.Mui-focused fieldset': {
                                borderWidth: '0px',
                            },
                        },
                        '& .MuiAutocomplete-inputRoot': {
                            '&.Mui-focused': {
                                boxShadow: '0 0 0 2px #FAFAF8',
                            },
                        },
                    }}
                    renderInput={(params) => <TextField {...params} label="" InputProps={{
                        ...params.InputProps,
                        style: { backgroundColor: 'white' },
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }} />}
                    onChange={(event, value) => { handleSearchClick(event, value) }}
                />
            )}
        </Box>
    );
}