import { Box, Typography } from '@mui/material';
import React from 'react';
import JsonViewer from '../dataDisplay/jsonDisplay';

export default function UserDataViewer({ data }) {
    return (
        <Box>
            {data && (
                <Box>
                    <Typography variant="body1" gutterBottom>User id: {data.id}</Typography>
                    <Typography variant="body1" gutterBottom>Display Name: {data.displayName}</Typography>
                    <Typography variant="body1" gutterBottom>Email: {data.email}</Typography>
                    <Typography variant="body1" gutterBottom>Points: {data.points}</Typography>
                    <Typography variant="body1" gutterBottom>Subscription: {<JsonViewer data={data.subscription} />}</Typography>
                    <Typography variant="body1" gutterBottom>Billing: {<JsonViewer data={data.billing} />}</Typography>
                </Box>
            )}
        </Box>
    );
}