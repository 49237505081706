const DIALOG_TYPE_CONFIRM = 'confirm';
const DIALOG_TYPE_CONSUMPTION_CONFIRM = 'consumption-confirm';
const DIALOG_TYPE_ALERT = 'alert';
const DIALOG_TYPE_SEND_EMAIL = 'send-email';
const DIALOG_TYPE_RENAME_DOC = 'rename-doc';
const DIALOG_TYPE_EXPERT_REVIEW = 'expert-review';
const DIALOG_TYPE_INFO = 'info';
const DIALOG_TYPE_PRAY = 'pray';
const DIALOG_TYPE_QUESTIONNAIRE_VIEWER = 'questionnaire-viewer';
const DIALOG_TYPE_GENERATION_VIEWER = 'generation-viewer';
const DIALOG_TYPE_USER_DATA_VIEWER = 'user-data-viewer';

export {
    DIALOG_TYPE_CONFIRM,
    DIALOG_TYPE_CONSUMPTION_CONFIRM,
    DIALOG_TYPE_ALERT,
    DIALOG_TYPE_SEND_EMAIL,
    DIALOG_TYPE_RENAME_DOC,
    DIALOG_TYPE_EXPERT_REVIEW,
    DIALOG_TYPE_INFO,
    DIALOG_TYPE_PRAY,
    DIALOG_TYPE_QUESTIONNAIRE_VIEWER,
    DIALOG_TYPE_GENERATION_VIEWER,
    DIALOG_TYPE_USER_DATA_VIEWER,
};