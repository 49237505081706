import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { getImagePNG } from "../../utils/assets";
import React from "react";
import { FLOWTYPE_CVE, FLOWTYPE_CVP, FLOWTYPE_PSE, FLOWTYPE_PSP, FLOWTYPE_RLE, FLOWTYPE_RLP } from "../../constants/flow";

export default function ReportTemplate({ content, flowType }) {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
        }}>
            {getReportHeader(content, flowType)}
            <Box sx={{ height: '20px' }}></Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: '15px',
            }}>
                {getReportSection(content, flowType)}
            </Box>
            {getReportFooter(content, flowType)}
        </Box>
    );
}

function EvaluationReportHeader({ content }) {
    return (
        <Box sx={{
            width: '100%',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: '20px',
            }}>
                <Typography variant="evaluation_header_title">{content.evaluation_report.title}</Typography>
                <img src={getImagePNG('logo_black')} alt="logo" width={'120px'} height={'18px'}/>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                border: '2px solid #191919',
                borderRadius: '2px',
                width: '100%',
            }}>
                <Box sx={{
                    width: '80%',
                    padding: '10px',
                    lineHeight: 1.2,
                }}>
                    <Typography variant="evaluation_header_summary" sx={{ padding: '0px', margin: '0px' }}>{content.evaluation_report.summary.text}</Typography>
                </Box>
                <Box sx={{
                    width: '18%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderLeft: '2px dashed rgba(0, 0, 0, 0.2)',
                }}>
                    <Typography variant="evaluation_header_score">{content.evaluation_report.summary.overall_score}</Typography>
                </Box>
            </Box>
        </Box>
    );
}

function PolishReportHeader({ content }) {
    return (
        <Box sx={{
            width: '100%',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: '20px',
            }}>
                <Typography variant="evaluation_header_title">{content.title}</Typography>
                <img src={getImagePNG('logo_black')} alt="logo" width={'120px'} height={'18px'} />
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                border: '2px solid #AAAAAA',
                borderRadius: '2px',
                width: '100%',
            }}>
                <Box sx={{
                    width: '100%',
                    padding: '10px',
                    lineHeight: 1.2,
                }}>
                    <Typography variant="evaluation_header_summary" sx={{ padding: '0px', margin: '0px' }}>{content.summary}</Typography>
                </Box>
            </Box>
        </Box>
    );
}

function EvaluationReportSection({ section }) {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'left',
                width: '100%',
            }}>
                <Typography variant="evaluation_section_title">{section.section_title}</Typography>
            </Box>
            <TableContainer>
                <Table sx={{
                    borderLeft: '2px solid #191919',
                    borderRight: '2px solid #191919',
                }}>
                    <TableHead sx={{
                        backgroundColor: '#191919',
                        borderLeft: '2px solid #191919',
                        borderRight: '2px solid #191919',
                        padding: '1px',
                        '.MuiTableCell-root': {
                            padding: '2px 8px 2px 8px',
                        },
                    }}>
                        <TableRow>
                            <TableCell>
                                <Typography variant="evaluation_section_header">SUBJECT</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="evaluation_section_header">FEEDBACK</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="evaluation_section_header">SUGGESTIONS</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="evaluation_section_header">SCORE</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{
                        borderLeft: '2px solid #191919',
                        borderRight: '2px solid #191919',
                        borderBottom: '2px solid #191919',
                        '.MuiTableCell-root': {
                            borderBottom: '1px solid #191919',
                            padding: '8px',
                        },
                    }}>
                        {section.criteria.map((criteria, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ verticalAlign: 'top' }}>
                                    <Typography variant="evaluation_section_content">{criteria.criteria_title}</Typography>
                                </TableCell>
                                <TableCell sx={{ verticalAlign: 'top', lineHeight: 1.2 }}>
                                    <Typography variant="evaluation_section_content">{criteria.feedback}</Typography>
                                </TableCell>
                                <TableCell sx={{ verticalAlign: 'top', lineHeight: 1.2 }}>
                                    <Typography variant="evaluation_section_content">{criteria.improvement_suggestions}</Typography>
                                </TableCell>
                                <TableCell sx={{
                                    borderLeft: '2px dashed rgba(0, 0, 0, 0.2)',
                                    textAlign: 'center',
                                }}>
                                    <Typography variant="evaluation_section_score">{criteria.score}</Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

function PolishReportSection({ sectionTitle, section }) {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'left',
                width: '100%',
            }}>
                <Typography variant="evaluation_section_title">{sectionTitle}</Typography>
            </Box>
            <TableContainer>
                <Table sx={{
                    borderLeft: '2px solid #AAAAAA',
                    borderRight: '2px solid #AAAAAA',
                }}>
                    <TableHead sx={{
                        backgroundColor: '#F0F0EB',
                        borderLeft: '2px solid #AAAAAA',
                        borderRight: '2px solid #AAAAAA',
                        borderTop: '2px solid #AAAAAA',
                        padding: '1px',
                        '.MuiTableCell-root': {
                            padding: '2px 8px 2px 8px',
                        },
                    }}>
                        <TableRow>
                            <TableCell sx={{ width: '25%' }}>
                                <Typography variant="polish_section_header">ORIGINAL</Typography>
                            </TableCell>
                            <TableCell sx={{ width: '35%' }}>
                                <Typography variant="polish_section_header">REVISED</Typography>
                            </TableCell>
                            <TableCell sx={{ width: '40%' }}>
                                <Typography variant="polish_section_header">REASON FOR CHANGE</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{
                        borderLeft: '2px solid #AAAAAA',
                        borderRight: '2px solid #AAAAAA',
                        borderTop: '2px solid #AAAAAA',
                        borderBottom: '2px solid #AAAAAA',
                        '.MuiTableCell-root': {
                            borderTop: '1px solid #AAAAAA',
                            borderBottom: '1px solid #AAAAAA',
                            borderRight: '2px solid #AAAAAA',
                            padding: '8px',
                        },
                    }}>
                        {section.map((line, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ verticalAlign: 'top' }}>
                                    <Typography variant="evaluation_section_content">{line.original}</Typography>
                                </TableCell>
                                <TableCell sx={{ verticalAlign: 'top' }}>
                                    <Typography variant="evaluation_section_content">{line.revised}</Typography>
                                </TableCell>
                                <TableCell colSpan={2} sx={{ verticalAlign: 'top' }}>
                                    <Typography variant="evaluation_section_content">{line.reasonForChange}</Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

function ReportFooter() {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            mt: '30px',
            gap: '10px',
        }}>
            <Typography variant="evaluation_footer">Copyright 2024 by LYDE Testing Service. All rights reserved.</Typography>
            <img src={getImagePNG('logo_black')} alt="logo" width={'120px'} height={'18px'} />
        </Box>
    );
}

function getReportHeader(content, flowType) {
    switch (flowType) {
            case FLOWTYPE_PSE:
            case FLOWTYPE_RLE:
            case FLOWTYPE_CVE:
                return <EvaluationReportHeader content={content} />;
            case FLOWTYPE_PSP:
            case FLOWTYPE_RLP:
            case FLOWTYPE_CVP:
                return <PolishReportHeader content={content} />;
            default:
                return <EvaluationReportHeader content={content} />;
        }
}

function getReportSection(content, flowType) {
    switch (flowType) {
            case FLOWTYPE_PSE:
            case FLOWTYPE_RLE:
            case FLOWTYPE_CVE:
                return content.evaluation_report.sections.map((section, index) => (
                    <EvaluationReportSection key={index} section={section} />
                ));
            case FLOWTYPE_PSP:
            case FLOWTYPE_RLP:
            case FLOWTYPE_CVP:
                const res = [];
                for (const [key, value] of Object.entries(content.detailedComparativeAnalysis)) {
                    res.push(<PolishReportSection key={key} sectionTitle={key} section={value} />);
                }
                return res;
            default:
                return content.evaluation_report.sections.map((section, index) => (
                    <EvaluationReportSection key={index} section={section} />
                ));
        }
}

function getReportFooter(content, flowType) {
    switch (flowType) {
        case FLOWTYPE_PSE:
        case FLOWTYPE_RLE:
        case FLOWTYPE_CVE:
        case FLOWTYPE_PSP:
        case FLOWTYPE_RLP:
        case FLOWTYPE_CVP:
        default:
            return <ReportFooter />;
    }
}