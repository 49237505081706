import React, { useEffect, useState } from 'react';
import { TextField, MenuItem, Button, IconButton, Box, Checkbox, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Stack, Chip, Autocomplete } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import TranslateIcon from '@mui/icons-material/Translate';
import authFetch from '../../../utils/auth';
import { AVAILABLE_LOCALES, DEFAULT_TRANSLATION } from '../../../constants/locale';
import { QTYPE_INFO_INSERT, QTYPE_INSTRUCTION_ONLY, QTYPE_MULTI_SELECT, QTYPE_QUESTION_GROUP, QTYPE_SINGLE_SELECT, QTYPE_TEXT_INPUT, TEXT_TYPE_LONG, TEXT_TYPE_MEDIUM, TEXT_TYPE_MEDIUM_SHORT, TEXT_TYPE_SHORT } from '../../../constants/question';
import { useInternal } from '../../../context/internalProvider';
import { useDialog } from '../../../context/dialogProvider';
import { getPromptOutputFields } from '../../../utils/prompt';

const qTypes = [
  { value: QTYPE_TEXT_INPUT, label: 'Text Input' },
  { value: QTYPE_SINGLE_SELECT, label: 'Single Select' },
  { value: QTYPE_MULTI_SELECT, label: 'Multi Select' },
  { value: QTYPE_QUESTION_GROUP, label: 'Question Group' },
  { value: QTYPE_INFO_INSERT, label: 'Insertable Question Group' },
  { value: QTYPE_INSTRUCTION_ONLY, label: 'Instruction Only' }
];
const textInputTypes = [
  { value: TEXT_TYPE_SHORT, label: 'Short (10 words/characters, 1 line)' },
  { value: TEXT_TYPE_MEDIUM_SHORT, label: 'Medium-short (30 words/characters, 1 line)' },
  { value: TEXT_TYPE_MEDIUM, label: 'Medium (500 words/characters, 4 lines)' },
  { value: TEXT_TYPE_LONG, label: 'Long (2000 words/characters, 10 lines)' },
]

const qDefault = {
  id: '',
  isMandatory: false,
  type: QTYPE_TEXT_INPUT,
  instruction: DEFAULT_TRANSLATION,
  hasHint: false,
  hint: DEFAULT_TRANSLATION,
  summary: '',
  // for select type question
  options: [{ value: '', text: DEFAULT_TRANSLATION }],
  showTextWhenSelected: false,
  // for text input question
  textInputType: 'short',
  hasUpload: false,
  childQuestionIDs: [],
  infoBlockName: DEFAULT_TRANSLATION,
  hasStaticContent: false,
  staticContent: {},
};

function getQuestionState(q) {
  return {
    id: q.id,
    isMandatory: q.isMandatory,
    type: q.type,
    instruction: q.instruction,
    hasHint: q.hasHint,
    hint: q.hasHint ? q.hint : DEFAULT_TRANSLATION,
    summary: q.summary,
    // for select type question
    options: q.options !== undefined ? q.options : [{ value: '', text: DEFAULT_TRANSLATION }],
    showTextWhenSelected: q.showTextWhenSelected,
    // for text input question
    textInputType: q.textInputType !== undefined ? q.textInputType : 'short',
    hasUpload: q.hasUpload,
    childQuestionIDs: q.childQuestionIDs? q.childQuestionIDs : [],
    infoBlockName: q.infoBlockName? q.infoBlockName : DEFAULT_TRANSLATION,
    hasStaticContent: q.hasStaticContent,
    staticContent: q.staticContent,
  }
}

function constructQuestion(questionState) {
  let q = {
    id: questionState.id,
    isMandatory: questionState.isMandatory,
    type: questionState.type,
    instruction: questionState.instruction,
    hasHint: questionState.hasHint,
    summary: questionState.summary,
    hasUpload: questionState.hasUpload,
  };

  if (questionState.hasHint) {
    q.hint = questionState.hint;
  }

  if (questionState.type === QTYPE_SINGLE_SELECT || questionState.type === QTYPE_MULTI_SELECT) {
    q.options = questionState.options;
    q.showTextWhenSelected = questionState.showTextWhenSelected;
  }

  if (questionState.type === QTYPE_TEXT_INPUT) {
    q.textInputType = questionState.textInputType;
  }

  if (questionState.type === QTYPE_QUESTION_GROUP || questionState.type === QTYPE_INFO_INSERT) {
    q.childQuestionIDs = questionState.childQuestionIDs;
  }

  if (questionState.type === QTYPE_INFO_INSERT) {
    q.infoBlockName = questionState.infoBlockName;
  }

  if (questionState.type === QTYPE_INSTRUCTION_ONLY) {
    q.hasStaticContent = questionState.hasStaticContent;
    q.staticContent = questionState.staticContent;
  }

  return q;
}

function QuestionBuilder({ questionProp, onSubmit }) {
  const { showSuccessMessage, showErrorMessage } = useDialog();
  const { questions, sections, prompts } = useInternal();

  const [isEdit, setIsEdit] = useState(false);
  const [questionState, setQuestionState] = useState(qDefault);
  const [openTranslationModal, setOpenTranslationModal] = useState(false);
  const [currentEditing, setCurrentEditing] = useState(null);
  const [selectedLocale, setSelectedLocale] = useState(AVAILABLE_LOCALES[0]);
  const [translationText, setTranslationText] = useState('');
  const [usages, setUsages] = useState([]);
  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const handleUsage = (qID, questions, sections) => {
    const sectionUsage = sections.filter(s => s.questionIDs.includes(qID)).map(s => ({
      id: s.id,
      type: 'section',
    }));
    const questionUsage = questions.filter(q => q.childQuestionIDs?.includes(qID)).map(q => ({
      id: q.id,
      type: 'question',
    }));
    setUsages([...sectionUsage, ...questionUsage]);
  }

  useEffect(() => {
    if (questionProp) {
      setQuestionState(getQuestionState(questionProp));
      handleUsage(questionProp.id, questions, sections);
      setIsEdit(true);
    } else {
      setQuestionState(qDefault);
      setIsEdit(false);
    }
  }, [questionProp, questions, sections]);

  const getExtendedOutputFieldsOptions = () => {
    const fields = [];
    prompts.forEach(p => {
      const pFields = getPromptOutputFields(p).map(f => {
        return {
          displayText: `${f} (${p.id})`,
          value: f,
        }
      });
      fields.push(...pFields);
    });
    return fields;
  }

  const handleQuestionIDChange = (val) => {
    // Regular expression to match only lowercase letters, digits, and underscores
    const filteredValue = val.replace(/[^a-z0-9_]/g, '');
    setQuestionState({ ...questionState, id: filteredValue })
  };

  const handleAddOption = () => {
    setQuestionState({ ...questionState, options: [...questionState.options, { value: '', text: DEFAULT_TRANSLATION }] });
  };

  const handleOptionValueChange = (index, optionValue) => {
    const updatedOptions = questionState.options.map((option, idx) => {
      if (idx === index) {
        return {
          ...option,
          value: optionValue,
        };
      }
      return option;
    });
    setQuestionState({ ...questionState, options: updatedOptions });
  };

  const handleOptionTranslationChange = (index, locale, translation) => {
    const updatedOptions = questionState.options.map((option, idx) => {
      if (idx === index) {
        return {
          ...option,
          text: {
            ...option.text,
            [locale]: translation,
          }
        };
      }
      return option;
    });
    setQuestionState({ ...questionState, options: updatedOptions });
  };

  const handleOptionTranslationDeletion = (index, locale) => {
    const updatedOptions = questionState.options.map((option, idx) => {
      if (idx === index) {
        let newText = option.text;
        delete newText[locale];
        return {
          ...option,
          text: newText,
        };
      }
      return option;
    });
    setQuestionState({ ...questionState, options: updatedOptions });
  };

  const handleRemoveOption = (index) => {
    const newOptions = questionState.options.filter((_, optIndex) => index !== optIndex);
    setQuestionState({ ...questionState, options: newOptions });
  };

  const handleOpenTranslationModal = (type, index = null) => {
    setCurrentEditing({ type, index });
    setOpenTranslationModal(true);
  };

  const handleSaveTranslation = () => {
    if (currentEditing.type === 'instruction' || currentEditing.type === 'hint') {
      setQuestionState({
        ...questionState,
        [currentEditing.type]: {
          ...questionState[currentEditing.type],
          [selectedLocale]: translationText
        }
      });
    } else if (currentEditing.type === 'option') {
      const updatedOptions = questionState.options.map((option, index) => {
        if (index === currentEditing.index) {
          return {
            ...option,
            text: {
              ...option.text,
              [selectedLocale]: translationText
            }
          };
        }
        return option;
      });
      setQuestionState({ ...questionState, options: updatedOptions });
    }
    handleCloseTranslationModal();
  };

  const handleCloseTranslationModal = () => {
    setOpenTranslationModal(false);
    setTranslationText('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const q = constructQuestion(questionState);
    try {
      const response = await authFetch('/dev/update-question', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ question: q })
      });

      if (response.isSuccess === true) {
        showSuccessMessage(isEdit ? 'Question updated successfully!' : 'Question created successfully!');
        // Reset question state after successful submission
        setQuestionState(qDefault);
        onSubmit();
      } else {
        console.error("Invalid response!", response);
        showErrorMessage("Something went wrong.");
      }
    } catch (error) {
      console.error("There was an error!", error);
      showErrorMessage(error.message || "An unknown error occurred.");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await authFetch('/dev/delete-question', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ questionID: questionState.id })
      });

      if (response.isSuccess === true) {
        showSuccessMessage('Question has been deleted');
        // Reset question state after successful submission
        setQuestionState(qDefault);
        onSubmit();
      } else {
        console.error("Invalid response!", response);
        showErrorMessage("Something went wrong.");
      }
    } catch (error) {
      console.error("There was an error!", error);
      showErrorMessage(error.message || "An unknown error occurred.");
    }
  };

  const handleOpenModal = () => setOpenQuestionModal(true);
  const handleCloseModal = () => {
    setOpenQuestionModal(false);
    setSelectedQuestion(null);
  };

  const handleAddQuestion = () => {
    if (selectedQuestion && !questionState.childQuestionIDs.find(qID => qID === selectedQuestion.id)) {
      setQuestionState(prev => ({
        ...prev,
        childQuestionIDs: [...prev.childQuestionIDs, selectedQuestion.id]
      }));
      handleCloseModal();
    }
  };

  const handleRemoveQuestion = (questionId) => {
    setQuestionState(prev => ({
      ...prev,
      childQuestionIDs: prev.childQuestionIDs.filter(qID => qID !== questionId),
    }));
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minWidth: 500,
      maxWidth: '100%', // Ensures responsiveness
      margin: 'auto',
      p: 5,
    }}>
      <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
        {isEdit ? 'Edit Question' : 'Add New Question'}
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Question ID"
          helperText="The identifier of the question, it needs to be unique and informative. For example: ps_grad_target_school_reason, rl_research_applicant_performance"
          value={questionState.id}
          onChange={(e) => handleQuestionIDChange(e.target.value)}
          variant={isEdit ? 'filled' : 'outlined'}
          InputProps={{
            readOnly: isEdit,
          }}
        />
        <FormControlLabel
          control={<Checkbox checked={questionState.isMandatory} onChange={(e) => setQuestionState({ ...questionState, isMandatory: e.target.checked })} />}
          label="Is Mandatory"
        />
        <TextField
          id="question-type"
          select
          label="Question Type"
          value={questionState.type}
          onChange={(e) => setQuestionState({ ...questionState, type: e.target.value })}
          fullWidth
          sx={{ mt: 2 }}
        >
          {qTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {questionState.type === QTYPE_TEXT_INPUT &&
          <TextField
            id="text-input-size"
            select
            label="Text input size"
            value={questionState.textInputType}
            onChange={(e) => setQuestionState({ ...questionState, textInputType: e.target.value })}
            fullWidth
            sx={{ mt: 2 }}
          >
            {textInputTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>}
        {questionState.type === QTYPE_TEXT_INPUT &&
          <FormControlLabel
            control={<Checkbox checked={questionState.hasUpload} onChange={(e) => setQuestionState({ ...questionState, hasUpload: e.target.checked })} />}
            label="Enable Upload"
          />
        }
        <Box key="question-instruction" sx={{ mt: 2 }}>
          <Stack direction="row">
            <Typography variant="subtitle1" gutterBottom>
              Question Instruction
            </Typography>
            <Button startIcon={<TranslateIcon />} onClick={() => handleOpenTranslationModal('instruction')} variant="text" sx={{ ml: 2 }}>
              Add Translation
            </Button>
          </Stack>
          <Box sx={{ ml: 3 }}>
            {Object.keys(questionState.instruction).length > 0 ? (
              Object.entries(questionState.instruction).map(([locale, text], index) => (
                <Box key={locale} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <TextField
                    multiline
                    variant="outlined"
                    size="small"
                    label={locale}
                    value={text}
                    onChange={(e) => {
                      const newTranslations = {
                        ...questionState.instruction,
                        [locale]: e.target.value
                      };
                      setQuestionState({ ...questionState, instruction: newTranslations });
                    }}
                    sx={{ flexGrow: 1, mr: 1 }}
                  />
                  <IconButton onClick={() => {
                    const { [locale]: deleted, ...remainingTranslations } = questionState.instruction;
                    setQuestionState({ ...questionState, instruction: remainingTranslations });
                  }} color="error">
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                No translations added.
              </Typography>
            )}
          </Box>
        </Box>
        <FormControlLabel
          control={<Checkbox checked={questionState.hasHint} onChange={(e) => setQuestionState({ ...questionState, hasHint: e.target.checked })} />}
          label="Has Hint"
        />
        {questionState.hasHint && <Box key="question-hint" sx={{ mt: 2 }}>
          <Stack direction="row">
            <Typography variant="subtitle1" gutterBottom>
              Question Hint
            </Typography>
            <Button startIcon={<TranslateIcon />} onClick={() => handleOpenTranslationModal('hint')} variant="text" sx={{ ml: 2 }}>
              Add Translation
            </Button>
          </Stack>
          <Box sx={{ ml: 3 }}>
            {Object.keys(questionState.hint).length > 0 ? (
              Object.entries(questionState.hint).map(([locale, translation], index) => (
                <Box key={locale} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <TextField
                    multiline
                    variant="outlined"
                    size="small"
                    label={locale}
                    value={translation}
                    onChange={(e) => {
                      const newTranslations = {
                        ...questionState.hint,
                        [locale]: e.target.value
                      };
                      setQuestionState({ ...questionState, hint: newTranslations });
                    }}
                    sx={{ flexGrow: 1, mr: 1 }}
                  />
                  <IconButton onClick={() => {
                    const { [locale]: deleted, ...remainingTranslations } = questionState.hint;
                    setQuestionState({ ...questionState, hint: remainingTranslations });
                  }} color="error">
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                No translations added.
              </Typography>
            )}
          </Box>
        </Box>}
        {[QTYPE_INFO_INSERT].includes(questionState.type) && <Box key="question-info-insert" sx={{ mt: 2 }}>
          <Stack direction="row">
            <Typography variant="subtitle1" gutterBottom>
              Insertable Info Block Name
            </Typography>
            <Button startIcon={<TranslateIcon />} onClick={() => handleOpenTranslationModal('infoBlockName')} variant="text" sx={{ ml: 2 }}>
              Add Translation
            </Button>
          </Stack>
          <Box sx={{ ml: 3 }}>
            {Object.keys(questionState.infoBlockName).length > 0 ? (
              Object.entries(questionState.infoBlockName).map(([locale, translation], index) => (
                <Box key={locale} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    label={locale}
                    value={translation}
                    onChange={(e) => {
                      const newTranslations = {
                        ...questionState.infoBlockName,
                        [locale]: e.target.value
                      };
                      setQuestionState({ ...questionState, infoBlockName: newTranslations });
                    }}
                    sx={{ flexGrow: 1, mr: 1 }}
                  />
                  <IconButton onClick={() => {
                    const { [locale]: deleted, ...remainingTranslations } = questionState.infoBlockName;
                    setQuestionState({ ...questionState, infoBlockName: remainingTranslations });
                  }} color="error">
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                No translations added.
              </Typography>
            )}
          </Box>
        </Box>}
        {[QTYPE_QUESTION_GROUP, QTYPE_INFO_INSERT].includes(questionState.type) && (
          <>
            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
              Children Questions
            </Typography>
            <Button startIcon={<AddIcon />} onClick={handleOpenModal} variant="outlined" sx={{ mb: 2 }}>Add Question</Button>

            <Box sx={{ mb: 2 }}>
              {questionState.childQuestionIDs.map((qID) => (
                <Chip
                  key={qID}
                  label={qID}
                  onDelete={() => handleRemoveQuestion(qID)}
                  color="primary"
                  sx={{ mr: 1, mb: 1 }}
                />
              ))}
            </Box>
          </>
        )}
        {[QTYPE_SINGLE_SELECT, QTYPE_MULTI_SELECT].includes(questionState.type) && (
          <>
            <Typography variant="subtitle1" gutterBottom>
              Question Options
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ ml: 3 }}>
              Each option should have a value field which will be stored in database as user's answer to this question and be used for GPT processing if selected. In most cases it will be the English translation of this option.
            </Typography>
            {questionState.type === QTYPE_SINGLE_SELECT && <FormControlLabel sx={{ ml: 3 }}
              control={<Checkbox checked={questionState.showTextWhenSelected} onChange={(e) => setQuestionState({ ...questionState, showTextWhenSelected: e.target.checked })} />}
              label="Show option text only when selected"
            />}
          </>
        )}
        {[QTYPE_SINGLE_SELECT, QTYPE_MULTI_SELECT].includes(questionState.type) && questionState.options.map((option, index) => (
          <Box key={index} sx={{ alignItems: 'center', mt: 2, ml: 3 }}>
            <Stack direction="row">
              <Typography variant="subtitle1" gutterBottom sx={{ mr: 2, p: 1 }}>
                {`Option ${index + 1}`}
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                label="option value"
                value={option.value}
                onChange={(e) => handleOptionValueChange(index, e.target.value)}
                sx={{ flexGrow: 1, mr: 1 }}
              />
              <IconButton onClick={() => handleRemoveOption(index)} color="error">
                <DeleteIcon />
              </IconButton>
              <IconButton onClick={() => handleOpenTranslationModal('option', index)} color="primary">
                <TranslateIcon />
              </IconButton>
            </Stack>
            <Box sx={{ ml: 3 }}>
              {Object.keys(option.text).length > 0 ? (
                Object.entries(option.text).map(([locale, translation], textIdx) => (
                  <Box key={locale} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label={locale}
                      value={translation}
                      onChange={(e) => handleOptionTranslationChange(index, locale, e.target.value)}
                      sx={{ flexGrow: 1, mr: 1 }}
                    />
                    <IconButton onClick={() => handleOptionTranslationDeletion(index, locale)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No translations added.
                </Typography>
              )}
            </Box>
          </Box>
        ))}
        {[QTYPE_SINGLE_SELECT, QTYPE_MULTI_SELECT].includes(questionState.type) && (
          <Button onClick={handleAddOption} startIcon={<AddCircleOutlineIcon />} sx={{ mt: 1, ml: 3 }}>
            Add Option
          </Button>
        )}
        {(questionState.type !== QTYPE_INSTRUCTION_ONLY) && (
          <TextField
            margin="normal"
            required
            fullWidth
            label="Question summary"
            helperText="This field will be used as a prefix prepended to the answer of this question when invoking GPT. It should be concise and reveal the nature of answer. For example: Reason why choosing target program"
            value={questionState.summary}
            onChange={(e) => { setQuestionState({ ...questionState, summary: e.target.value }) }}
          />
        )}
        {(questionState.type === QTYPE_INSTRUCTION_ONLY) && (
          <FormControlLabel
            control={
              <Checkbox
                checked={questionState.hasStaticContent === true}
                onChange={(e) => setQuestionState({ ...questionState, hasStaticContent: e.target.checked })}
              />}
            label="Has Static Content"
          />
        )}
        {questionState.hasStaticContent && (
          <Autocomplete
            value={questionState.staticContent}
            options={getExtendedOutputFieldsOptions()}
            getOptionLabel={(option) => option.displayText}
            onChange={(event, value) => { setQuestionState({ ...questionState, staticContent: value }) }}
            renderInput={(params) => <TextField {...params} label="Select Output Field" variant="outlined" />}
            sx={{ mt: 2, width: 550 }}
          />
        )}
        <Typography variant="subtitle1" gutterBottom sx={{ mt: 1 }} >
          Usage
        </Typography>
        {usages.length > 0 ? (
          usages.map((usage, idx) => (
            <Chip
              key={idx}
              label={usage.id}
              color={usage.type === 'section'? 'primary' : 'secondary'}
              variant='outlined'
              sx={{ ml: 2, mb: 1 }}
            />
          ))
        ) : (
          <Typography variant='body2'>Not used in any section</Typography>
        )}
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="submit" fullWidth variant="contained">
            {isEdit ? 'Update Question' : 'Add Question'}
          </Button>
          {isEdit && (
            <Button
              fullWidth
              onClick={handleDelete}
              variant="contained"
              color="error"
              disabled={usages.length > 0}
              sx={{ ml: 2 }}>
              Delete Question
            </Button>
          )}
        </Box>
      </Box>

      <Dialog open={openQuestionModal} onClose={handleCloseModal}>
        <DialogTitle>Add Question</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Autocomplete
            options={questions.filter(q => (q.id !== questionState.id && ![QTYPE_QUESTION_GROUP, QTYPE_INFO_INSERT].includes(q.type)))}
            getOptionLabel={(option) => option.id}
            onChange={(event, value) => setSelectedQuestion(value)}
            renderInput={(params) => <TextField {...params} label="Select Question" variant="outlined" />}
            sx={{ mb: 2, width: 500 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddQuestion} color="primary">Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openTranslationModal} onClose={handleCloseTranslationModal}>
        <DialogTitle>Add Translation</DialogTitle>
        <DialogContent>
          <TextField
            select
            fullWidth
            label="Locale"
            value={selectedLocale}
            onChange={(e) => setSelectedLocale(e.target.value)}
            margin="normal"
          >
            {AVAILABLE_LOCALES.map((locale) => (
              <MenuItem key={locale} value={locale}>
                {locale}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="normal"
            fullWidth
            label="Translation"
            value={translationText}
            onChange={(e) => setTranslationText(e.target.value)}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTranslationModal}>Cancel</Button>
          <Button onClick={handleSaveTranslation}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default QuestionBuilder;
