export const QTYPE_TEXT_INPUT = 'text-input';
export const QTYPE_SINGLE_SELECT = 'single-select';
export const QTYPE_MULTI_SELECT = 'multi-select';
export const QTYPE_QUESTION_GROUP = 'question-group';
export const QTYPE_INFO_INSERT = 'info-insert';
export const QTYPE_INSTRUCTION_ONLY = 'instruction-only';

export const TEXT_TYPE_SHORT = 'short';
export const TEXT_TYPE_MEDIUM_SHORT = 'medium-short';
export const TEXT_TYPE_MEDIUM = 'medium';
export const TEXT_TYPE_LONG = 'long';

export const TEXT_INPUT_PARAMETERS = {
    [TEXT_TYPE_SHORT]: {
        maxWordCount: 10,
        inputRows: 1,
    },
    [TEXT_TYPE_MEDIUM_SHORT]: {
        maxWordCount: 30,
        inputRows: 1,
    },
    [TEXT_TYPE_MEDIUM]: {
        maxWordCount: 500,
        inputRows: 4,
    },
    [TEXT_TYPE_LONG]: {
        maxWordCount: 2000,
        inputRows: 10,
    },
}