import * as React from 'react';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import PricingCard from './pricingCard';
import PricingSubslide from './pricingSubslide';
import authFetch from '../../utils/auth';
import Typography from '@mui/material/Typography';
import PricingTable from './pricingTable';
import { useAuth } from '../../context/authProvider';
import { useNavigate } from 'react-router-dom';

function renderContent(texts, contentId, pricingCards, pricingTable, handleCardButtonClick, isLoggedIn, openSignInDialog) {
    switch (contentId) {
        case 'pricing_cards':
            return (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: '100px',
                    mb: '100px',
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            mb: '60px',
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{ color: 'primary.main' }}
                        >
                            {texts && texts.title}
                        </Typography>
                        <Typography
                            variant='h8'
                            sx={{ color: 'subtitle.secondary' }}>
                            {texts && texts.subtitle}
                        </Typography>
                    </Box>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container spacing={3} justifyContent={'center'}>
                                {pricingCards.map((card, index) => (
                                    <Grid key={index} item xs={5} sm={5} md={5} lg={2.5} xl={2.5}>
                                        <Slide direction="up" in={true} timeout={500 + (index * 200)}>
                                            {PricingCard({ card, handleCardButtonClick, index })}
                                        </Slide>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{ height: '100px' }}></Box>
                    <PricingTable pricingTable={pricingTable} />
                </Box>
            );
        default:
            return (
                <PricingSubslide
                    card={pricingCards.find(card => card.action.actionID === contentId)}
                    isLoggedIn={isLoggedIn}
                    openSignInDialog={openSignInDialog}
                    texts={texts}
                />
            );
    }

}

export default function PricingDisplay() {
    const { isLoggedIn, openSignInDialog } = useAuth();
    const navigate = useNavigate();
    const [texts, setTexts] = React.useState(null);
    const [pricingCards, setPricingCards] = React.useState(null);
    const [pricingTable, setPricingTable] = React.useState(null);
    const [contentId, setContentId] = React.useState('pricing_cards');

    const handleCardButtonClick = (card) => {
        if (card.action.actionID === 'customized_service') {
            if (isLoggedIn) {
                navigate('/home', { state: { sequenceNumber: '6' } });
            } else {
                openSignInDialog();
            }
        } else {
            setContentId(card.action.actionID);
        }
    }

    React.useEffect(() => {
        authFetch('/public/get-pricing-display')
            .then(data => {
                setTexts(data.pricingDisplay.texts);
                setPricingCards(data.pricingDisplay.pricingCards);
                setPricingTable(data.pricingDisplay.pricingTable);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            {pricingCards === null ? (
                null
            ) : (
                renderContent(texts, contentId, pricingCards, pricingTable, handleCardButtonClick, isLoggedIn, openSignInDialog)
            )}
        </Box>
    );
}