import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQAccordian({ faqs }) {
    return (
        <Box>
            {faqs.map((faq, index) => (
                <Accordion key={index} sx={{
                    maxWidth: { xs: '90%', md: '650px' },
                    boxShadow: 'none',
                    bgcolor: 'bg_dark.main',
                }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                            boxShadow: 'none',
                            bgcolor: 'bg_dark.main',
                            borderBottom: '1px solid #F0F0EB',
                            borderRadius: '0',
                        }}
                    >
                        <Typography variant='subtitle1'>{faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        boxShadow: 'none',
                        bgcolor: 'bg_dark.main',
                        borderRadius: '0',
                    }}>
                        <Typography variant='landing_faq_answer' sx={{ whiteSpace: 'pre-line' }}>{faq.answer}</Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    )
}