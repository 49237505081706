import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import authFetch from '../../utils/auth';
import { Button, Divider } from '@mui/material';
import { BackDropLoading } from '../loading/backDropLoading';

export default function AdminDialogMenuItem({ itemText, accountDisplay, handleTopUp, isLoading }) {
    const userAccountData = accountDisplay.userAccountData;
    const [open, setOpen] = React.useState(false);
    const [userTags, setUserTags] = React.useState(userAccountData.userTags);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = (tagToDelete) => async () => {
        const newTags = userTags.filter((tag) => tag !== tagToDelete);
        setUserTags(newTags);
        await updateTags(newTags);
    };

    const handleAdd = async () => {
        const newTag = prompt('Enter a new tag');
        if (newTag) {
            const newTags = [...userTags, newTag];
            setUserTags(newTags);
            await updateTags(newTags);
        }
    }

    return (
        <React.Fragment>
            <MenuItem onClick={handleClickOpen}>
                <Typography textAlign="center">{itemText}</Typography>
            </MenuItem>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth="sm"
                fullWidth={true}
                sx={{
                    '& .MuiDialog-paper': { // locate the paper element and apply styles
                        width: '50vh',
                    },
                }}
            >
                {isLoading && <BackDropLoading />}
                
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: "primary.main", color: "primary.contrastText "}}>
                    {itemText}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: "primary.contrastText",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ pt: 2, pb: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Tags
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {userTags.map((tag, index) => (
                                <Chip
                                    key={index}
                                    label={tag}
                                    onDelete={tag === 'admin' ? undefined : handleDelete(tag)}
                                    color={tag === 'admin' ? 'primary' : 'default'}
                                    variant="contained"
                                    sx={{ fontWeight: 'medium' }}
                                />
                            ))}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
                            <Button
                                aria-label="add"
                                variant="contained"
                                sx={{ boxShadow: 'none'}}
                                onClick={() => handleAdd()}
                            >
                                <Typography variant="body1" sx={{ fontWeight: 'medium' }}>Add Tag</Typography>
                            </Button>
                        </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ pt: 2, pb: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Points: {userAccountData.points}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
                            <Button
                                aria-label="add"
                                variant="contained"
                                sx={{ boxShadow: 'none'}}
                                onClick={() => handleTopUp()}
                            >
                                <Typography variant="body1" sx={{ fontWeight: 'medium' }}>Top Up 500 pts</Typography>
                            </Button>
                        </Box>
                    </Box>
                    
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

async function updateTags(newTags) {
    const response = await authFetch('/user/tag-update', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tags: newTags }),
    });
    return response;
}