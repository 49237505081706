import { Box } from '@mui/material';
import { getFlowTypeIcon, getIcon } from '../../utils/assets';
import { EXPERT_CUSTOMIZED_SERVICE, MEDITATE_SERVICE } from '../../constants/home';

const boxStyle = {
  borderRadius: '50px',
  padding: '5px',
  // boxShadow: 'inset 1px 2px 2px 1px rgba(0,0,0,0.2)', 
  // // boxShadow: 1,
  width: '68px',
  height: '68px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#F7F7F7',
};

function getServiceIcon(service) {
  switch (service.id) {
    case MEDITATE_SERVICE:
      return getIcon('icon-pray@2x');
    case EXPERT_CUSTOMIZED_SERVICE:
      return getIcon('iconEC-primary@2x');
    default:
      return getFlowTypeIcon({ flowType: service.flowType, size: '@2x', color: 'primary' });
  }
}

export default function ServiceCardAvatar({ service }) {
  return (
    <Box sx={boxStyle}>
      <img src={getServiceIcon(service)} alt={service.displayText} style={{ width: '48px', height: '48px' }} />
    </Box>
  );
}
