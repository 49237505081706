import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import ServiceCard from './serviceCard';
import { useHome } from '../../context/homeProvider';
import ServiceTabs from './serviceTabs';

export default function ServicesDisplay() {
    const { servicesDisplay, refs } = useHome();

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '1000px',
            pt: 5,
            pb: 10,
            gap: 5,
        }}>
            <ServiceTabs />
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '1000px',
            }}>
                <Grid container sx={{ gap: 10 }}>
                    {servicesDisplay && servicesDisplay.map((category, index) => (
                        <Grid item key={index} ref={refs.current[category.sequenceNumber]} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'left',
                        }}>
                            <Box sx={{
                                pb: 3,
                            }}>
                                <Typography variant="h5" gutterBottom>{category.displayText}</Typography>
                                <Typography variant="subtitle1" color={'subtitle.secondary'} gutterBottom>{category.description}</Typography>
                            </Box>
                            <Grid container spacing={3}>
                                {category.services.map((service, serviceIndex) => (
                                    <Grid item key={serviceIndex} sm={6}>
                                        <ServiceCard service={service} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
}
