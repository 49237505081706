import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#D97757',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#424242',
    },
    third: {
      main: '#FEE3A5',
      contrastText: '#D97757',
    },
    bg1: {
      main: '#FAFAF7',
      contrastText: '#191919',
    },
    bg2: {
      main: '#FFFFFF',
      contrastText: '#191919',
    },
    bg_article: {
      main: '#FFFFFF',
      contrastText: '#191919',
    },
    bg_skin: {
      main: '#EADBBC',
      contrastText: '#191919',
    },
    bg_dark: {
      main: '#F0F0EB',
      contrastText: '#191919',
    },
    bg_label: {
      main: '#FFECC0',
      contrastText: '#D97757',
    },
    bg_bottom: {
      main: '#191919',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#6F9874',
      contrastText: '#FFFFFF',
    },
    subtitle: {
      main: '#191919',
      secondary: '#686865',
      contrastText: '#FFFFFF',
    },
    // Add more color options as needed
  },
  // You can also customize typography, breakpoints, etc.
  // customize typography
  typography: {
    fontFamily: [
      'DM Sans',
      'Roboto',
      'Arial',
      'Protest Strike',
      'Angkor',
      'sans-serif',
      'Alibaba PuHuiTi',
      'Cinecaption Regular',
      'AlimamaFangYuan',
      'Times New Roman',
      'wencangshufang'
    ].join(','),
    landing_title: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'wencangshufang',
      fontSize: '120px',
      fontWeight: 300,
    },
    landing_slang: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'wencangshufang',
      fontSize: '70px',
      fontStyle: 'normal',
      fontWeight: 300,
    },
    h3: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi Regular',
      fontSize: '48px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '66px',
    },
    h4: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi',
      fontSize: '40px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '66px',
    },
    h5: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi Regular',
      fontSize: '30px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 1.067,
      letterSpacing: '1px',
    },
    h6: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi Regular',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: '1px',
    },
    h7: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi Regular',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 1.5,
    },
    subtitle1: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi Regular',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.667,
    },
    subtitle2: {
      color: '#686865',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi Regular',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 2.1,
    },
    landing_subtitle: {
      color: '#686865',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi Regular',
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    landing_faq_answer: {
      color: '#686865',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi Regular',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.8,
    },
    body1: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi Regular',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.429,
    },
    caption: {
      color: '#AAAAAA',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi Regular',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 2.1,
      letterSpacing: '1px',
    },
    caption_small: {
      color: '#686865',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi Regular',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.333,
      letterSpacing: '1px',
    },
    article: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi Regular',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.8,
      letterSpacing: '0.2px',
    },
    pricing_card_title: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi',
      fontSize: '28px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 1.5,
    },
    pricing_card_subtitle: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi',
      fontSize: '35px',
      fontStyle: 'normal',
      fontWeight: 800,
      lineHeight: 1,
    },
    card_title: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi Regular',
      fontSize: '21px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 1.5,
    },
    card_subtitle: {
      color: '#686865',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi Regular',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    card_content: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi Regular',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.429,
    },
    card_chip: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi Regular',
      fontSize: '16px',
      fontStyle: 'normal',
      lineHeight: 1.333,
    },
    appBar_price: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Alibaba PuHuiTi',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 1.5,
    },
    cv_title_name: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Times New Roman',
      fontSize: '38px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 1.5,
    },
    cv_title_info: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Times New Roman',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 1.5,
    },
    cv_content_category: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Times New Roman',
      fontSize: '17px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 3.0,
    },
    cv_content_program_title: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Times New Roman',
      fontSize: '17px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 1.5,
    },
    cv_content_program_info: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Times New Roman',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 1.3,
    },
    cv_content_program_info_bold: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Times New Roman',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 1.3,
    },
    cv_content_program_info_italic: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Times New Roman',
      fontSize: '16px',
      fontStyle: 'italic',
      fontWeight: 500,
      lineHeight: 1.3,
    },
    cv_content_program_info_2: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Times New Roman',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 1.3,
    },
    evaluation_header_title: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Arial',
      fontSize: '25px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 1.5,
    },
    evaluation_header_summary: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Arial',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1,
    },
    evaluation_header_score: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Arial',
      fontSize: '45px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 1.5,
    },
    evaluation_section_title: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Arial',
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 1.5,
    },
    evaluation_section_header: {
      color: '#FFF',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Arial',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 1,
    },
    polish_section_header: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Arial',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 1,
    },
    evaluation_section_content: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Arial',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1,
    },
    evaluation_section_score: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Arial',
      fontSize: '25px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 1,
    },
    evaluation_footer: {
      color: '#191919',
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: 'Arial',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1,
    },
  },
});

export default theme;