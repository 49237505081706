import { AFTERSECTION_CONDITIONAL, AFTERSECTION_DIRECT } from "../../constants/section";

function getSectionByID(sectionID, sections) {
  return sections.find(s => s.id === sectionID);
}

function getNextSectionIDs(sectionID, sections) {
  const section = getSectionByID(sectionID, sections);
  if (!section) {
    return new Set();
  }
  switch (section.afterSection) {
    case AFTERSECTION_DIRECT:
      return new Set([section.nextSectionDirect]);
    case AFTERSECTION_CONDITIONAL:
      return new Set(Object.values(section.nextSectionsConditional.nextSectionByOption));
    default:
      return new Set();
  }
}

export function getAllSectionIDs(startingSectionID, sections) {
  const nextSectionIDs = getNextSectionIDs(startingSectionID, sections);
  let sectionsSet = new Set();
  for (const sID of nextSectionIDs) {
    const ss = getAllSectionIDs(sID, sections);
    sectionsSet = new Set([...sectionsSet, ...ss]);
  }
  sectionsSet.add(startingSectionID);
  return sectionsSet;
}