export const AVAILABLE_LOCALES = ['en', 'zh', 'zh-HK'];
export const LOCALE_DISPLAYS = {
    'en': 'English',
    'zh': '简体中文',
    'zh-HK': '繁體中文',
}
export const DEFAULT_TRANSLATION = {
    'en': 'English',
    'zh': '简体中文',
    'zh-HK': '繁體中文',
}