import React, { useState } from 'react';
import { TextField, Button, MenuItem, Box, Grid } from '@mui/material';
import authFetch from '../../utils/auth';
import { useDialog } from '../../context/dialogProvider';

const defaultFormData = {
  name: '',
  subject: '',
  contactMethod: '',
  email: '',
  wechat: '',
  message: '',
}

function ContactForm({ formDisplay }) {
  const [formData, setFormData] = useState(defaultFormData);
  const { showSuccessMessage } = useDialog();
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) newErrors.name = formDisplay.nameLabelText.invalidError ? formDisplay.nameLabelText.invalidError : 'Name is required';
    if (!formData.subject) newErrors.subject = formDisplay.subject.invalidError ? formDisplay.subject.invalidError : 'Subject is required';
    if (!formData.contactMethod) newErrors.contactMethod = formDisplay.preferredContact.invalidError ? formDisplay.preferredContact.invalidError : 'Preferred contact method is required';
    if (formData.contactMethod !== 'wechat' && (!formData.email || validateEmail(formData.email))) newErrors.email = formDisplay.emailLabelText.invalidError ? formDisplay.emailLabelText.invalidError : 'Email is required';
    if (formData.contactMethod === 'wechat' && !formData.wechat) newErrors.wechat = formDisplay.wechatLabelText.invalidError ? formDisplay.wechatLabelText.invalidError : 'Wechat ID is required';
    if (!formData.message) newErrors.message = formDisplay.messageLabelText.invalidError ? formDisplay.messageLabelText.invalidError : 'Message is required';

    return newErrors;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length) {
        setErrors(validationErrors);
      } else {
        const resp = await authFetch('/action/submit-contact-form', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ formData: formData }),
        });
        showSuccessMessage(resp.message);
        setFormData(defaultFormData);
      }
    } catch (error) {
      console.error("There was an error!", error)
    }
  };

  const validateEmail = (email) => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      return true;
    }
   return false;   
  }

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label={formDisplay.nameLabelText.text}
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={!!errors.name} // Is true if errors.name is not empty
            helperText={errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            required
            fullWidth
            name="subject"
            value={formData.subject}
            label={formDisplay.subject.labelText}
            onChange={handleChange}
            error={!!errors.subject}
            helperText={errors.subject}
          >
            {formDisplay.subject.options.map(option => (
              <MenuItem key={option.id} value={option.value}>{option.text}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            select
            fullWidth
            name="contactMethod"
            value={formData.contactMethod}
            label={formDisplay.preferredContact.labelText}
            onChange={handleChange}
            error={!!errors.contactMethod}
            helperText={errors.contactMethod}
          >
            {formDisplay.preferredContact.options.map(option => (
              <MenuItem key={option.id} value={option.value}>{option.text}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          {formData.contactMethod !== 'wechat' ? (
            <TextField
              required
              fullWidth
              label={formDisplay.emailLabelText.text}
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
          ) : (
            <TextField
              required
              fullWidth
              label={formDisplay.wechatLabelText.text}
              name="wechat"
              value={formData.wechat}
              onChange={handleChange}
              error={!!errors.wechat}
              helperText={errors.wechat}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label={formDisplay.messageLabelText.text}
            name="message"
            multiline
            rows={4}
            value={formData.message}
            onChange={handleChange}
            error={!!errors.message}
            helperText={errors.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            alignSelf: 'stretch',
          }}>
            <Button type="submit" variant="contained" sx={{ boxShadow: 'none' }}>{formDisplay.buttonText}</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ContactForm;
