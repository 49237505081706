const { default: authFetch } = require("./auth");
const { DOWNLOAD_PDF_BTN, DOWNLOAD_DOC_BTN } = require('../constants/article');

async function dowloadPDFDoc(docName, flowid, template) {
    const response = await authFetch('/action/download-pdf', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ flowid: flowid, template: template }),
    });

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${docName}.pdf`;
    a.click();
}

async function dowloadWordDoc(docName, htmlContent) {
    const response = await authFetch('/action/download-docx', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content: htmlContent }),
    });

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${docName}.docx`;
    a.click();
}

async function sendDocToEmail(flowId, email, template, docName) {
    const response = await authFetch(`/action/send-email/${flowId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ emailAddress: email, template: template, docName: docName })
    })

    return response;
}

async function renameDoc(flowId, newDocName) {
    const response = await authFetch(`/action/rename-doc/${flowId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newDocName: newDocName })
    })

    return response;
}

async function changeStyle(flowId) {
    const response = await authFetch('/flow/change-style', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ flowid: flowId })
    })

    return response;

}

async function editFlow(flowId) {
    const response = await authFetch('/flow/edit-as-new', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ flowid: flowId })
    })

    return response;
}

async function fetchDialogInfo(request, callback = null) {
    const response = await authFetch('/public/get-dialog-info', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            request: request
        })
    })
    if (response) {
        const info = response.info;
        if (callback) {
            callback(info);
        }
    }
}

async function expertReview(productID, currency, flowid, emailMessage, userEmail) {
    await authFetch(`/payment/create-checkout-session`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            productID: productID,
            currency: currency,
            flowID: flowid,
            userMsg: emailMessage,
            userEmail: userEmail
        })
    })
        .then(res => {
            window.location.href = res.url
        })
}

async function actionHandler(action) {
    const actionId = action.actionId;
    const attachedData = action.attachedData;
    const flowId = attachedData.flowId;
    const docName = attachedData.docName;
    const inboxAddress = attachedData.inboxAddress;
    const userEmail = attachedData.userEmail;
    const newDocName = attachedData.newDocName;
    const emailMessage = attachedData.message;
    const productId = attachedData.productId;
    const currency = attachedData.currency;
    const htmlContent = attachedData.htmlContent;
    const template = attachedData.template;

    let response = null;
    try {
        switch (actionId) {
            case DOWNLOAD_PDF_BTN:
                dowloadPDFDoc(docName, flowId, template);
                break;
            case DOWNLOAD_DOC_BTN:
                dowloadWordDoc(docName, htmlContent);
                break;
            case 'send-email-button':
                response = await sendDocToEmail(flowId, inboxAddress, template, docName);
                break;
            case 'rename-doc-button':
                response = await renameDoc(flowId, newDocName);
                break;
            case 'exp-review-button':
                await expertReview(productId, currency, flowId, emailMessage, userEmail);
                break;
            case 'check-info-button':
                // TODO
                console.log('checkInfoButton');
                break;
            case 'change-style-button':
                response = await changeStyle(flowId);
                break;
            case 'edit-flow-button':
                response = await editFlow(flowId);
                break;
            default:
                console.log('default');
        }

        return response;

    } catch (error) {
        console.error('Error:', error);
    }

}

// create a function for wrapping optionID and attachedData into a single object called "action":
function createAction(optionId, attachedData) {
    return {
        actionId: optionId,
        attachedData: attachedData,
    };
}

module.exports = {
    actionHandler,
    createAction,
    fetchDialogInfo,
};