import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Pray from '../pray/pray';


export default function PrayDialog({ open, handleClose, content }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-content-title"
      aria-describedby="alert-content-description"
      maxWidth='lg'
    >
      <Pray onClose={handleClose} displayText={content.displayText} showAction={content.isEligible} />
    </Dialog>
  )
}