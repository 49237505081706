import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, Typography, TextField, Autocomplete, IconButton, DialogActions, DialogContent, DialogTitle, Chip, Grid, Slider, Input, MenuItem, FormControlLabel, Checkbox, Stack } from '@mui/material';
import authFetch from '../../../utils/auth';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { GPT_MODELS, GPT_PARAMETERS } from '../../../constants/prompt';
import { useInternal } from '../../../context/internalProvider';
import { useDialog } from '../../../context/dialogProvider';

const defaultPrompt = {
  id: '',
  content: '',
  model: 'gpt-4-turbo-preview',
  parameters: {
    temperature: GPT_PARAMETERS.temperature.default,
    max_tokens: GPT_PARAMETERS.max_tokens.default,
    top_p: GPT_PARAMETERS.top_p.default,
    frequency_penalty: GPT_PARAMETERS.frequency_penalty.default,
    presence_penalty: GPT_PARAMETERS.presence_penalty.default,
  },
  dependentQuestionIDs: [],
  useJson: false,
  parseJsonOutput: false,
  outputField: '',
  jsonOutputFields: [],
}

function PromptBuilder({ promptProp, onSubmit }) {
  const { showSuccessMessage, showErrorMessage } = useDialog();
  const { questions } = useInternal();
  const [isEdit, setIsEdit] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState(defaultPrompt);

  useEffect(() => {
    if (promptProp) {
      setCurrentPrompt(promptProp);
      setIsEdit(true);
    } else {
      setCurrentPrompt(defaultPrompt);
      setIsEdit(false);
    }
  }, [promptProp]);

  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const handlePromptIDChange = (val) => {
    // Regular expression to match only lowercase letters, digits, and underscores
    const filteredValue = val.replace(/[^a-z0-9_]/g, '');
    setCurrentPrompt({ ...currentPrompt, id: filteredValue })
  };

  const handleParameterChange = (val, name) => {
    setCurrentPrompt({
      ...currentPrompt,
      parameters: {
        ...currentPrompt.parameters,
        [name]: val,
      },
    })
  }

  const handleInputChange = (val, name) => {
    const numVal = val === '' ? 0 : Number(val);
    handleParameterChange(numVal, name);
  }

  const handleOpenModal = () => setOpenQuestionModal(true);
  const handleCloseModal = () => {
    setOpenQuestionModal(false);
    setSelectedQuestion(null);
  };

  const handleAddQuestion = () => {
    if (selectedQuestion && !currentPrompt.dependentQuestionIDs.find(qID => qID === selectedQuestion.id)) {
      setCurrentPrompt(prev => ({
        ...prev,
        dependentQuestionIDs: [...prev.dependentQuestionIDs, selectedQuestion.id]
      }));
      handleCloseModal();
    }
  };

  const handleRemoveQuestion = (questionId) => {
    setCurrentPrompt(prev => ({
      ...prev,
      dependentQuestionIDs: prev.dependentQuestionIDs.filter(qID => qID !== questionId),
    }));
  };

  const handleOutputFieldChange = (val) => {
    const filteredValue = val.replace(/[^a-z0-9_]/g, '');
    setCurrentPrompt(prev => ({
      ...prev,
      outputField: filteredValue,
    }));
  }

  const handleJsonOutputFieldChange = (val, fieldIdx) => {
    const filteredValue = val.replace(/[^a-z0-9_]/g, '');
    const updatedJsonOutputFields = currentPrompt.jsonOutputFields.map((f, idx) => idx === fieldIdx ? filteredValue : f);
    setCurrentPrompt(prev => ({
      ...prev,
      jsonOutputFields: updatedJsonOutputFields,
    }));
  }

  const handleRemoveJsonOutputField = (fieldIdx) => {
    const updatedJsonOutputFields = currentPrompt.jsonOutputFields.filter((f, idx) => idx !== fieldIdx);
    setCurrentPrompt(prev => ({
      ...prev,
      jsonOutputFields: updatedJsonOutputFields,
    }));
  }

  const handleAddJsonOutputField = () => {
    setCurrentPrompt(prev => ({
      ...prev,
      jsonOutputFields: [...currentPrompt.jsonOutputFields, ''],
    }));
  }

  const handleSave = async () => {
    try {
      const response = await authFetch('/dev/update-prompt', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ prompt: currentPrompt })
      });

      if (response.isSuccess === true) {
        showSuccessMessage(isEdit ? 'Prompt updated successfully!' : 'Prompt created successfully!');
        setCurrentPrompt(defaultPrompt);
        onSubmit();
      } else {
        console.error("Invalid response!", response);
        showErrorMessage("Something went wrong.");
      }
    } catch (error) {
      console.error("There was an error!", error);
      showErrorMessage(error.message || "An unknown error occurred.");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await authFetch('/dev/delete-prompt', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ promptID: currentPrompt.id })
      });

      if (response.isSuccess === true) {
        showSuccessMessage('Prompt has been deleted!');
        setCurrentPrompt(defaultPrompt);
        onSubmit();
      } else {
        console.error("Invalid response!", response);
        showErrorMessage("Something went wrong.");
      }
    } catch (error) {
      console.error("There was an error!", error);
      showErrorMessage(error.message || "An unknown error occurred.");
    }
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: 600, // Fixed width
      maxWidth: '100%', // Ensures responsiveness
      margin: 'auto',
      p: 5,
    }}>
      <Typography variant="h6" gutterBottom component="div" sx={{ textAlign: 'center' }}>
        {isEdit ? 'Edit Flow Config' : 'Add New Flow Config'}
      </Typography>
      <TextField sx={{ mt: 2 }}
        label="Prompt ID"
        name="id"
        required
        fullWidth
        value={currentPrompt.id}
        onChange={e => handlePromptIDChange(e.target.value)}
        helperText="Prompt ID is the identifier of this prompt, examples: ps_grad_p1, rl_industry"
        variant={isEdit ? 'filled' : 'outlined'}
        InputProps={{
          readOnly: isEdit,
        }}
      />
      <TextField
        label="Prompt Content"
        name="content"
        value={currentPrompt.content}
        onChange={e => setCurrentPrompt({ ...currentPrompt, content: e.target.value })}
        fullWidth
        sx={{ mt: 2 }}
        multiline={true}
        rows={10}
        variant="outlined"
      />

      <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
        Dependent Questions
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ ml: 1, mb: 1 }}>
        Dependent question should only be used when you want to use the user's answer to some specific question as part of your prompt. Example: you should add question "preferred_language" as dependent when you have this on your prompt: "Write the article use %preferred_language%"
      </Typography>
      <Button startIcon={<AddIcon />} onClick={handleOpenModal} variant="outlined" sx={{ mb: 2 }}>Add Question</Button>

      <Box sx={{ mb: 2 }}>
        {currentPrompt.dependentQuestionIDs.map((qID) => (
          <Chip
            key={qID}
            label={qID}
            onDelete={() => handleRemoveQuestion(qID)}
            color="primary"
            sx={{ mr: 1, mb: 1 }}
          />
        ))}
      </Box>

      <TextField
        id="prompt-model"
        select
        label="GPT Model"
        value={currentPrompt.model}
        onChange={(e) => setCurrentPrompt({ ...currentPrompt, model: e.target.value })}
        fullWidth
        sx={{ mt: 2 }}
      >
        {GPT_MODELS.map((model) => (
          <MenuItem key={model} value={model}>
            {model}
          </MenuItem>
        ))}
      </TextField>

      <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
        Parameters
      </Typography>
      <Box sx={{ ml: 3, mb: 2 }}>
        {Object.entries(GPT_PARAMETERS).map(([key, { label, min, max, step }]) => (
          <React.Fragment key={key}>
            <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>
              {label}
            </Typography>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs>
                <Slider
                  value={currentPrompt.parameters[key]}
                  onChange={(e) => handleParameterChange(e.target.value, key)}
                  aria-labelledby={`${key}-input-slider`}
                  min={min}
                  max={max}
                  step={step}
                />
              </Grid>
              <Grid item>
                <Input
                  value={currentPrompt.parameters[key]}
                  size="small"
                  onChange={(e) => handleInputChange(e.target.value, key)}
                  inputProps={{
                    step: step,
                    min: min,
                    max: max,
                    type: 'number',
                    'aria-labelledby': `${key}-input-slider`,
                  }}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
      </Box>

      <FormControlLabel
        control={<Checkbox checked={currentPrompt.useJson} onChange={(e) => setCurrentPrompt({ ...currentPrompt, useJson: e.target.checked })} />}
        label="JSON Response"
      />

      {currentPrompt.useJson && (
        <FormControlLabel
          control={<Checkbox checked={currentPrompt.parseJsonOutput} onChange={(e) => setCurrentPrompt({ ...currentPrompt, parseJsonOutput: e.target.checked })} />}
          label="Parse JSON Response to Get Outputs"
        />
      )}

      {currentPrompt.parseJsonOutput && (
        <Box>
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            JSON Output Fields
          </Typography>
          <Box sx={{ mt: 2, ml: 3 }}>
            {currentPrompt.jsonOutputFields.map((f, idx) => (
              <Stack direction="row" key={`field-${idx + 1}`}>
                <TextField sx={{ mt: 1 }}
                  label={`json output field ${idx + 1}`}
                  key={`field-${idx + 1}`}
                  name={`field-${idx + 1}`}
                  fullWidth
                  required
                  value={f}
                  onChange={e => handleJsonOutputFieldChange(e.target.value, idx)}
                />
                <IconButton onClick={() => handleRemoveJsonOutputField(idx)} color="error">
                  <DeleteIcon />
                </IconButton>
              </Stack>
            ))}
            <Button onClick={handleAddJsonOutputField} startIcon={<AddCircleOutlineIcon />} sx={{ mt: 1 }}>
              Add Field
            </Button>
          </Box>
        </Box>
      )}

      {!currentPrompt.parseJsonOutput && (
        <Box>
          <TextField sx={{ mt: 1 }}
            label="Output Field"
            name="output-field"
            fullWidth
            required
            value={currentPrompt.outputField}
            helperText="Please always prefix this field with 'output_' to differentiate it from questionID, examples: output_ps_grad_p1"
            onChange={e => handleOutputFieldChange(e.target.value)}
          />
        </Box>
      )}

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button fullWidth onClick={handleSave} variant="contained" color="primary">
          {isEdit ? 'Update Prompt' : 'Add Prompt'}
        </Button>
        {isEdit && (
          <Button fullWidth onClick={handleDelete} variant="contained" color="error" sx={{ ml: 2 }}>
            Delete Prompt
          </Button>
        )}
      </Box>

      <Dialog open={openQuestionModal} onClose={handleCloseModal}>
        <DialogTitle>Add Question</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Autocomplete
            options={questions}
            getOptionLabel={(option) => option.id}
            onChange={(event, value) => setSelectedQuestion(value)}
            renderInput={(params) => <TextField {...params} label="Select Question" variant="outlined" />}
            sx={{ mb: 2, width: 500 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddQuestion} color="primary">Add</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default PromptBuilder;
