import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, MenuItem, Typography, TextField, Autocomplete, IconButton, DialogActions, DialogContent, DialogTitle, Stack, Chip, Divider, Card } from '@mui/material';
import authFetch from '../../../utils/auth';
import { getAllSectionIDs } from '../../../utils/questionnaire/section';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getPromptOutputFields } from '../../../utils/prompt';
import { useInternal } from '../../../context/internalProvider';
import { QTYPE_QUESTION_GROUP } from '../../../constants/question';
import { useDialog } from '../../../context/dialogProvider';

const INPUT_FIELD_TYPE_QUESTION = 'question'
const INPUT_FIELD_TYPE_OUTPUT = 'output'
const INPUT_FIELD_TYPE_EXTENDED_OUTPUT = 'extended output'
const INPUT_FIELD_TYPES = [
  INPUT_FIELD_TYPE_QUESTION,
  INPUT_FIELD_TYPE_OUTPUT,
  INPUT_FIELD_TYPE_EXTENDED_OUTPUT,
]

const defaultGeneration = {
  id: '',
  flowConfigID: '',
  steps: [],
}

function GenerationBuilder({ generationProp, onSubmit }) {
  const { showSuccessMessage, showErrorMessage } = useDialog();
  const { sections, questions, flowConfigs, prompts } = useInternal();
  
  const [isEdit, setIsEdit] = useState(false);
  const [currentGeneration, setCurrentGeneration] = useState(defaultGeneration);
  const [flowQuestionIDs, setFlowQuestionIDs] = useState(new Set());
  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const [inputFieldType, setInputFieldType] = useState('');
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedOutputField, setSelectedOutputField] = useState(null);
  const [editingStepIdx, setEditingStepIdx] = useState(0);

  const updateFlowQuestionIDs = (fc, sections, questions) => {
    const sectionIDs = getAllSectionIDs(fc.startingSectionID, sections);
    const flowSections = sections.filter(s => sectionIDs.has(s.id));
    let qIDs = new Set();
    flowSections.forEach(s => {
      s.questionIDs.forEach(qID => {
        const q = questions.find(qq => qq.id === qID);
        if (q.type === QTYPE_QUESTION_GROUP) {
          q.childQuestionIDs.forEach(cqID => {
            qIDs.add(cqID);
          })
        } else {
          qIDs.add(qID);
        }
      })
    });
    setFlowQuestionIDs(qIDs);
  }

  useEffect(() => {
    if (generationProp) {
      setCurrentGeneration(generationProp);
      const fc = flowConfigs.find(fc => fc.id === generationProp.flowConfigID);
      if (fc) {
        updateFlowQuestionIDs(fc, sections, questions)
      }
      setIsEdit(true);
    } else {
      setCurrentGeneration(defaultGeneration);
      setIsEdit(false);
    }
  }, [generationProp, flowConfigs, sections, questions]);

  const handleGenerationIDChange = (val) => {
    // Regular expression to match only lowercase letters, digits, and underscores
    const filteredValue = val.replace(/[^a-z0-9_]/g, '');
    setCurrentGeneration({ ...currentGeneration, id: filteredValue })
  };

  const handleSelectFlowConfig = (fc) => {
    if (!fc) {
      return
    }
    setCurrentGeneration({ ...currentGeneration, flowConfigID: fc.id, steps: [] });
    updateFlowQuestionIDs(fc, sections, questions);
  };

  const handleAddStep = () => {
    let step = {
      inputFields: [],
      promptID: '',
    };
    setCurrentGeneration(prev => {
      return {
        ...prev,
        steps: [...prev.steps, step]
      };
    });
  }

  const handleRemoveStep = (stepIdx) => {
    setCurrentGeneration(prev => {
      return {
        ...prev,
        steps: prev.steps.filter((step, idx) => idx !== stepIdx),
      };
    });
  }

  const handleAddStepInput = (idx) => {
    setEditingStepIdx(idx);
    setOpenQuestionModal(true);
  }
  const handleCloseQuestionModal = () => {
    setOpenQuestionModal(false);
    setSelectedQuestion(null);
  };

  const handleAddStepInputField = () => {
    const fieldToAdd = getInputField();
    if (!currentGeneration.steps[editingStepIdx].inputFields.includes(fieldToAdd)) {
      const updatedSteps = currentGeneration.steps.map((step, idx) => {
        if (idx === editingStepIdx) {
          return { ...step, inputFields: [...step.inputFields, fieldToAdd] };
        }
        return step;
      });
      setCurrentGeneration(prev => ({
        ...prev,
        steps: updatedSteps,
      }));

      handleCloseQuestionModal();
    }
  };

  const getInputField = () => {
    if (inputFieldType === INPUT_FIELD_TYPE_QUESTION) {
      return {
        name: selectedQuestion.id,
        description: selectedQuestion.summary
      }
    }
    return {
      name: selectedOutputField,
      description: '',
    };
  }

  const handleRemoveStepInputField = (stepIdx, fieldIdx) => {
    const updatedSteps = currentGeneration.steps.map((step, idx) => {
      if (idx === stepIdx) {
        return { ...step, inputFields: step.inputFields.filter((f, fidx) => fidx !== fieldIdx) };
      }
      return step;
    });
    setCurrentGeneration(prev => ({
      ...prev,
      steps: updatedSteps,
    }));
  };

  const handleStepInputFieldDescriptionChange = (val, stepIdx, fieldIdx) => {
    const updatedSteps = currentGeneration.steps.map((step, idx) => {
      if (idx === stepIdx) {
        return {
          ...step, inputFields: step.inputFields.map((f, fidx) => {
            if (fidx === fieldIdx) {
              return { ...f, description: val };
            }
            return f;
          })
        };
      }
      return step;
    });
    setCurrentGeneration(prev => ({
      ...prev,
      steps: updatedSteps,
    }));
  }

  const handleSelectStepPrompt = (stepIdx, prompt) => {
    if (currentGeneration.steps.find(step => step.promptID === prompt.id)) {
      return;
    }
    const updatedSteps = currentGeneration.steps.map((step, idx) => {
      if (idx === stepIdx) {
        return { ...step, promptID: prompt ? prompt.id : '' };
      }
      return step;
    });
    setCurrentGeneration(prev => ({
      ...prev,
      steps: updatedSteps,
    }));
  }

  const getOutputFieldsOptions = () => {
    const fields = [];
    currentGeneration.steps.filter((step, idx) => idx < editingStepIdx).forEach(step => {
      const p = prompts.find(p => p.id === step.promptID);
      const pFields = getPromptOutputFields(p);
      fields.push(...pFields);
    });
    return fields;
  }

  const getExtendedOutputFieldsOptions = () => {
    const fields = [];
    prompts.forEach(p => {
      const pFields = getPromptOutputFields(p).map(f => {
        return {
          displayText: `${f} (${p.id})`,
          value: f,
        }
      });
      fields.push(...pFields);
    });
    return fields;
  }

  const handleSave = async () => {
    try {
      const response = await authFetch('/dev/update-generation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ generation: currentGeneration })
      });

      if (response.isSuccess === true) {
        showSuccessMessage(isEdit ? 'Flow config updated successfully!' : 'Flow config created successfully!');
        setCurrentGeneration(defaultGeneration);
        onSubmit();
      } else {
        console.error("Invalid response!", response);
        showErrorMessage("Something went wrong.");
      }
    } catch (error) {
      console.error("There was an error!", error);
      showErrorMessage(error.message || "An unknown error occurred.");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await authFetch('/dev/delete-generation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ generationID: currentGeneration.id })
      });

      if (response.isSuccess === true) {
        showSuccessMessage('Flow config has been deleted!');
        setCurrentGeneration(defaultGeneration);
        onSubmit();
      } else {
        console.error("Invalid response!", response);
        showErrorMessage("Something went wrong.");
      }
    } catch (error) {
      console.error("There was an error!", error);
      showErrorMessage(error.message || "An unknown error occurred.");
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minWidth: 500,
      maxWidth: '100%', // Ensures responsiveness
      margin: 'auto',
      p: 5,
    }}>
      <Typography variant="h6" gutterBottom component="div" sx={{ textAlign: 'center' }}>
        {isEdit ? 'Edit Generation' : 'Add New Generation'}
      </Typography>
      <TextField sx={{ mt: 2 }}
        label="Generation ID"
        name="id"
        required
        fullWidth
        value={currentGeneration.id}
        onChange={e => handleGenerationIDChange(e.target.value)}
        helperText="Generation ID is the identifier of a generation flow, examples: ps_grad, rl_research"
        variant={isEdit ? 'filled' : 'outlined'}
        InputProps={{
          readOnly: isEdit,
        }}
      />

      <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
        Associated Flow Config
      </Typography>
      <Autocomplete
        disablePortal
        id="flow-config"
        options={flowConfigs}
        value={currentGeneration.flowConfigID === '' ? null : flowConfigs.find(fc => fc.id === currentGeneration.flowConfigID)}
        getOptionLabel={(option) => option.id || ""}
        sx={{ width: '100%', p: 3 }}
        renderInput={(params) => <TextField {...params} />}
        onChange={(event, value) => handleSelectFlowConfig(value)}
      />

      <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
        Generation Steps
      </Typography>
      <Divider />

      {currentGeneration.steps.map((step, stepIdx) => (
        <Box sx={{ ml: 3, mt: 2 }} key={`step-${stepIdx}`}>
          <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }} >
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
              {`Step ${stepIdx + 1}`}
            </Typography>
            <IconButton onClick={() => handleRemoveStep(stepIdx)} color="error">
              <DeleteIcon />
            </IconButton>
          </Stack>
          <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="subtitle1" gutterBottom>
              Input Fields
            </Typography>
            <Button startIcon={<AddIcon />} onClick={() => handleAddStepInput(stepIdx)} variant="text" size='small' sx={{ ml: 2 }}>
              Add
            </Button>
          </Stack>
          {step.inputFields.map((field, fieldIdx) => (
            <Card direction="row" key={`field-${fieldIdx}`} sx={{ p: 2, ml: 2, mb: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Chip
                  key={field.name}
                  label={field.name}
                  variant='outlined'
                  color="primary"
                  sx={{ mr: 1, mb: 1 }}
                />
                <IconButton onClick={() => handleRemoveStepInputField(stepIdx, fieldIdx)} color="error">
                  <DeleteIcon />
                </IconButton>
              </Box>
              <TextField
                label="Input Field Description"
                name="field-description"
                required
                fullWidth
                variant='standard'
                value={field.description}
                onChange={e => handleStepInputFieldDescriptionChange(e.target.value, stepIdx, fieldIdx)}
              />
            </Card>
          ))}
          <Autocomplete
            disablePortal
            id="prompt-id"
            options={prompts}
            value={step.promptID === '' ? null : prompts.find(p => p.id === step.promptID)}
            getOptionLabel={(option) => option.id || ""}
            sx={{ width: '100%', mt: 1 }}
            renderInput={(params) => <TextField {...params} label="Prompt" />}
            onChange={(event, value) => handleSelectStepPrompt(stepIdx, value)}
          />
          <Divider sx={{ mt: 2 }} />
        </Box>
      ))}

      <Button onClick={handleAddStep} startIcon={<AddCircleOutlineIcon />} variant='outlined' sx={{ mt: 2 }}>
        Add Step
      </Button>

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button fullWidth onClick={handleSave} variant="contained" color="primary">
          {isEdit ? 'Update Generation' : 'Add Generation'}
        </Button>
        {isEdit && (
          <Button fullWidth onClick={handleDelete} variant="contained" color="error" sx={{ ml: 2 }}>
            Delete Generation
          </Button>
        )}
      </Box>

      <Dialog open={openQuestionModal} onClose={handleCloseQuestionModal}>
        <DialogTitle>Add Input Field</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseQuestionModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            id="input-field-type"
            select
            label="Input Field Type"
            value={inputFieldType}
            onChange={(e) => setInputFieldType(e.target.value)}
            fullWidth
            sx={{ mt: 2, width: 550 }}
          >
            {INPUT_FIELD_TYPES.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
          {inputFieldType === INPUT_FIELD_TYPE_QUESTION && (
            <Autocomplete
              options={questions.filter(q => flowQuestionIDs.has(q.id))}
              getOptionLabel={(option) => option.id}
              onChange={(event, value) => setSelectedQuestion(value)}
              renderInput={(params) => <TextField {...params} label="Select Question" variant="outlined" />}
              sx={{ mt: 2, width: 550 }}
            />
          )}
          {inputFieldType === INPUT_FIELD_TYPE_OUTPUT && (
            <Autocomplete
              options={getOutputFieldsOptions()}
              getOptionLabel={(option) => option}
              onChange={(event, value) => setSelectedOutputField(value)}
              renderInput={(params) => <TextField {...params} label="Select Output Field" variant="outlined" />}
              sx={{ mt: 2, width: 550 }}
            />
          )}
          {inputFieldType === INPUT_FIELD_TYPE_EXTENDED_OUTPUT && (
            <Autocomplete
              options={getExtendedOutputFieldsOptions()}
              getOptionLabel={(option) => option.displayText}
              onChange={(event, value) => setSelectedOutputField(value.value)}
              renderInput={(params) => <TextField {...params} label="Select Extended Output Field" variant="outlined" />}
              sx={{ mt: 2, width: 550 }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddStepInputField} color="primary">Add</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default GenerationBuilder;
