import { Box, Typography, Divider, IconButton } from '@mui/material';
import { getIcon, getFlowTypeIcon } from '../../utils/assets';
import { useNavigate } from 'react-router-dom';

export default function FlowHeader({ flowInfo, handleRename }) {
    const navigate = useNavigate();
    const { flowName, flowType, flowTypeDisplay } = flowInfo;
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            alignSelf: 'stretch',
            pt: '30px',
            pl: '50px',
        }}>
            <IconButton variant='icon' onClick={() => navigate('/mydoc')} sx={{
                width: '20px',
                height: '30px',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            }}>
                <img src={getIcon('icon-arrow-down')} alt="arrow-down" />
            </IconButton>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '20px',
                flex: '1 0 0',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'left',
                    alignItems: 'center',
                    gap: '10px',
                    minWidth: '80px',
                }}>
                    <img src={getFlowTypeIcon({ flowType: flowType })} alt="article" height={'20px'} width={'20px'} />
                    <Typography variant="body1"> {flowTypeDisplay} </Typography>
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '5px',
                }}>
                    <Typography variant="h5" sx={{
                        maxWidth: '900px',
                        overflow: 'hidden', // hide text that overflows the container
                        whiteSpace: 'nowrap', // keep text on a single line
                        textOverflow: 'ellipsis' // show ellipsis when text overflows
                     }}>{flowName}</Typography>
                    <IconButton onClick={() => handleRename()}>
                        <img src={getIcon('icon-edit@2x')} alt="edit" />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
}