import React from 'react';
import { getIcon } from '../../utils/assets';
import { Chip, Typography } from '@mui/material';

export default function PriceWithCoinChip({ price }) {
    return (
        <Chip icon={<img src={getIcon('icon-coins')} height={'18px'} alt='coins'></img>} label={
            <Typography variant='card_chip' color={'primary.main'}>{price}</Typography>
        } size='small' color='third' sx={{ width: 'wrap', height: '19px' }} />
    );
}