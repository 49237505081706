import React from 'react';
import { Typography, Box, Divider, Stack } from '@mui/material';

function displayExperience(item, index) {
  return (
    <Box key={index}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <Typography variant="cv_content_program_title">{item.title}</Typography>
        <Typography variant="cv_content_program_info">{item.location}</Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <Typography variant="cv_content_program_info_italic">{item.subtitle}</Typography>
        <Typography variant="cv_content_program_info">{item.timePeriod}</Typography>
      </Box>
      {item.additionalDetails.map(((line, lineIdx) => (
        <Stack direction="row" alignItems="flex-start" spacing={1} key={lineIdx}>
          <ul style={{ paddingLeft: '20px' }}>
            <li><Typography variant="cv_content_program_info">{line}</Typography></li>
          </ul>
        </Stack>
      )))}
    </Box>
  );
}

function CVTemplateV1({ resumeData }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
        <Typography variant="cv_title_name">{resumeData.name}</Typography>
        <Typography variant="cv_title_info" sx={{ textAlign: 'center' }}>{resumeData.basicInfo}</Typography>
      </Box>
      <Divider sx={{ borderTop: '2px solid' }} />
      {resumeData.education && (
        <>
          <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="cv_content_category">EDUCATION</Typography>
          </Box>
          {resumeData.education.map((edu, index) => (
            <Box key={index} mb={2}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
                <Typography variant="cv_content_program_title">{edu.institution}</Typography>
                <Typography variant="cv_content_program_info">{edu.location}</Typography>
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
                <Typography variant="cv_content_program_info">{edu.degree}</Typography>
                <Typography variant="cv_content_program_info">{edu.timePeriod}</Typography>
              </Box>
              {edu.additionalDetails.map(((line, lineIdx) => (
                <Stack direction="row" alignItems="flex-start" spacing={1} key={lineIdx}>
                  <Typography variant="cv_content_program_info_2">{line}</Typography>
                </Stack>
              )))}
            </Box>
          ))}</>
      )}
      {resumeData.researchExperiences && resumeData.researchExperiences.length > 0 && (
        <>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}>
            <Typography variant="cv_content_category">RESEARCH EXPERIENCES</Typography>
          </Box>
          {resumeData.researchExperiences.map((exp, index) => displayExperience(exp, index))}
        </>
      )}
      {resumeData.professionalExperiences && resumeData.professionalExperiences.length > 0 && (
        <>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}>
            <Typography variant="cv_content_category">PROFESSIONAL EXPERIENCES</Typography>
          </Box>
          {resumeData.professionalExperiences.map((exp, index) => displayExperience(exp, index))}
        </>
      )}
      {resumeData.extracurricularActivities && resumeData.extracurricularActivities.length > 0 && (
        <>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}>
            <Typography variant="cv_content_category">EXTRACURRICULAR ACTIVITIES</Typography>
          </Box>
          {resumeData.extracurricularActivities.map((exp, index) => displayExperience(exp, index))}
        </>
      )}
      {resumeData.skills && (
        <>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}>
            <Typography variant="cv_content_category">SKILLS & INTERESTS</Typography>
          </Box>
          {resumeData.skills.technical && (
            <Stack direction={'row'} gap={'5px'}>
              <Typography variant="cv_content_program_info_bold" sx={{ fontWeight: 'bold' }}>Technical:</Typography>
              <Typography variant="cv_content_program_info" >{resumeData.skills.technical}</Typography>
            </Stack>
          )}
          {resumeData.skills.languages && (
            <Stack direction={'row'} gap={'5px'}>
              <Typography variant="cv_content_program_info_bold" sx={{ fontWeight: 'bold' }}>Languages:</Typography>
              <Typography variant="cv_content_program_info" >{resumeData.skills.languages}</Typography>
            </Stack>
          )}
          {resumeData.skills.laboratory && (
            <Stack direction={'row'} gap={'5px'}>
              <Typography variant="cv_content_program_info_bold" sx={{ fontWeight: 'bold' }}>Laboratory:</Typography>
              <Typography variant="cv_content_program_info" >{resumeData.skills.laboratory}</Typography>
            </Stack>
          )}
          {resumeData.skills.interests && (
            <Stack direction={'row'} gap={'5px'}>
              <Typography variant="cv_content_program_info_bold" sx={{ fontWeight: 'bold' }}>Interests:</Typography>
              <Typography variant="cv_content_program_info" >{resumeData.skills.interests}</Typography>
            </Stack>
          )}
        </>
      )}
      {resumeData.publications && resumeData.publications.length > 0 && (
        <>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}>
            <Typography variant="cv_content_category">PUBLICATIONS</Typography>
          </Box>
          {resumeData.publications.map(((line, lineIdx) => (
            <Stack direction="row" alignItems="flex-start" spacing={1} key={lineIdx}>
              <ul style={{ paddingLeft: '20px' }}>
                <li><Typography variant="cv_content_program_info_2">{line}</Typography></li>
              </ul>
            </Stack>
          )))}
        </>
      )}
      {resumeData.conferences && resumeData.conferences.length > 0 && (
        <>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}>
            <Typography variant="cv_content_category">CONFERENCES</Typography>
          </Box>
          {resumeData.conferences.map(((line, lineIdx) => (
            <Stack direction="row" alignItems="flex-start" spacing={1} key={lineIdx}>
              <ul style={{ paddingLeft: '20px' }}>
                <li><Typography variant="cv_content_program_info_2">{line}</Typography></li>
              </ul>
            </Stack>
          )))}
        </>
      )}
    </Box>
  );
}

export default CVTemplateV1;
