const PROPERTY_TYPE_SERVICE = 'property_type_service';
const PROPERTY_TYPE_PRICE = 'property_type_price';
const PROPERTY_TYPE_ESTIMATED_DELIVERY_TIME = 'property_type_estimated_delivery_time';
const PROPERTY_TYPE_DELIVERY_CONTENT = 'property_type_delivery_content';

module.exports = {
    PROPERTY_TYPE_SERVICE,
    PROPERTY_TYPE_PRICE,
    PROPERTY_TYPE_ESTIMATED_DELIVERY_TIME,
    PROPERTY_TYPE_DELIVERY_CONTENT,
};