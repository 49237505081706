import * as React from 'react';
import Box from '@mui/material/Box';
import ArticleContent from './articleContent';
import ArticleButtonGroup from './ArticleButtonGroup';
import ArticleRating from './articleRating';
import { BackDropLoading } from '../loading/backDropLoading';
import { useArticle } from '../../context/articleProvider';
import { useDialog } from '../../context/dialogProvider';
import MarkdownParser from '../dataDisplay/markdown';
import { Fab, Paper, Typography } from '@mui/material';
import { FLOWTYPE_RLP } from '../../constants/flow';
import { CONTENT_TYPE_ARTICLE, CONTENT_TYPE_REPORT } from '../../constants/article';
import ShareIcon from '@mui/icons-material/Share';
import { DIALOG_TYPE_INFO } from '../../constants/dialog';
import { useNavigate } from 'react-router-dom';

export default function ArticleDisplay({ flowId }) {
  const navigate = useNavigate();
  const { flowType, content, analysis, rating, displayTexts, cvTemplateType } = useArticle();
  const { isLoading, openDialog } = useDialog();

  if (isLoading) {
    return (
      <BackDropLoading />
    );
  } else {
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            padding: '60px 50px 50px 50px',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '25px',
            flex: '1 0 auto',
            alignSelf: 'stretch',
            minWidth: '660px',
          }}
        >
          <ArticleButtonGroup />
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignSelf: 'stretch',
            gap: '40px',
          }}>
            {!content && (
              <BackDropLoading />
            )}
            {(content || analysis) && (
              getContentDisplay(content, analysis, flowType, displayTexts, cvTemplateType)
            )}
            {rating && (
              <ArticleRating flowId={flowId} rating={rating} />
            )}
          </Box>
        </Box>
        <Fab
          variant='extended'
          color='primary'
          style={{
            position: 'fixed',
            bottom: '500px',  // distance from the bottom of the screen
            right: '16px',   // distance from the right side of the screen
            zIndex: 1000,    // make sure it floats above other elements
          }}
          onClick={
            () => openDialog(DIALOG_TYPE_INFO, {
                title: displayTexts.freeProgramTitle,
                description: displayTexts.freeProgramDescription,
                confirmBtn: displayTexts.freeProgramActionText,
            }, () => {navigate('/pricing')})}>
          <ShareIcon sx={{ mr: 1 }} />
          {displayTexts.freeProgramButtonText}
        </Fab>
      </Box>
    );
  }
}

function getContentDisplay(article, analysis, flowType, displayTexts, cvTemplateType) {
  switch (flowType) {
    case FLOWTYPE_RLP:
    //case FLOWTYPE_CVP:
      return getPolishContentDisplay(article, analysis, flowType, displayTexts, cvTemplateType);
    default:
      return getDefaultContentDisplay(article, flowType, displayTexts, cvTemplateType);
  }
}

function getPolishContentDisplay(article, analysis, flowType, displayTexts, cvTemplateType) {
  return (
    <React.Fragment>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
        <Typography variant="h5" sx={{ color: 'text.primary', textAlign: 'center' }} gutterBottom >{displayTexts.afterPolishedTitle}</Typography>
        <Paper elevation={0} sx={{
          bgcolor: 'bg_article.main',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignSelf: 'center',
          padding: '15mm',
          overflow: 'auto',
          border: '4px solid var(--bg-dark, #F0F0EB)',
          width: '794px',
          minHeight: '1123px',
        }}>
          <ArticleContent content={article} flowType={flowType} template={cvTemplateType} contentTypeIdentifier={CONTENT_TYPE_ARTICLE} />
        </Paper>
        <Box sx={{ height: '30px' }}></Box>
        <Typography variant="h5" sx={{ color: 'text.primary', textAlign: 'center' }} gutterBottom >{displayTexts.polishReportTitle}</Typography>
        <Paper elevation={0} sx={{
          bgcolor: 'bg_article.main',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignSelf: 'center',
          padding: '15mm',
          overflow: 'auto',
          border: '4px solid var(--bg-dark, #F0F0EB)',
          width: '794px',
          minHeight: '1123px',
        }}>
          <ArticleContent content={analysis} flowType={flowType} template={cvTemplateType} contentTypeIdentifier={CONTENT_TYPE_REPORT} />
        </Paper>
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
      }}>
        <Box sx={{
          maxWidth: '820px'
        }}>
          <MarkdownParser text={displayTexts.disclaimer} textType={'subtitle2'} textAlignType={'center'} />
        </Box>
      </Box>
    </React.Fragment>
  )
}

function getDefaultContentDisplay(article, flowType, displayTexts, cvTemplateType){
  return (
    <React.Fragment>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
        <Paper elevation={0} sx={{
          bgcolor: 'bg_article.main',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignSelf: 'center',
          padding: '15mm',
          overflow: 'auto',
          border: '4px solid var(--bg-dark, #F0F0EB)',
          width: '794px',
          minHeight: '1123px',
        }}>
          <ArticleContent content={article} flowType={flowType} template={cvTemplateType} contentTypeIdentifier={CONTENT_TYPE_ARTICLE} />
        </Paper>
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
      }}>
        <Box sx={{
          maxWidth: '820px'
        }}>
          <MarkdownParser text={displayTexts.disclaimer} textType={'subtitle2'} textAlignType={'center'} />
        </Box>
      </Box>
    </React.Fragment>
  );
}