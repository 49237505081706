import React from 'react';
import ReactECharts from 'echarts-for-react';


export default function NewUserAmountLineChart({ data }) {
	const [xAxisData, setXAxisData] = React.useState([]);
	const [yAxisData, setYAxisData] = React.useState([]);

	React.useEffect(() => {
		const groupedData = {};
		data.forEach(unixTime => {
			const time = new Date(unixTime).toLocaleDateString();
			const date = time.split(' ')[0]; // X-axis interval: 1 day
			if (groupedData[date]) {
				groupedData[date]++;
			} else {
				groupedData[date] = 1;
			}
		});
		setXAxisData(Object.keys(groupedData));
		setYAxisData(Object.values(groupedData));
	}, [data]);

	const chartOptions = {
		xAxis: {
			type: 'category',
			data: xAxisData,
			axisLabel: {
				interval: 'auto',
				rotate: 45, // Rotate x-axis label for better readability
			}
		},
		yAxis: {
			type: 'value',
			name: 'Number of New Users'
		},
		series: [{
			data: yAxisData,
			type: 'line',
			lineStyle: {
				color: '#D97757',
				width: 2.5,
				type: 'solid'
			},
			itemStyle: {
				color: 'grey'
			}
		}],
		tooltip: {
			trigger: 'axis',
			formatter: function (params) {
				var date = params[0].name;
				var count = params[0].value;
				return date + '<br/>Number of New Users: ' + count;
			}
		},
		dataZoom: [{
			type: 'slider',
			xAxisIndex: 0
		}],
	};

	return (
		<ReactECharts option={chartOptions} />
	);
}