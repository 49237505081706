import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';


export default function ConfirmDialog({ open, handleClose, handleConfirm, content }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-content-title"
      aria-describedby="alert-content-description"
      sx={{
        '& .MuiDialog-paper': {
          width: '640px',
          maxWidth: 'none',
          padding: '5px 0px 10px 0px',
        }
      }}
    >
      <DialogTitle id="alert-content-title">
        {content.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-content-description">
          {content.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {content.cancelBtn && (<Button sx={{ boxShadow: 'none' }} onClick={() => handleClose()}>{content.cancelBtn}</Button>)}
        {content.confirmBtn && (<Button variant='contained' sx={{ boxShadow: 'none' }} onClick={() => handleConfirm()} autoFocus>{content.confirmBtn}</Button>)}
      </DialogActions>
    </Dialog>
  )
}