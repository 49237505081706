import React, { useEffect, useState } from 'react';
import { Typography, Box, Button, Collapse, IconButton, Divider } from '@mui/material';
import QuestionBasic from './questionBasic';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpIcon from '@mui/icons-material/Help';

function getDefaultInfoBlock(questionDisplay) {
  let infoBlock = {};
  questionDisplay.childQuestionDisplays.forEach(cqd => infoBlock[cqd.id] = "");
  return infoBlock;
}

function QuestionInsert({ questionDisplay, infoList, onAnswerChange, readOnly, validationErrors }) {
  const defaultInfoBlock = getDefaultInfoBlock(questionDisplay);
  const [hintOpen, setHintOpen] = useState(false);
  const [groupInstances, setGroupInstances] = useState([defaultInfoBlock]);

  useEffect(() => {
    if (infoList[questionDisplay.id]) {
      setGroupInstances(JSON.parse(infoList[questionDisplay.id]));
    }
  }, [questionDisplay, infoList]);

  const handleAddGroupInstance = () => {
    setGroupInstances([...groupInstances, defaultInfoBlock]);
  };

  const handleRemoveGroupInstance = (index) => {
    const updatedInstances = [...groupInstances];
    updatedInstances.splice(index, 1);
    setGroupInstances(updatedInstances);
    if (onAnswerChange) {
      onAnswerChange(questionDisplay.id, JSON.stringify(updatedInstances));
    }
  };

  const handleAnswerChange = (index, questionId, answer) => {
    const updatedInstances = [...groupInstances];
    if (!updatedInstances[index]) {
      updatedInstances[index] = {};
    }
    updatedInstances[index][questionId] = answer;
    setGroupInstances(updatedInstances);
    if (onAnswerChange) {
      onAnswerChange(questionDisplay.id, JSON.stringify(updatedInstances));
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{
        display: 'flex',
        gap: '8px',
        alignSelf: 'stretch',
      }}>
        <Box sx={{
          display: 'flex',
          padding: '10px 15px',
          alignItems: 'center',
          gap: '10px',
          borderRadius: '20px',
          backgroundColor: 'bg_skin.main',
        }}>
          <Typography variant="body1" sx={{ fontWeight: 'bold', lineHeight: '20px' }} style={{ display: "inline-block", whiteSpace: "pre-line" }}>
            {questionDisplay.isMandatory && <span style={{ color: 'red' }}>*</span>}{questionDisplay.instructionDisplay}
          </Typography>
        </Box>
        {!readOnly && questionDisplay.hasHint && (
          <HelpIcon
            onClick={() => setHintOpen(!hintOpen)}
            aria-expanded={hintOpen}
            variant="text"
            fontSize='small'
            color="primary"
            sx={{
              alignSelf: 'center',
            }}
          />
        )}
      </Box>
      <Collapse in={hintOpen} sx={{ ml: 1 }}>
        <Typography variant="caption" color="text.secondary" style={{ display: "inline-block", whiteSpace: "pre-line" }} sx={{ mt: 1 }}>
          {questionDisplay.hintDisplay}
        </Typography>
      </Collapse>
      {groupInstances.map((instance, index) => (
        <Box sx={{ mt: 1 }} key={index}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            alignSelf: 'stretch',
            height: '40px',
          }}>
            <Typography sx={{ fontWeight: 'bold', color: 'primary.main' }}>•</Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {questionDisplay.infoBlockName} {index + 1}
            </Typography>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
              <Divider sx={{ flexGrow: 1, borderStyle: 'dashed', borderColor: 'bg_skin.main', borderBottomWidth: '2px' }} />
            </Box>
            {!readOnly && groupInstances.length > 1 && (
              <IconButton onClick={() => handleRemoveGroupInstance(index)} color="error">
                <DeleteIcon color='primary' fontSize='small' />
              </IconButton>
            )}
          </Box>
          <Box sx={{
            display: 'flex',
            padding: '30px 30px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '10px',
            alignSelf: 'stretch',
            borderRadius: '20px',
            backgroundColor: 'bg_dark.main',
          }}>
            {questionDisplay.childQuestionDisplays.map(cqd => (
              <QuestionBasic
                key={cqd.id}
                questionDisplay={cqd}
                answer={instance[cqd.id]}
                onAnswerChange={(qid, answer) => handleAnswerChange(index, cqd.id, answer)}
                readOnly={readOnly}
                errorMessage={validationErrors[questionDisplay.id]?.[index]?.[cqd.id]}
              />
            ))}
          </Box>
        </Box>
      ))}

      {!readOnly && (
        <Button
          startIcon={<AddCircleOutlineIcon />}
          onClick={handleAddGroupInstance}
          sx={{ mt: 1 }}
        >
          {questionDisplay.addInfoBlockDisplay}
        </Button>
      )}
    </Box>
  );
}

export default QuestionInsert;
