import React from 'react';
import { Typography, Button, Paper, Box, Chip } from '@mui/material';
import { KeyValueList } from '../../dataDisplay/keyValueList';

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapes special characters
};

const getPattern = (qID) => {
  // Include '%' in the escaped pattern
  const pattern = `%${qID}%`;
  return escapeRegExp(pattern);
};

const HighlightText = ({ text, patterns }) => {
  if (patterns.length === 0) {
    return (
      <Typography component="span" sx={{ whiteSpace: 'pre-wrap' }}>
        {text}
      </Typography>
    )
  }

  const regex = new RegExp(`(${patterns.join('|')})`, 'gi');
  const parts = text.split(regex);

  return (
    <Typography component="span" sx={{ whiteSpace: 'pre-wrap' }}>
      {parts.map((part, index) =>
        regex.test(part) ? (
          <span key={index} style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}>
            {part}
          </span>
        ) : (
          <span key={index}>{part}</span>
        )
      )}
    </Typography>
  );
};

function PromptPreview({ prompt, onEdit }) {
  if (!prompt) {
    return <Typography>Select a prompt to view its details.</Typography>;
  }

  const patterns = prompt.dependentQuestionIDs.map(getPattern);

  return (
    <Paper elevation={3} sx={{ p: 3, m: 3, overflow: 'auto' }}>
      <Box display="flex" flexDirection="column">
        <Typography variant="h6" component="h2" sx={{ mb: 2, fontWeight: 'medium', textAlign: 'center' }}>
          {prompt.id}
        </Typography>
        <Box sx={{ mb: 4, width: '100%' }}>
          <HighlightText text={prompt.content} patterns={patterns} />
        </Box>

        <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
          Dependent Questions
        </Typography>
        <Box sx={{ mb: 2 }}>
          {prompt.dependentQuestionIDs.map((qID) => (
            <Chip
              key={qID}
              label={qID}
              color="secondary"
              variant='outlined'
              sx={{ mr: 1, mb: 1 }}
            />
          ))}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
          <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
            Model
          </Typography>
          <Chip label={prompt.model} color="primary" variant="outlined" size="small" sx={{ fontWeight: 'bold', ml: 3 }} />
        </Box>

        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Parameters
        </Typography>
        <KeyValueList data={prompt.parameters} />

        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
          <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
            Response Format
          </Typography>
          <Chip label={prompt.useJson ? 'JSON' : 'Normal'} color="primary" variant="outlined" size="small" sx={{ fontWeight: 'bold', ml: 3 }} />
        </Box>

        {prompt.parseJsonOutput && (
          <Box>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
              JSON Output Fields
            </Typography>
            {prompt.jsonOutputFields.map((f, idx) => (
              <Chip sx={{ mt: 1, ml: 1 }}
                label={f}
                key={`field-${idx + 1}`}
                name={`field-${idx + 1}`}
                variant='outlined'
                color='success'
              />
            ))}
          </Box>
        )}

        {!prompt.parseJsonOutput && (
          <Box>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Output Field
            </Typography>
            <Chip sx={{ mt: 1, ml: 1 }}
              label={prompt.outputField}
              name="output-field"
              variant='outlined'
              color='success'
            />
          </Box>
        )}
      </Box>
      {onEdit && <Button onClick={onEdit} variant="contained" color="primary" sx={{ mt: 3 }}>
        Edit Prompt
      </Button>}
    </Paper>
  );
}

export default PromptPreview;
