import React from 'react';
import { Box, Button } from '@mui/material';
import authFetch from '../../utils/auth';
import { useLocation } from 'react-router-dom';
import Loading from '../loading/loading';
import MarkdownParser from '../dataDisplay/markdown';

export default function Generating({ flowType }) {
    let location = useLocation();
    const [content, setContent] = React.useState(null);
    const type = flowType || location.state.flowType;
    const fetchSuccessInfo = (flowType) => {
        authFetch('/article/generating-display', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ flowType: flowType }),
        })
        .then(data => {
            setContent(data);
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    React.useEffect(() => {
        fetchSuccessInfo(type);
    }, [type]);

    if (content) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '80%',
                    gap: 7,
                }}
            >
                <Loading size={75} />
                <MarkdownParser text={content.description} textType={'subtitle1'} textAlignType={'center'}/>
                <Box>
                    {content.action.map((action, index) => (
                        <Button key={index} variant="contained" color="primary" href="/mydoc" sx={{ width: '100px', boxShadow: 'none' }}>
                            {action.displayText}
                        </Button>
                    ))}
                </Box>
            </Box>
        );
    }
}
