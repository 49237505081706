import * as React from 'react';
import ContactDisplay from '../components/contact/contactDisplay';
import FullLayout from '../components/layout/full';
import { Helmet } from 'react-helmet';

export default function Contact() {
    return (
        <>
            <Helmet>
                <meta name="description" content='如有任何疑问，欢迎联系我们.' />
            </Helmet>
            <FullLayout mainContent={<ContactDisplay />} />
        </>
    );
}