import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, FormControl, InputLabel, MenuItem, Select, Typography, TextField, Autocomplete, Chip, IconButton, DialogActions, DialogContent, DialogTitle, Stack, FormControlLabel, Checkbox } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import authFetch from '../../../utils/auth';
import TranslateIcon from '@mui/icons-material/Translate';
import DeleteIcon from '@mui/icons-material/Delete';
import { AVAILABLE_LOCALES, DEFAULT_TRANSLATION } from '../../../constants/locale';
import { AFTERSECTION_CONDITIONAL, AFTERSECTION_DIRECT, AFTERSECTION_SUBMIT } from '../../../constants/section';
import { getAllSectionIDs } from '../../../utils/questionnaire/section';
import { useInternal } from '../../../context/internalProvider';
import { useDialog } from '../../../context/dialogProvider';

const defaultSection = {
  id: '',
  title: DEFAULT_TRANSLATION,
  questionIDs: [],
  afterSection: AFTERSECTION_DIRECT,
  nextSectionDirect: '',
  nextSectionsConditional: {
    keyQuestionID: '',
    nextSectionByOption: {}
  },
  needGeneration: false,
}

function cleanSection(section) {
  switch (section.afterSection) {
    case AFTERSECTION_CONDITIONAL:
      section.nextSectionDirect = '';
      break;
    case AFTERSECTION_DIRECT:
      section.nextSectionsConditional = {
        keyQuestionID: '',
        nextSectionByOption: {}
      };
      break;
    case AFTERSECTION_SUBMIT:
      section.nextSectionDirect = '';
      section.nextSectionsConditional = {
        keyQuestionID: '',
        nextSectionByOption: {}
      };
      break;
    default:
      break;
  }

  return section;
}

function SectionBuilder({ sectionProp, onSubmit }) {
  const { showSuccessMessage, showErrorMessage } = useDialog();
  const { sections, questions } = useInternal();

  const [isEdit, setIsEdit] = useState(false);
  const [currentSection, setCurrentSection] = useState(defaultSection);
  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [keyQuestion, setKeyQuestion] = useState(null);
  const [openTranslationModal, setOpenTranslationModal] = useState(false);
  const [currentEditing, setCurrentEditing] = useState(null);
  const [selectedLocale, setSelectedLocale] = useState(AVAILABLE_LOCALES[0]);
  const [translationText, setTranslationText] = useState('');
  const [eligibleNextSectionIDs, setEligibleNextSectionIDs] = useState([]);

  useEffect(() => {
    if (sectionProp) {
      setCurrentSection(sectionProp);
      setIsEdit(true);
      if (sectionProp.afterSection === AFTERSECTION_CONDITIONAL) {
        setKeyQuestion(questions.find(q => q.id === sectionProp.nextSectionsConditional.keyQuestionID));
      }
    } else {
      setCurrentSection(defaultSection);
      setIsEdit(false);
    }
  }, [sectionProp, questions]);

  useEffect(() => {
    if (sectionProp) {
      const sIDs = sections.map(s => s.id).filter(sID => !getAllSectionIDs(sID, sections).has(sectionProp.id));
      setEligibleNextSectionIDs(sIDs);
    } else {
      setEligibleNextSectionIDs(sections.map(s => s.id));
    }
  }, [sectionProp, sections]);

  const handleSectionIDChange = (val) => {
    // Regular expression to match only lowercase letters, digits, and underscores
    const filteredValue = val.replace(/[^a-z0-9_]/g, '');
    setCurrentSection({ ...currentSection, id: filteredValue })
  };

  const handleOpenModal = () => setOpenQuestionModal(true);
  const handleCloseModal = () => {
    setOpenQuestionModal(false);
    setSelectedQuestion(null);
  };

  const handleAddQuestion = () => {
    if (selectedQuestion && !currentSection.questionIDs.find(qID => qID === selectedQuestion.id)) {
      setCurrentSection(prev => ({
        ...prev,
        questionIDs: [...prev.questionIDs, selectedQuestion.id]
      }));
      handleCloseModal();
    }
  };

  const handleRemoveQuestion = (questionId) => {
    setCurrentSection(prev => ({
      ...prev,
      questionIDs: prev.questionIDs.filter(qID => qID !== questionId),
      nextSectionsConditional: {
        keyQuestionID: (keyQuestion && keyQuestion.id === questionId) ? '' : prev.nextSectionsConditional.keyQuestionID,
        nextSectionByOption: (keyQuestion && keyQuestion.id === questionId) ? {} : prev.nextSectionsConditional.nextSectionByOption,
      },
    }));
    if (keyQuestion && keyQuestion.id === questionId) {
      setKeyQuestion(null);
    }
  };

  const handleKeyQuestionChange = (q) => {
    setCurrentSection({
      ...currentSection,
      nextSectionsConditional: {
        ...currentSection.nextSectionsConditional,
        keyQuestionID: q.id,
        nextSectionByOption: {},
      },
    })
    setKeyQuestion(q);
  }

  const handleSave = async () => {
    try {
      const response = await authFetch('/dev/update-section', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ section: cleanSection(currentSection) })
      });

      if (response.isSuccess === true) {
        showSuccessMessage(isEdit ? 'Section updated successfully!' : 'Section created successfully!');
        setCurrentSection(defaultSection);
        onSubmit();
      } else {
        console.error("Invalid response!", response);
        showErrorMessage("Something went wrong.");
      }
    } catch (error) {
      console.error("There was an error!", error);
      showErrorMessage(error.message || "An unknown error occurred.");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await authFetch('/dev/delete-section', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ sectionID: currentSection.id })
      });

      if (response.isSuccess === true) {
        showSuccessMessage('Section has been deleted!');
        setCurrentSection(defaultSection);
        onSubmit();
      } else {
        console.error("Invalid response!", response);
        showErrorMessage("Something went wrong.");
      }
    } catch (error) {
      console.error("There was an error!", error);
      showErrorMessage(error.message || "An unknown error occurred.");
    }
  };

  const handleOpenTranslationModal = (type) => {
    setCurrentEditing({ type });
    setOpenTranslationModal(true);
  };

  const handleSaveTranslation = () => {
    setCurrentSection({
      ...currentSection,
      [currentEditing.type]: {
        ...currentSection[currentEditing.type],
        [selectedLocale]: translationText
      }
    });
    handleCloseTranslationModal();
  };

  const handleCloseTranslationModal = () => {
    setOpenTranslationModal(false);
    setTranslationText('');
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minWidth: 500,
      maxWidth: '100%', // Ensures responsiveness
      margin: 'auto',
      p: 5,
    }}>
      <Typography variant="h6" gutterBottom component="div" sx={{ textAlign: 'center' }}>
        {isEdit ? 'Edit Section' : 'Add New Section'}
      </Typography>
      <TextField sx={{ mt: 2 }}
        label="Section ID"
        name="id"
        required
        fullWidth
        value={currentSection.id}
        onChange={e => handleSectionIDChange(e.target.value)}
        variant={isEdit ? 'filled' : 'outlined'}
        InputProps={{
          readOnly: isEdit,
        }}
      />
      <Box key="section-title" sx={{ mt: 2 }}>
        <Stack direction="row">
          <Typography variant="subtitle1" gutterBottom>
            Section Title
          </Typography>
          <Button startIcon={<TranslateIcon />} onClick={() => handleOpenTranslationModal('title')} variant="text" sx={{ ml: 2 }}>
            Add Translation
          </Button>
        </Stack>
        <Box sx={{ ml: 3 }}>
          {Object.keys(currentSection.title).length > 0 ? (
            Object.entries(currentSection.title).map(([locale, text], index) => (
              <Box key={locale} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <TextField
                  variant="outlined"
                  size="small"
                  label={locale}
                  value={text}
                  onChange={(e) => {
                    const newTranslations = {
                      ...currentSection.title,
                      [locale]: e.target.value
                    };
                    setCurrentSection({ ...currentSection, title: newTranslations });
                  }}
                  sx={{ flexGrow: 1, mr: 1 }}
                />
                <IconButton onClick={() => {
                  const { [locale]: deleted, ...remainingTranslations } = currentSection.title;
                  setCurrentSection({ ...currentSection, title: remainingTranslations });
                }} color="error">
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              No translations added.
            </Typography>
          )}
        </Box>
      </Box>

      <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
        Section Questions
      </Typography>
      <Button startIcon={<AddIcon />} onClick={handleOpenModal} variant="outlined" sx={{ mb: 2 }}>Add Question</Button>

      <Box sx={{ mb: 2 }}>
        {currentSection.questionIDs.map((qID) => (
          <Chip
            key={qID}
            label={qID}
            onDelete={() => handleRemoveQuestion(qID)}
            color="primary"
            sx={{ mr: 1, mb: 1 }}
          />
        ))}
      </Box>

      <Box sx={{ mb: 2 }}>
        <FormControl component="fieldset">
          <Typography variant="subtitle1">After this section</Typography>
          <Select
            value={currentSection.afterSection}
            onChange={(e) => setCurrentSection({ ...currentSection, afterSection: e.target.value })}
            displayEmpty
          >
            <MenuItem value="direct">Go to specified section</MenuItem>
            <MenuItem value="conditional">Conditional Based on Question</MenuItem>
            <MenuItem value="submit">Submit</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {currentSection.afterSection === AFTERSECTION_DIRECT && (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Next Section</InputLabel>
          <Select
            label='Next Section'
            value={currentSection.nextSectionDirect}
            onChange={(e) => setCurrentSection({ ...currentSection, nextSectionDirect: e.target.value })}
          >
            {sections && sections.map((section) => (
              eligibleNextSectionIDs.includes(section.id) ?
                <MenuItem key={section.id} value={section.id}>{section.id}</MenuItem> :
                <MenuItem disabled key={section.id} value={section.id}>{`${section.id} (circular dependency)`}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {currentSection.afterSection === AFTERSECTION_CONDITIONAL && (
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Key Question for Conditional Navigation</InputLabel>
          <Select
            label="Key Question for Conditional Navigation"
            value={keyQuestion ? keyQuestion : ''}
            onChange={(e) => handleKeyQuestionChange(e.target.value)}
          >
            {questions.filter(q => q.type === 'single-select' && currentSection.questionIDs.includes(q.id)).map((q) => (
              <MenuItem key={q.id} value={q}>{q.id}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {currentSection.afterSection === AFTERSECTION_CONDITIONAL && keyQuestion && (
        keyQuestion.options.map((option) => (
          <Box key={option.value} sx={{ ml: 3, mt: 2, display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ mr: 2 }}>{option.value}:</Typography> {/* Assuming option texts are localized */}
            <Select
              value={currentSection.nextSectionsConditional.nextSectionByOption[option.value] || ''}
              onChange={(e) => {
                const nextSectionsByOption = {
                  ...currentSection.nextSectionsConditional.nextSectionByOption,
                  [option.value]: e.target.value,
                };
                setCurrentSection({
                  ...currentSection,
                  nextSectionsConditional: {
                    ...currentSection.nextSectionsConditional,
                    nextSectionByOption: nextSectionsByOption,
                  },
                });
              }}
              sx={{ flexGrow: 1 }}
            >
              {sections && sections.map((section) => (
                eligibleNextSectionIDs.includes(section.id) ?
                  <MenuItem key={section.id} value={section.id}>{section.id}</MenuItem> :
                  <MenuItem disabled key={section.id} value={section.id}>{`${section.id} (circular dependency)`}</MenuItem>
              ))}
            </Select>
          </Box>
        ))
      )}

      {currentSection.afterSection !== AFTERSECTION_SUBMIT && (
        <FormControlLabel
          control={
            <Checkbox
              checked={currentSection.needGeneration === true}
              onChange={(e) => setCurrentSection({ ...currentSection, needGeneration: e.target.checked })}
            />}
          label="Need Generation"
        />
      )}

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button fullWidth onClick={handleSave} variant="contained" color="primary">
          {isEdit? 'Update Section':'Add Section'}
        </Button>
        {isEdit && (
          <Button fullWidth onClick={handleDelete} variant="contained" color="error" sx={{ ml: 2 }}>
            Delete Section
          </Button>
        )}
      </Box>

      {/* Modal for adding questions */}
      <Dialog open={openQuestionModal} onClose={handleCloseModal}>
        <DialogTitle>Add Question</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Autocomplete
            options={questions}
            getOptionLabel={(option) => option.id}
            onChange={(event, value) => setSelectedQuestion(value)}
            renderInput={(params) => <TextField {...params} label="Select Question" variant="outlined" />}
            sx={{ mb: 2, width: 500 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddQuestion} color="primary">Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openTranslationModal} onClose={handleCloseTranslationModal}>
        <DialogTitle>Add Translation</DialogTitle>
        <DialogContent>
          <TextField
            select
            fullWidth
            label="Locale"
            value={selectedLocale}
            onChange={(e) => setSelectedLocale(e.target.value)}
            margin="normal"
          >
            {AVAILABLE_LOCALES.map((locale) => (
              <MenuItem key={locale} value={locale}>
                {locale}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="normal"
            fullWidth
            label="Translation"
            value={translationText}
            onChange={(e) => setTranslationText(e.target.value)}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTranslationModal}>Cancel</Button>
          <Button onClick={handleSaveTranslation}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default SectionBuilder;
