import * as React from 'react';
import {
  DIALOG_TYPE_CONSUMPTION_CONFIRM, DIALOG_TYPE_EXPERT_REVIEW, DIALOG_TYPE_INFO,
  DIALOG_TYPE_PRAY, DIALOG_TYPE_RENAME_DOC, DIALOG_TYPE_SEND_EMAIL, DIALOG_TYPE_QUESTIONNAIRE_VIEWER, DIALOG_TYPE_GENERATION_VIEWER, DIALOG_TYPE_CONFIRM,
  DIALOG_TYPE_USER_DATA_VIEWER
} from '../constants/dialog';
import ConfirmDialog from '../components/dialogs/confirmDialog';
import RenameDocDialog from '../components/dialogs/renameDocDialog';
import SendEmailDialog from '../components/dialogs/sendEmailDialog';
import ExpertReviewDialog from '../components/dialogs/expertReviewDialog';
import InfoDialog from '../components/dialogs/infoDialog';
import { Alert, Snackbar } from '@mui/material';
import PrayDialog from '../components/dialogs/prayDialog';
import QuestionnaireViewerDialog from '../components/dialogs/questionnaireViewerDialog';
import GenerationViewerDialog from '../components/dialogs/generationViewerDialog';
import authFetch from '../utils/auth';
import UserDataViewerDialog from '../components/dialogs/userDataViewerDialog';
import { useAuth } from './authProvider';

const DialogContext = React.createContext();

export const useDialog = () => React.useContext(DialogContext);

export const DialogProvider = ({ children }) => {
  const { refreshAccountDisplay } = useAuth();

  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState(null);
  const [content, setContent] = React.useState(null);
  const [callback, setCallback] = React.useState(null);
  const [resultMessage, setResultMessage] = React.useState('');
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const showSuccessMessage = (message) => {
    setResultMessage(message);
    setOpenSnackbar(true);
  }

  const showErrorMessage = (message) => {
    setResultMessage(message);
    setOpenErrorSnackbar(true);
  }

  const openDialog = (type, content = null, callback = null) => {
    setOpen(true);
    setType(type);
    setContent(content);
    setCallback(() => callback);
  }

  const closeDialog = () => {
    setOpen(false);
  }

  const closeDialogPray = (prayDone) => {
    setOpen(false);
    if (prayDone === true) {
      authFetch('/misc/meditate')
        .then(data => {
          refreshAccountDisplay();
          showSuccessMessage(data.message);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }

  const handleConfirm = (props) => {
    if (callback) {
      if (props) {
        callback(props);
      } else {
        callback();
      }
    }
    closeDialog();
  }

  const renderDialog = () => {
    switch (type) {
      case DIALOG_TYPE_CONFIRM:
      case DIALOG_TYPE_CONSUMPTION_CONFIRM:
        return <ConfirmDialog open={open} handleClose={closeDialog} handleConfirm={handleConfirm} content={content} />
      case DIALOG_TYPE_RENAME_DOC:
        return <RenameDocDialog open={open} handleClose={closeDialog} handleConfirm={handleConfirm} content={content} />
      case DIALOG_TYPE_SEND_EMAIL:
        return <SendEmailDialog open={open} handleClose={closeDialog} handleConfirm={handleConfirm} content={content} />
      case DIALOG_TYPE_EXPERT_REVIEW:
        return <ExpertReviewDialog open={open} handleClose={closeDialog} handleConfirm={handleConfirm} content={content} />
      case DIALOG_TYPE_INFO:
        return <InfoDialog open={open} handleClose={closeDialog} handleConfirm={handleConfirm} content={content} />
      case DIALOG_TYPE_PRAY:
        return <PrayDialog open={open} handleClose={closeDialogPray} content={content} />
      case DIALOG_TYPE_QUESTIONNAIRE_VIEWER:
        return <QuestionnaireViewerDialog open={open} handleClose={closeDialog} content={content} />
      case DIALOG_TYPE_GENERATION_VIEWER:
        return <GenerationViewerDialog open={open} handleClose={closeDialog} content={content} />
      case DIALOG_TYPE_USER_DATA_VIEWER:
        return <UserDataViewerDialog open={open} handleClose={closeDialog} content={content} />
      default:
        return null;
    }
  }

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog, showSuccessMessage, showErrorMessage, isLoading, setIsLoading }}>
      {children}
      {renderDialog()}
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          {resultMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={() => setOpenErrorSnackbar(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setOpenErrorSnackbar(false)} severity="error" sx={{ width: '100%' }}>
          {resultMessage}
        </Alert>
      </Snackbar>
    </DialogContext.Provider>
  );
}