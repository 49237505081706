import React from 'react';
import { Box, Button } from '@mui/material';
import authFetch from '../../utils/auth';
import { useDialog } from '../../context/dialogProvider';


function InternalManager() {
  const { showSuccessMessage } = useDialog();

  const promoteToProd = () => {
    authFetch('/dev/promote-to-prod')
      .then((data) => {
        showSuccessMessage('Succeeded!')
      })
      .catch((error) => console.error("There was an error!", error));
  }

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        onClick={promoteToProd}
        sx={{ mb: 2, ml: 3, mt: 3 }} // Margin bottom for spacing
      >
        Promote to Prod
      </Button>
    </Box>
  )
}

export default InternalManager;