import * as React from 'react';
import { useParams } from 'react-router-dom';
import authFetch from '../utils/auth';
import ArticleContent from '../components/article/articleContent';

export default function FlowPDF() {
  const { flowid, template } = useParams();
  const [content, setContent] = React.useState("");
  const [flowType, setFlowType] = React.useState(null);

  const fetchArticleDisplay = (flowid) => {
    authFetch(`/public/flow-data/${flowid}`)
      .then(data => {
        console.log(data)
        setContent(data.content);
        setFlowType(data.flowType);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  React.useEffect(() => {
    fetchArticleDisplay(flowid);
  }, [flowid]);

  return (
    <ArticleContent content={content} flowType={flowType} template={template} />
  );
}