import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import authFetch from '../../utils/auth';
import { Box } from '@mui/material';
import { getIcon } from '../../utils/assets';

const drawerWidth = '240px';

export default function MainDrawerLeft() {
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOptions, setSidebarOptions] = React.useState([]);
  const[selectedOption, setSelectedOption] = React.useState(location.state?.selectedOption ?? 'icon-home');

  React.useEffect(() => {
    authFetch('/user/sidebar-options')
      .then((data) => {
        setSidebarOptions(data.options);
      })
      .catch((error) => console.error("There was an error!", error));
  }, []);

  const handleDrawerButtonClick = (option) => {
    setSelectedOption(option.icon['name']);
    navigate(option.redirectUrl, { state: { selectedOption: option.icon['name'] } });
  };

  return (
    <Drawer
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          marginTop: '64px',
          bgcolor: 'bg1.main'
        },
      }}
      variant="persistent"
      anchor="left"
      open
    >
      <Box sx={{ mt: 2 }}>
        <List>
          {sidebarOptions.map((option, index) => (
            <ListItem
              key={index}
              disablePadding
              selected={location.pathname === option.redirectUrl}
            >
              <ListItemButton onClick={() => handleDrawerButtonClick(option)}>
                <ListItemIcon>
                  <img src={getIcon(option.icon['name'], option.icon['size'], selectedOption === option.icon['name'] ? 'primary' : 'default')} alt='' style={{ verticalAlign: 'middle', width: 24, height: 24, }} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle1' }} primary={option.displayText} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}
