import React from "react";
import BasicLayout from "../components/layout/basic";
import SampleDrawer from "../components/leftbar/sampleDrawer";
import SampleDisplay from "../components/sample/sampleDisplay";
import authFetch from "../utils/auth";
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function Sample() {
    const location = useLocation();
    const [sampleList, setSampleList] = React.useState([]);
    const [currSampleId, setCurrSampleId] = React.useState(null);
    React.useEffect(() => {
        authFetch('/public/get-sample-display', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(data => {
                setSampleList(data.sampleDisplay.sampleList);
                if (location.state && location.state.sampleId) {
                    setCurrSampleId(location.state.sampleId);
                } else {
                    setCurrSampleId(data.sampleDisplay.sampleList[0].id);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [location.state]);

    return (
        <>
            <Helmet>
                <meta name="description" content='查看服务样例.' />
            </Helmet>
            <BasicLayout mainContent={<SampleDisplay sample={sampleList.find(sample => sample.id === currSampleId)} />}
                leftDrawer={<SampleDrawer currSampleId={currSampleId} sampleList={sampleList} setCurrSampleId={setCurrSampleId} />}
                isLeftDrawerFixed={false} />
        </>
    );

}