import React from 'react';
import FullLayout from '../components/layout/full';
import LandingDisplay from '../components/landing/landingDisplay';
import { Helmet } from 'react-helmet';

export default function Landing() {

  return (
    <>
      <Helmet>
        <meta name="description" content='佛系申 FoxiApply - 专属于你的留学申请平台.' />
      </Helmet>
      <FullLayout mainContent={
        <LandingDisplay />
      } />
    </>
  );
}