import React from 'react';
import { Typography, Box, Divider, Stack } from '@mui/material';

function displayExperience(item, index) {
    return (
        <Box key={index} mb={2}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{item.title} / {item.location} / {item.timePeriod}</Typography>
            <Typography variant="body2">{item.subtitle}</Typography>
            {item.additionalDetails.map(((line, lineIdx) => (
                <Stack direction="row" alignItems="flex-start" spacing={1} key={lineIdx}>
                    <ul style={{ paddingLeft: '20px' }}>
                        <li><Typography variant="body2">{line}</Typography></li>
                    </ul>
                </Stack>
            )))}
        </Box>
    );
}

function CVTemplateV2({ resumeData }) {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h4" sx={{ textAlign: 'center', fontWeight: 'bold' }}>{resumeData.name}</Typography>
            <Divider />
            <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>{resumeData.basicInfo}</Typography>
            {resumeData.education && (
                <>
                    <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', mt: 2, fontWeight: 'bold' }}>Education</Typography>
                    {resumeData.education.map((edu, index) => (
                        <Box key={index} mb={2}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{edu.institution} / {edu.location} / {edu.timePeriod}</Typography>
                            <Typography variant="body2">{edu.degree}</Typography>
                            {edu.additionalDetails.map(((line, lineIdx) => (
                                <Stack direction="row" alignItems="flex-start" spacing={1} key={lineIdx}>
                                    <ul style={{ paddingLeft: '20px' }}>
                                        <li><Typography variant="body2">{line}</Typography></li>
                                    </ul>
                                </Stack>
                            )))}
                        </Box>
                    ))}</>
            )}
            {resumeData.professionalExperiences && resumeData.professionalExperiences.length > 0 && (
                <>
                    <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', mt: 2, fontWeight: 'bold' }}>Professional Experiences</Typography>
                    {resumeData.professionalExperiences.map((exp, index) => displayExperience(exp, index))}
                </>
            )}
            {resumeData.researchExperiences && resumeData.researchExperiences.length > 0 && (
                <>
                    <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', mt: 2, fontWeight: 'bold' }}>Research Experiences</Typography>
                    {resumeData.researchExperiences.map((exp, index) => displayExperience(exp, index))}
                </>
            )}
            {resumeData.extracurricularActivities && resumeData.extracurricularActivities.length > 0 && (
                <>
                    <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', mt: 2, fontWeight: 'bold' }}>Extracurricular Activities</Typography>
                    {resumeData.extracurricularActivities.map((exp, index) => displayExperience(exp, index))}
                </>
            )}
            {resumeData.skills && (
                <>
                    <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', mt: 2, fontWeight: 'bold' }}>Skills & Interests</Typography>
                    {resumeData.skills.technical && (
                        <Stack direction={'row'} gap={'5px'}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Technical:</Typography>
                            <Typography variant="body1" >{resumeData.skills.technical}</Typography>
                        </Stack>
                    )}
                    {resumeData.skills.languages && (
                        <Stack direction={'row'} gap={'5px'}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Languages:</Typography>
                            <Typography variant="body1" >{resumeData.skills.languages}</Typography>
                        </Stack>
                    )}
                    {resumeData.skills.laboratory && (
                        <Stack direction={'row'} gap={'5px'}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Laboratory:</Typography>
                            <Typography variant="body1" >{resumeData.skills.laboratory}</Typography>
                        </Stack>
                    )}
                    {resumeData.skills.interests && (
                        <Stack direction={'row'} gap={'5px'}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Interests:</Typography>
                            <Typography variant="body1" >{resumeData.skills.interests}</Typography>
                        </Stack>
                    )}
                </>
            )}
            {resumeData.publications && resumeData.publications.length > 0 && (
                <>
                    <Typography variant="h6" gutterBottom sx={{ textAlign: 'left', mt: 2, fontWeight: 'bold' }}>Publications</Typography>
                    {resumeData.publications.map(((line, lineIdx) => (
                        <Stack direction="row" alignItems="flex-start" spacing={1} key={lineIdx}>
                            <ul style={{ paddingLeft: '20px' }}>
                                <li><Typography variant="body2">{line}</Typography></li>
                            </ul>
                        </Stack>
                    )))}
                </>
            )}
        </Box>
    );
}

export default CVTemplateV2;