import * as React from 'react';
import Box from '@mui/material/Box';

export default function BasicLayout({ mainContent, leftDrawer, isLeftDrawerFixed = true }) {
  return (
    <Box sx={{ display: 'flex', marginTop: '64px' }}>
      {leftDrawer && leftDrawer}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: (leftDrawer && isLeftDrawerFixed) ? '240px' : 0,
          minHeight: `calc(100vh - 64px)`,
        }}
      >
        {mainContent}
      </Box>
    </Box>
  );
}