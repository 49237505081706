import React, { useEffect } from 'react';

function Terms() {
  useEffect(() => {
    // Function to load the Termly script
    const loadTermlyScript = () => {
      const scriptId = 'termly-jssdk';
      if (document.getElementById(scriptId)) return;

      const script = document.createElement('script');
      script.id = scriptId;
      script.src = 'https://app.termly.io/embed-policy.min.js';
      document.body.appendChild(script);
    };

    // Call the function to load the script
    loadTermlyScript();
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <div name="termly-embed" data-id="1678a26d-bbd2-4ba6-9a17-3ef9373ae8b9"></div>
  );
}

export default Terms;
