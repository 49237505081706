import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function SendEmailDialog({ open, handleClose, handleConfirm, content }) {
  const [inboxAddress, setInboxAddress] = React.useState(content.userEmail);
  const handleEmailChange = (event) => {
    setInboxAddress(event.target.value);
    console.log(inboxAddress);
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          const email = formJson.inboxAddress;
          handleConfirm(email);
          handleClose();
        },
      }}
      sx={{
        '& .MuiDialog-paper': {
          width: '640px',
          maxWidth: 'none',
          padding: '5px 0px 10px 0px',
        }
      }}
    >
      <DialogTitle>{content.title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          required
          margin="dense"
          id="inbox-address-send-email"
          name="inboxAddress"
          type='email'
          fullWidth
          variant="standard"
          placeholder={content.userEmail}
          onChange={() => handleEmailChange}
        />
        <DialogContentText sx={{
          fontSize: '0.8em',
        }}>
          {content.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{ boxShadow: 'none' }} onClick={() => handleClose()}>{content.cancelBtn}</Button>
        <Button sx={{ boxShadow: 'none' }} type='submit' variant='contained'>{content.confirmBtn}</Button>
      </DialogActions>
    </Dialog>
  );
}