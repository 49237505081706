import React, { useEffect, useState } from 'react';
import { Card, Grid, TextField, Button, CardContent, Typography, CardActionArea, Paper, Container, Stack, Dialog, DialogTitle, IconButton, DialogContent, Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';
import authFetch from '../../utils/auth';
import { KeyValueList } from '../dataDisplay/keyValueList';
import { useLydia } from '../../context/lydiaProvider';
import { countWordsAndChineseCharacters } from '../../utils/questionnaire/wordcount';
import { FLOWTYPE_ALL, FLOWTYPE_LST, FLOW_STATUS_LST, STATUS_ALL, STATUS_COMPLETED } from '../../constants/flow';
import { useDialog } from '../../context/dialogProvider';
import { DIALOG_TYPE_QUESTIONNAIRE_VIEWER, DIALOG_TYPE_USER_DATA_VIEWER } from '../../constants/dialog';
import { GenerationViewer } from './generationViewer';
import CloseIcon from '@mui/icons-material/Close';
import ArticleContent from '../article/articleContent';
import { CV_TEMPLATE_TYPE_1_BTN } from '../../constants/article';
import UserTierChip from '../chips/userTierChip';

const defaultSearchParams = {
  userEmail: '',
  flowID: '',
  startTime: '',
  endTime: '',
  flowType: FLOWTYPE_ALL,
  flowStatus: STATUS_ALL,
};

function Investigation() {
  const { generations, prompts, env } = useLydia();
  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const [flows, setFlows] = useState([]);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [selectedFlowIdx, setSelectedFlowIdx] = useState(-1);
  const [selectedFlowGeneration, setSelectedFlowGeneration] = useState(null);
  const [openGenerationViewer, setOpenGenerationViewer] = useState(false);
  const [openArticleViewer, setOpenArticleViewer] = useState(false);

  useEffect(() => {
    const dt = new Date();
    const startDate = new Date(dt.setDate(dt.getDate() - 2));
    const endDate = new Date(dt.setDate(dt.getDate() + 9));
    const searchParamsRecent = {
      userEmail: '',
      flowID: '',
      startTime: startDate.toDateString(),
      endTime: endDate.toDateString(),
      flowType: FLOWTYPE_ALL,
      flowStatus: STATUS_ALL,
      env: env,
    };
    fetchFlows(env, searchParamsRecent);
  }, [env]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchParams(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fetchFlows = async (env, searchParams) => {
    authFetch('/lydia/list-flows', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        searchParams: {
          ...searchParams,
          env: env,
        }
      })
    })
      .then((data) => {
        setFlows(data.flows);
        setSelectedFlow(null);
        setSelectedFlowIdx(-1);
        setSelectedFlowGeneration(null);
      })
      .catch((error) => console.error("There was an error!", error));
  };

  const displayFlowDetails = (flow, fIdx) => {
    setSelectedFlowIdx(fIdx);
    authFetch('/lydia/get-flow', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ env: env, flowid: flow.id })
    })
      .then((data) => {
        setSelectedFlow(data.flow);
        if (data.flow.generationID) {
          const generation = generations.find(g => g.id === data.flow.generationID);
          setSelectedFlowGeneration(generation);
        }
      })
      .catch((error) => console.error("There was an error!", error));
  };

  const { openDialog } = useDialog();

  const handleOpenQuestionnaireViewer = (flowId) => {
    openDialog(DIALOG_TYPE_QUESTIONNAIRE_VIEWER, {
      flowId: flowId,
      env: env,
    });
  };

  const handleCloseGenerationViewer = () => {
    setOpenGenerationViewer(false);
  };

  const handleOpenGenerationViewer = () => {
    setOpenGenerationViewer(true);
  };

  const handleCloseArticleViewer = () => {
    setOpenArticleViewer(false);
  };

  const handleOpenArticleViewer = () => {
    setOpenArticleViewer(true);
  };

  const handleOpenUserDataViewer = () => {
    openDialog(DIALOG_TYPE_USER_DATA_VIEWER, selectedFlow.userData);
  };

  return (
    <Container sx={{ mt: 3 }}>
      <Paper elevation={3} sx={{ p: 2, marginBottom: 4 }}>
        <Typography variant="h5" component="h2" marginBottom={2}>Search Criteria</Typography>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              label="User Email"
              name="userEmail"
              value={searchParams.userEmail}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              label="Flow ID"
              name="flowID"
              value={searchParams.flowID}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              label="Start Time"
              name="startTime"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={searchParams.startTime}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              label="End Time"
              name="endTime"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={searchParams.endTime}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="flow-type-select-label">Flow Type</InputLabel>
              <Select
                labelId="flow-type-select-label"
                id="flow-type-select"
                name="flowType"
                value={searchParams.flowType}
                onChange={handleInputChange}
                label="Flow Type"  // This label prop helps MUI align the label correctly
              >
                {FLOWTYPE_LST.map((flowType) => (
                  <MenuItem key={flowType} value={flowType}>
                    {flowType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="flow-type-select-label">Flow Status</InputLabel>
              <Select
                labelId="flow-status-select-label"
                id="flow-status-select"
                name="flowStatus"
                value={searchParams.flowStatus}
                label="Flow Status"
                onChange={handleInputChange}
              >
                {FLOW_STATUS_LST.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => fetchFlows(env, searchParams)}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          {flows.map((flow, fIdx) => (
            <Card
              key={flow.id}
              sx={{
                marginBottom: 2,
                bgcolor: selectedFlowIdx === fIdx ? 'primary.main' : 'background.paper',
                color: selectedFlowIdx === fIdx ? 'white' : 'black',
              }}
              onClick={() => displayFlowDetails(flow, fIdx)}
              elevation={4}>
              <CardActionArea>
                <CardContent>
                  {/* {flow.payingTier === 3 && <Tooltip title="Subscriped User" arrow><Looks3Icon sx={{ position: 'absolute', top: 0, right: 0, color: 'yellow' }} /></Tooltip>}
                  {flow.payingTier === 2 && <Tooltip title="Paying User" arrow><LooksTwoIcon sx={{ position: 'absolute', top: 0, right: 0, color: 'orange' }} /></Tooltip>}
                  {flow.payingTier === 1 && <Tooltip title="Free User" arrow><LooksOneIcon sx={{ position: 'absolute', top: 0, right: 0, color: 'gray' }} /></Tooltip>} */}
                  <Box sx={{ position: 'absolute', top: 2, right: 1 }}>
                    <UserTierChip tier={flow.payingTier} />
                  </Box>
                  <Typography gutterBottom variant="body1" component="div">
                    Flow ID: {flow.id}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    CreatedAt: {new Date(flow.time).toLocaleString()}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Type: {flow.type} | Status: {flow.status}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Grid>
        {selectedFlow && <Grid item xs={12} md={7}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Flow Details</Typography>
            <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
              {selectedFlow.progress && <Button variant="contained" color="primary" onClick={() => handleOpenQuestionnaireViewer(selectedFlow?.id)}>
                Questionnaire
              </Button>}
              {selectedFlow.gptHistory && <Button variant="contained" color="primary" onClick={() => handleOpenGenerationViewer()}>
                Generation
              </Button>}
              {selectedFlow.status === STATUS_COMPLETED && <Button variant="contained" color="primary" onClick={() => handleOpenArticleViewer()}>
                Article
              </Button>}
              {selectedFlow.userData && <Button variant="contained" color="primary" onClick={() => handleOpenUserDataViewer()}>
                User Data
              </Button>}
            </Stack>
            {selectedFlow ? (
              <>
                <Typography variant="body1"><strong>Flow ID:</strong> {selectedFlow.id}</Typography>
                <Typography variant="body1"><strong>User ID:</strong> {selectedFlow.uid}</Typography>
                <Typography variant="body1"><strong>Type:</strong> {selectedFlow.flowType}</Typography>
                <Typography variant="body1"><strong>Status:</strong> {selectedFlow.status}</Typography>
                {selectedFlow.status === STATUS_COMPLETED && (
                  <>
                    <Typography variant="body1" sx={{ mt: 1 }}><strong>Final Article</strong> | wordcount:{countWordsAndChineseCharacters(selectedFlow.content)}</Typography>
                    <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>{selectedFlow.content}</Typography>
                  </>
                )}
                <Typography variant="body1" sx={{ mt: 1 }}><strong>Info:</strong></Typography>
                <KeyValueList data={selectedFlow.infoList} />
              </>
            ) : <Typography variant="body1">Select a flow to see detailed information.</Typography>}
          </Paper>
        </Grid>}
      </Grid>
      <Dialog open={openGenerationViewer} fullWidth maxWidth='xl'>
        <DialogTitle>Generation Viewer</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseGenerationViewer}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <GenerationViewer generation={selectedFlowGeneration} flowData={selectedFlow} prompts={prompts} />
        </DialogContent>
      </Dialog>
      <Dialog open={openArticleViewer} fullWidth maxWidth='xl'>
        <DialogTitle>Article Viewer</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseArticleViewer}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Paper elevation={0} sx={{
            bgcolor: 'bg_article.main',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignSelf: 'center',
            padding: '15mm',
            overflow: 'auto',
            border: '4px solid var(--bg-dark, #F0F0EB)',
            width: '794px', // Width of A4 paper in pixels
            minHeight: '1123px', // Height of A4 paper in pixels
          }}>
            <ArticleContent content={selectedFlow?.content} flowType={selectedFlow?.flowType} template={CV_TEMPLATE_TYPE_1_BTN} />
          </Paper>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default Investigation;