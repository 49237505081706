import { Box, Card, CardContent, Typography } from "@mui/material";
import PriceWithCoinChip from "../chips/priceWithCoinChip";
import { useHome } from "../../context/homeProvider";
import ServiceCardAvatar from "./serviceCardAvatar";

export default function ServiceCard({ service }) {
    const { handleServiceCardClick } = useHome();

    return (
        <Card sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
            padding: '0px 0px 0px 20px',
            flexDirection: 'row',
            height: '150px',
            width: '490px',
            boxShadow: 'none',
            borderRadius: '10px',
            backgroundColor: 'bg_dark.main',
            opacity: 0.9,
            flexGrowth: 1,
            '&:hover': {
                opacity: 1,
                '.MuiCardMedia-root': {
                    transform: 'scale(1.1)',
                    transition: 'transform 0.5s',
                },
                transform: 'translateY(-10px)',
                transition: 'transform 0.5s',
            },
            cursor: service.disabled ? 'not-allowed' : 'pointer',
        }} onClick={() => {
            if (!service.disabled) {
                handleServiceCardClick(service);
            }
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            }}>
                <ServiceCardAvatar service={service} />
            </Box>
            <CardContent sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '5px',
                flexGrow: 1,
                height: '100%',
                padding: '19px 20px 10px 0px',
            }}>
                <Typography gutterBottom variant="card_title" sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1, // Limit text to 1 line
                    fontWeight: 'bold',
                }}>
                    {service.displayText}
                </Typography>
                <Typography variant="card_content" color="text.secondary" sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                }}>
                    {service.description}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'end', width: '95%', flexGrow: 1 }}>
                    {service.points && service.points > 0 && (
                        <PriceWithCoinChip price={service.points} />
                    )}
                </Box>
            </CardContent>
        </Card>
    );
}