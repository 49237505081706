import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import authFetch from '../utils/auth';
import Loading from '../components/loading/loading';
import { useParams } from 'react-router-dom';

export default function PaymentSuccess({redirectURL}) {
    const { flowid } = useParams();
    const [content, setContent] = React.useState(null);

    const fetchSuccessInfo = () => {
        authFetch('/payment/success')
        .then(data => {
            setContent(data);
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    React.useEffect(() => {
        fetchSuccessInfo();
    }, []);

    return (
        <Container maxWidth="sm">
            {content == null ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        <Loading />
                    </Typography>
                </Box>
            ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100vh',
                            gap: 2,
                        }}
                    >
                        <CheckCircleOutlineIcon sx={{ fontSize: 60, color: 'green' }} />
                        <Typography variant="h4" component="h1" gutterBottom>
                            {content.title}
                        </Typography>
                        <Typography variant="body1" component="p" gutterBottom>
                            {content.description}
                        </Typography>
                        {content.action.map((action, index) => (
                            <Button key={index} variant="contained" color="primary" sx={{ boxShadow: 'none' }} href={redirectURL === ''? `/f/${flowid}` : redirectURL}>
                                {action.displayText}
                            </Button>
                        ))}
                    </Box>
            )}
        </Container>
    );
}
