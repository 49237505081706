import * as React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { STATUS_COMPLETED, STATUS_GENERATING, STATUS_INPROGRESS } from '../constants/flow';
import Questionnaire from '../components/questionnaire/questionnaire';
import authFetch from '../utils/auth';
import Article from '../containers/article';
import { ArticleProvider } from '../context/articleProvider';
import Generating from '../components/generating/generating';
import BasicLayout from '../components/layout/basic';
import MainDrawerLeft from '../components/leftbar/mainDrawer';
import FlowHeader from '../components/flowHeader/flowHeader';
import { actionHandler, createAction, fetchDialogInfo } from '../utils/action';
import { useDialog } from '../context/dialogProvider';
import { DIALOG_TYPE_RENAME_DOC } from '../constants/dialog';
import { BackDropLoading } from '../components/loading/backDropLoading';

function renderFlowPage(flowid, flowType, flowStatus) {
  switch (flowStatus) {
    case STATUS_INPROGRESS:
      return (
        <Box className='content' sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}>
          <Questionnaire flowid={flowid} />
        </Box>
      );
    case STATUS_GENERATING:
      return <Generating flowType={flowType}/>;
    case STATUS_COMPLETED:
      return (
        <ArticleProvider>
          <Article flowId={flowid} />
        </ArticleProvider>
      );
    default:
      return null;
  }

}

export default function Flow() {
  const { flowid } = useParams();
  const { openDialog } = useDialog();
  const [flowInfo, setFlowInfo] = React.useState({});

  const handleRename = async () => {
    let request = {
        dialogType: DIALOG_TYPE_RENAME_DOC,
    }
    let attachedData = {
        flowId: flowid,
        docName: flowInfo.flowName,
    }
    const optionId = 'rename-doc-button';
    fetchDialogInfo(request, async (info) => {
        info.docName = attachedData.docName;
        openDialog(DIALOG_TYPE_RENAME_DOC, info, async (newDocName) => {
            attachedData.newDocName = newDocName;
            const action = createAction(optionId, attachedData);
            const response = await actionHandler(action);
            if (response) {
                fetchFlowInfo(attachedData.flowId);
            }
        })
    });
  }
  
  React.useEffect(() => {
    fetchFlowInfo(flowid);
  }, [flowid]);

  const fetchFlowInfo = (flowid) => {
    authFetch('/flow/info', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ flowid: flowid })
    })
      .then((data) => {
        setFlowInfo(data);
      })
      .catch((error) => console.error("There was an error!", error));
  }

  return (flowInfo && flowInfo.flowType) ? (
    <BasicLayout mainContent={
      <>
        <FlowHeader flowInfo={flowInfo} handleRename={handleRename} />
        {renderFlowPage(flowid, flowInfo.flowType, flowInfo.status)}
      </>
    } leftDrawer={<MainDrawerLeft />} />
  ) : <BackDropLoading />;
}