import * as React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { getIcon } from '../../utils/assets';

export default function PricingCard({ card, handleCardButtonClick, index }) {
    
    return (
        <Card sx={{
            position: 'relative',
            display: 'flex',
            padding: '35px 25px 20px 25px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '5px',
            flex: '1 0 auto',
            alignSelf: 'stretch',
            borderRadius: '24px',
            border: '1px solid',
            borderColor: 'var(--bg-dark, #F0F0EB)',
            bgcolor: 'bg_dark.main',
            // minHeight: '780px',
            height: '100%',
            width: '100%',
            userSelect: 'none',
            boxShadow: 15,
            '&:hover': {
                boxShadow: 5,
            },
        }}>
           {card.recommended && (
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bgcolor: 'primary.main',
                    borderColor: 'primary.contrastText',
                    color: 'common.white',
                    px: '20px',
                    py: '5px',
                    borderRadius: '0 20px 0 20px',
                }}>
                    <Typography variant="h7" color={'primary.contrastText'} sx={{ fontWeight: 1000 }}>
                        {card.recommendedText}
                    </Typography>
                </Box>
           )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '10px',
                    alignSelf: 'stretch',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant='pricing_card_title'>
                    {card.title}
                </Typography>

                <Box sx={{
                    display: 'flex',
                    gap: '5px',
                    alignSelf: 'stretch',
                    alignItems: 'flex-end',
                }}>
                    <Typography variant={card.id === 'CustomizedService' ? 'subtitle2' : 'pricing_card_subtitle'} sx={{ color: card.id === 'CustomizedService' ? 'subtitle.secondary' : 'primary.main' }}>{card.price.featurePrice}</Typography>
                    <Typography variant="h7" sx={{ color: 'subtitle.secondary' }}>{card.price.priceToPoints ? `${card.price.priceToPoints}` : ''}</Typography>
                </Box>
                <Box sx={{
                    mt: '10px',
                }}>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            alignSelf: 'stretch',
                            color: 'subtitle.secondary',
                        }}
                    >
                        {card.description}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '10px',
                    alignSelf: 'stretch',
                    justifyContent: 'space-between',
                    flexGrow: 1, // this is the key to make the card grow to fill the space
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '15px',
                        alignSelf: 'stretch',
                        justifyContent: 'flex-end',
                        flexGrow: 1, // this is the key to make the card grow to fill the space
                        width: 'wrap',
                    }}
                >
                    <Box sx={{
                        mt: '10px',
                        mb: '10px',
                        width: '100%',
                        color: 'subtitle.secondary',
                    }}>
                        <Divider />
                    </Box>
                    <Typography
                        variant="subtitle1"
                        color={'subtitle.secondary'}
                    >
                        {card.supportedFeaturesTitle}
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '100%',
                        flexWrap: 'wrap',
                        gap: '10px',
                    }}>
                    {Object.keys(card.features).map((feature, index) => (
                        <Box key={index} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                        }}>
                            {card.features[feature].enabled ? <img src={getIcon('icon-check')} alt={card.features[feature].displayText} /> : <img src={getIcon('icon-x')} alt={card.features[feature].displayText} />}
                            <Typography variant="subtitle2" color={'subtitle.secondary'}>
                                {card.features[feature].displayText}
                            </Typography>
                        </Box>
                            
                    ))}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        gap: '10px',
                        alignSelf: 'stretch',
                        height: 'auto',
                    }}>
                        <Button
                            sx={{
                                display: 'flex',
                                padding: '14px 30px',
                                mt: '10px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignSelf: 'stretch',
                                borderRadius: '20px',
                                color: '#fff',
                                '&:hover': {
                                    background: '#333',
                                },
                                width: 'auto',
                                fontSize: '16px',
                                background: index === 1 ? '#D97757' : '#191919',
                            }}
                            onClick={() => handleCardButtonClick(card) }
                        >
                            {card.action.displayText}
                        </Button>
                        <Typography
                            variant='subtitle2'
                            sx={{
                                alignSelf: 'stretch',
                                textAlign: 'center',
                            }}
                        >
                            {card.price.priceUnit}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Card> 
        
    )
}