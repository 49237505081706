import React, { useState } from 'react';
import { Typography, Box, Collapse } from '@mui/material';
import QuestionBasic from './questionBasic';
import HelpIcon from '@mui/icons-material/Help';

function QuestionGroup({ questionDisplay, infoList, onAnswerChange, readOnly, validationErrors }) {
  const [hintOpen, setHintOpen] = useState(false);

  return (
    <Box sx={{
      mt: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      alignSelf: 'stretch',
    }}>
      <Box sx={{
        display: 'flex',
        gap: '8px',
        alignSelf: 'stretch',
      }}>
        <Box sx={{
          display: 'flex',
          padding: '10px 15px',
          alignItems: 'center',
          gap: '10px',
          borderRadius: '20px',
          backgroundColor: 'bg_skin.main',
        }}>
          <Typography variant="body1" sx={{ fontWeight: 'bold', lineHeight: '20px' }} style={{ display: "inline-block", whiteSpace: "pre-line" }}>
            {questionDisplay.isMandatory && <span style={{ color: 'red' }}>*</span>}{questionDisplay.instructionDisplay}
          </Typography>
        </Box>
        {!readOnly && questionDisplay.hasHint && (
          <HelpIcon
            onClick={() => setHintOpen(!hintOpen)}
            aria-expanded={hintOpen}
            variant="text"
            fontSize='small'
            color="primary"
            sx={{
              alignSelf: 'center',
            }}
          />
        )}
      </Box>
      <Collapse in={hintOpen} sx={{ ml: 1 }}>
        <Typography variant="caption" color="text.secondary" style={{ display: "inline-block", whiteSpace: "pre-line" }} sx={{ mt: 1 }}>
          {questionDisplay.hintDisplay}
        </Typography>
      </Collapse>
      <Box sx={{
        display: 'flex',
        padding: '30px 30px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
        alignSelf: 'stretch',
        borderRadius: '20px',
        backgroundColor: 'bg_dark.main',
      }}>
        {questionDisplay.childQuestionDisplays.map(qd => (
          <QuestionBasic
            key={qd.id}
            questionDisplay={qd}
            answer={infoList[qd.id]}
            onAnswerChange={onAnswerChange}
            readOnly={readOnly}
            errorMessage={validationErrors[qd.id]}
          />
        ))}
      </Box>
    </Box>
  );
}

export default QuestionGroup;