import * as React from 'react';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const sizeLarge = 70;

export default function Loading({ size = sizeLarge }) {

    switch (size) {
        case 'small':
            return (
                <HourglassEmptyIcon sx={{
                    fontSize: size,
                    animation: 'spin 4s infinite',
                    '@keyframes spin': {
                        '0%, 100%': {
                            transform: 'rotate(0deg)',
                        },
                        '25%': {
                            transform: 'rotate(180deg)',
                        },
                        '50%': {
                            transform: 'rotate(180deg)',
                        },
                        '75%': {
                            transform: 'rotate(360deg)',
                        },
                    },
                }} color='primary' />
            )
        case 'medium':
            return (
                <HourglassEmptyIcon sx={{
                    fontSize: size,
                    animation: 'spin 4s infinite',
                    '@keyframes spin': {
                        '0%, 100%': {
                            transform: 'rotate(0deg)',
                        },
                        '25%': {
                            transform: 'rotate(180deg)',
                        },
                        '50%': {
                            transform: 'rotate(180deg)',
                        },
                        '75%': {
                            transform: 'rotate(360deg)',
                        },
                    },
                }} color='primary' />
            )
        case 'large':
            return (
                <HourglassEmptyIcon sx={{
                    fontSize: size,
                    animation: 'spin 4s infinite',
                    '@keyframes spin': {
                        '0%, 100%': {
                            transform: 'rotate(0deg)',
                        },
                        '25%': {
                            transform: 'rotate(180deg)',
                        },
                        '50%': {
                            transform: 'rotate(180deg)',
                        },
                        '75%': {
                            transform: 'rotate(360deg)',
                        },
                    },
                }} color='primary' />
            )
        default:
            return (
                <HourglassEmptyIcon sx={{
                    fontSize: size,
                    animation: 'spin 4s infinite',
                    '@keyframes spin': {
                        '0%, 100%': {
                            transform: 'rotate(0deg)',
                        },
                        '25%': {
                            transform: 'rotate(180deg)',
                        },
                        '50%': {
                            transform: 'rotate(180deg)',
                        },
                        '75%': {
                            transform: 'rotate(360deg)',
                        },
                    },
                }} color='primary' />
            )
    }
}