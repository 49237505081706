import * as React from 'react';
import Box from '@mui/material/Box';
import authFetch from '../../utils/auth';
import FlowCard from './flowCard';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FlowCardSkeleton from '../skeleton/mydoc/FlowCardSkeleton';
import FlowOptionSkeleton from '../skeleton/mydoc/flowOptionSkeleton';
import Typography from '@mui/material/Typography';

const FLOWTYPE_ALL = 'all';
const STATUS_ALL = 'all';

function filterFlowCards(flowCards, flowType, flowStatus) {
  let filteredCards = [];
  for (let card of flowCards) {
    if (flowType !== FLOWTYPE_ALL && card.type !== flowType) {
      continue;
    }
    if (flowStatus !== STATUS_ALL && card.status !== flowStatus) {
      continue;
    }
    filteredCards.push(card);
  }
  return filteredCards;
}

export default function MydocDisplay() {
  const [title, setTitle] = React.useState('');
  const [flowCards, setFlowCards] = React.useState(null);
  const [flowCardsFiltered, setFlowCardsFiltered] = React.useState(null);
  const [flowTypeOptions, setFlowTypeOptions] = React.useState(null);
  const [flowStatusOptions, setFlowStatusOptions] = React.useState(null);
  const [flowTypeSelected, setFlowTypeSelected] = React.useState('');
  const [flowStatusSelected, setFlowStatusSelected] = React.useState('');
  const [flowTypeFilterName, setFlowTypeFilterName] = React.useState('');
  const [flowStatusFilterName, setFlowStatusFilterName] = React.useState('');

  React.useEffect(() => {
    authFetch('/user/mydoc-display')
      .then((data) => {
        setTitle(data.title);
        setFlowCards(data.flowCards);
        setFlowCardsFiltered(data.flowCards);
        setFlowTypeSelected(data.typeFilter.defaultValue);
        setFlowStatusSelected(data.statusFilter.defaultValue);
        setFlowTypeOptions(data.typeFilter.options);
        setFlowStatusOptions(data.statusFilter.options);
        setFlowTypeFilterName(data.typeFilter.name);
        setFlowStatusFilterName(data.statusFilter.name);
      })
      .catch((error) => console.error("There was an error!", error));
  }, []);

  const handleTypeChange = (event) => {
    const newType = event.target.value;
    setFlowTypeSelected(newType);
    setFlowCardsFiltered(filterFlowCards(flowCards, newType, flowStatusSelected));
  };
  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    setFlowStatusSelected(newStatus);
    setFlowCardsFiltered(filterFlowCards(flowCards, flowTypeSelected, newStatus));
  };

  return (
    <Box className='content' sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '40px',
      flex: '1 0 0',
      alignSelf: 'stretch',
      ml: 5,
    }}>
      <Typography variant="h5" component="div" sx={{ mt: 5 }}>
        {title}
      </Typography>
      <Box sx={{ display: 'flex', paddingBottom: 2 }}>
        <FormControl sx={{ width: 245, mr: 3 }}>
          <InputLabel id="flow-type-select-label">{flowTypeFilterName}</InputLabel>
          {flowTypeOptions === null ? (
            <FlowOptionSkeleton />
          ) : (
            <Select
              labelId="flow-type-select-label"
              id="flow-type-select"
              value={flowTypeSelected}
              label="FlowType"
              onChange={handleTypeChange}
            >
              {flowTypeOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.displayText}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
        <FormControl sx={{ width: 245 }}>
          <InputLabel id="flow-status-select-label">{flowStatusFilterName}</InputLabel>
          {flowStatusOptions === null ? (
            <FlowOptionSkeleton />
          ) : (
            <Select
              labelId="flow-status-select-label"
              id="flow-status-select"
              value={flowStatusSelected}
              label="FlowStatus"
              onChange={handleStatusChange}
            >
              {flowStatusOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {option.displayText}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
        {flowCardsFiltered === null ? (
          <FlowCardSkeleton count={30} />
        ) : (
          flowCardsFiltered.map((flowCard) => (
            <FlowCard key={flowCard.id} flowCard={flowCard} />
          ))
        )}
      </Box>
    </Box>
  );
}