import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import Mydoc from './containers/mydoc';
import Landing from './containers/landing';
import About from './containers/about';
import theme from './theme';
import Dev from './containers/dev';
import Pricing from './containers/pricing';
import Flow from './containers/flow';
import { DialogProvider } from './context/dialogProvider';
import PaymentSuccess from './containers/paymentSuccess';
import { AuthProvider } from './context/authProvider';
import Lydia from './containers/lydia';
import Home from './containers/home';
import Sample from './containers/sample';
import Contact from './containers/contact';
import WithAppBarLayout from './components/layout/withAppBar';
import Privacy from './containers/privacy';
import Terms from './containers/terms';
import LydiaProvider from './context/lydiaProvider';
import InternalMgr from './containers/internalmgr';
import FlowPDF from './containers/flowPDF';
import MobileAccessHandler from './components/mobile/mobileAccessHandler';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <DialogProvider>
          <CssBaseline />
          <Router>
            <MobileAccessHandler />
            <WithAppBarLayout content={<Routes>
              <Route exact path="/" element={<Landing />} />
              <Route path='/home' element={<Home />} />
              <Route path="/mydoc" element={<Mydoc />} />
              <Route path="/dev" element={<Dev />} />
              <Route path="/lydia" element={(
                <LydiaProvider>
                  <Lydia />
                </LydiaProvider>)} />
              <Route path="/about" element={<About />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/f/:flowid" element={<Flow />} />
              <Route path="/fpdf63457427/:flowid/:template" element={<FlowPDF />} />
              <Route path="/payment-success/topup" element={<PaymentSuccess redirectURL={'/home'} />} />
              <Route path="/payment-success/:flowid" element={<PaymentSuccess redirectURL={''} />} />
              <Route path="/sample" element={<Sample />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/internalmanager" element={<InternalMgr />} />
            </Routes>} />
          </Router>
        </DialogProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
