import React from 'react';
import Button from '@mui/material/Button';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LOGIN_AND_TRY_BTN } from '../../constants/landing';
import { Typography } from '@mui/material';

export default function TryBtn({ landingDisplay, handleBtnClick }) {
    return (
        <Button variant='contained' onClick={() => handleBtnClick(LOGIN_AND_TRY_BTN)} sx={{
            width: { xs: '180px', md: '280px' },
            height: { xs: '60px', md: '85px' },
            padding: '0px 30px',
            borderRadius: '20px',
            boxShadow: '0 0 0 0',
            fontWeight: 400,
            fontSize: '18px',
            gap: '10px',
            '&:hover': {
                backgroundColor: 'primary.dark',
                color: 'primary.contrastText',
            }
        }}>
            <Typography variant='h6' color={'primary.contrastText'} sx={{ fontSize: { xs: 'h7.fontSize', md: 'h6.fontSize' } }}>{landingDisplay.tryBtnText}</Typography>
        </Button>
    )
}