import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import stringToColor from '../../utils/color';
import CircularProgress from '@mui/material/CircularProgress';

function stringAvatar(name) {
  let initials = name.split(' ').map((n) => n[0]).join('');
  if (initials.length > 1) {
    initials = initials.substring(0, 2); // At most two initials are used, even if the name has multiple parts
  } else if (initials.length === 0) {
    initials = "MO"; // Default
  }
  return {
    sx: {
      bgcolor: stringToColor(name, 0),
    },
    children: initials,
  };
}


export default function UserAvatar({ accountDisplay, size, shape }) {
  const userAccountData = accountDisplay.userAccountData;
  const props = {
    ...(size && { sx: { width: size, height: size } }),
    ...(shape && { variant: shape })
  };
  
  if (userAccountData === undefined) {
    return (
      <CircularProgress color='primary' />
    );
  }
  if (userAccountData.profilePictureUrl) {
    return (
      <Avatar alt={userAccountData.displayName || "User Avatar"} src={userAccountData.profilePictureUrl} {...props} />
    );
  }
  return (
    <Avatar {...stringAvatar(userAccountData.displayName)} {...props} />
  );
}