import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, MenuItem, Typography, TextField, Autocomplete, IconButton, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import authFetch from '../../../utils/auth';
import TranslateIcon from '@mui/icons-material/Translate';
import DeleteIcon from '@mui/icons-material/Delete';
import { AVAILABLE_LOCALES, DEFAULT_TRANSLATION } from '../../../constants/locale';
import SectionsFlowDisplay from './sectionsFlowDisplay';
import { useInternal } from '../../../context/internalProvider';
import { useDialog } from '../../../context/dialogProvider';

const defaultFlowConfig = {
  id: '',
  prefixID: '',
  name: DEFAULT_TRANSLATION,
  startingSectionID: '',
}

function FlowConfigBuilder({ flowConfigProp, onSubmit }) {
  const { showSuccessMessage, showErrorMessage } = useDialog();
  const { sections, questions } = useInternal();
  const [isEdit, setIsEdit] = useState(false);
  const [currentFlowConfig, setCurrentFlowConfig] = useState(defaultFlowConfig);

  useEffect(() => {
    if (flowConfigProp) {
      setCurrentFlowConfig(flowConfigProp);
      setIsEdit(true);
    } else {
      setCurrentFlowConfig(defaultFlowConfig);
      setIsEdit(false);
    }
  }, [flowConfigProp]);

  const [openTranslationModal, setOpenTranslationModal] = useState(false);
  const [currentEditing, setCurrentEditing] = useState(null);
  const [selectedLocale, setSelectedLocale] = useState(AVAILABLE_LOCALES[0]);
  const [translationText, setTranslationText] = useState('');

  const handleFlowConfigIDChange = (val) => {
    // Regular expression to match only lowercase letters, digits, and underscores
    const filteredValue = val.replace(/[^a-z0-9_]/g, '');
    setCurrentFlowConfig({ ...currentFlowConfig, id: filteredValue })
  };

  const handleFlowConfigPrefixIDChange = (val) => {
    // Regular expression to match only lowercase letters, digits, and underscores
    const filteredValue = val.replace(/[^a-z0-9]/g, '');
    setCurrentFlowConfig({ ...currentFlowConfig, prefixID: filteredValue })
  };

  const handleSelectStartingSection = (section) => {
    if (!section) {
      return
    }
    setCurrentFlowConfig({ ...currentFlowConfig, startingSectionID: section.id });
  };

  const handleSave = async () => {
    try {
      const response = await authFetch('/dev/update-flowconfig', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ flowConfig: currentFlowConfig })
      });

      if (response.isSuccess === true) {
        showSuccessMessage(isEdit ? 'Flow config updated successfully!' : 'Flow config created successfully!');
        setCurrentFlowConfig(defaultFlowConfig);
        onSubmit();
      } else {
        console.error("Invalid response!", response);
        showErrorMessage("Something went wrong.");
      }
    } catch (error) {
      console.error("There was an error!", error);
      showErrorMessage(error.message || "An unknown error occurred.");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await authFetch('/dev/delete-flowconfig', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ flowConfigID: currentFlowConfig.id })
      });

      if (response.isSuccess === true) {
        showSuccessMessage('Flow config has been deleted!');
        setCurrentFlowConfig(defaultFlowConfig);
        onSubmit();
      } else {
        console.error("Invalid response!", response);
        showErrorMessage("Something went wrong.");
      }
    } catch (error) {
      console.error("There was an error!", error);
      showErrorMessage(error.message || "An unknown error occurred.");
    }
  };

  const handleOpenTranslationModal = (type) => {
    setCurrentEditing({ type });
    setOpenTranslationModal(true);
  };

  const handleSaveTranslation = () => {
    setCurrentFlowConfig({
      ...currentFlowConfig,
      [currentEditing.type]: {
        ...currentFlowConfig[currentEditing.type],
        [selectedLocale]: translationText
      }
    });
    handleCloseTranslationModal();
  };

  const handleCloseTranslationModal = () => {
    setOpenTranslationModal(false);
    setTranslationText('');
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minWidth: 500,
      maxWidth: '100%', // Ensures responsiveness
      margin: 'auto',
      p: 5,
    }}>
      <Typography variant="h6" gutterBottom component="div" sx={{ textAlign: 'center' }}>
        {isEdit ? 'Edit Flow Config' : 'Add New Flow Config'}
      </Typography>
      <TextField sx={{ mt: 2 }}
        label="Flow Config ID"
        name="id"
        required
        fullWidth
        value={currentFlowConfig.id}
        onChange={e => handleFlowConfigIDChange(e.target.value)}
        helperText="Flow config ID is the same as flowType, current examples: personal_statement, recommendation_letter, personal_statement_evaluation, school_selection"
        variant={isEdit ? 'filled' : 'outlined'}
        InputProps={{
          readOnly: isEdit,
        }}
      />
      <TextField sx={{ mt: 3 }}
        label="Flow Config Prefix ID"
        name="prefix-id"
        required
        fullWidth
        value={currentFlowConfig.prefixID}
        onChange={e => handleFlowConfigPrefixIDChange(e.target.value)}
        helperText="Prefix ID is a short identifier of the flowType, current examples: ps, rl, pse, ss"
      />
      <Box key="flow-config-name" sx={{ mt: 2 }}>
        <Stack direction="row">
          <Typography variant="subtitle1" gutterBottom>
            Flow Config Name
          </Typography>
          <Button startIcon={<TranslateIcon />} onClick={() => handleOpenTranslationModal('name')} variant="text" size='small' sx={{ ml: 2 }}>
            Add Translation
          </Button>
        </Stack>
        <Box sx={{ ml: 2, mb: 1 }}>
          <Typography
            variant="caption"
            sx={{
              color: 'text.secondary', // Adjust for subtle appearance
              display: 'inline-block', // Ensures the typography respects container's margin
            }}
          >
            Formal name of this type of flow, current examples: Personal statement/个人陈述/個人陳述, Recommendation letter/推荐信/推薦信, Personal statement evaluation/个人陈述评估/個人陳述評估, School selection/选校指导/選校指導
          </Typography>
        </Box>
        <Box sx={{ ml: 3 }}>
          {Object.keys(currentFlowConfig.name).length > 0 ? (
            Object.entries(currentFlowConfig.name).map(([locale, text], index) => (
              <Box key={locale} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <TextField
                  variant="outlined"
                  size="small"
                  label={locale}
                  value={text}
                  onChange={(e) => {
                    const newTranslations = {
                      ...currentFlowConfig.name,
                      [locale]: e.target.value
                    };
                    setCurrentFlowConfig({ ...currentFlowConfig, name: newTranslations });
                  }}
                  sx={{ flexGrow: 1, mr: 1 }}
                />
                <IconButton onClick={() => {
                  const { [locale]: deleted, ...remainingTranslations } = currentFlowConfig.name;
                  setCurrentFlowConfig({ ...currentFlowConfig, name: remainingTranslations });
                }} color="error">
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              No translations added.
            </Typography>
          )}
        </Box>
      </Box>

      <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
        Starting section
      </Typography>
      <Autocomplete
        disablePortal
        id="starting-section"
        options={sections}
        value={currentFlowConfig.startingSectionID === '' ? null : sections.find(s => s.id === currentFlowConfig.startingSectionID)}
        getOptionLabel={(option) => option.id || ""}
        sx={{ width: '100%', p: 3 }}
        renderInput={(params) => <TextField {...params} label="Starting Section ID" />}
        onChange={(event, value) => handleSelectStartingSection(value)}
      />

      {currentFlowConfig.startingSectionID !== '' ? (
        <>
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            Flow Content
          </Typography>
          <SectionsFlowDisplay startingSectionID={currentFlowConfig.startingSectionID} sections={sections} questions={questions} />
        </>
      ) : (<></>)}

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button fullWidth onClick={handleSave} variant="contained" color="primary">
          {isEdit ? 'Update Flow Config' : 'Add Flow Config'}
        </Button>
        {isEdit && (
          <Button fullWidth onClick={handleDelete} variant="contained" color="error" sx={{ ml: 2 }}>
            Delete Flow Config
          </Button>
        )}
      </Box>

      <Dialog open={openTranslationModal} onClose={handleCloseTranslationModal}>
        <DialogTitle>Add Translation</DialogTitle>
        <DialogContent>
          <TextField
            select
            fullWidth
            label="Locale"
            value={selectedLocale}
            onChange={(e) => setSelectedLocale(e.target.value)}
            margin="normal"
          >
            {AVAILABLE_LOCALES.map((locale) => (
              <MenuItem key={locale} value={locale}>
                {locale}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="normal"
            fullWidth
            label="Translation"
            value={translationText}
            onChange={(e) => setTranslationText(e.target.value)}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTranslationModal}>Cancel</Button>
          <Button onClick={handleSaveTranslation}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default FlowConfigBuilder;
