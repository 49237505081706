import React from 'react';
import { QTYPE_INFO_INSERT, QTYPE_INSTRUCTION_ONLY, QTYPE_QUESTION_GROUP, QTYPE_SINGLE_SELECT, QTYPE_TEXT_INPUT } from '../../constants/question';
import QuestionGroup from './questionGroup';
import QuestionInsert from './questionInsert';
import QuestionSimple from './questionSimple';
import QuestionInstructionOnly from './questionInstructionOnly';

function Question({ questionDisplay, infoList, onAnswerChange, readOnly, validationErrors }) {
  switch (questionDisplay.type) {
    case QTYPE_QUESTION_GROUP:
      return <QuestionGroup
        questionDisplay={questionDisplay}
        infoList={infoList}
        onAnswerChange={onAnswerChange}
        readOnly={readOnly}
        validationErrors={validationErrors}
      />;
    case QTYPE_INFO_INSERT:
      return <QuestionInsert
        questionDisplay={questionDisplay}
        infoList={infoList}
        onAnswerChange={onAnswerChange}
        readOnly={readOnly}
        validationErrors={validationErrors}
      />;
    case QTYPE_TEXT_INPUT:
    case QTYPE_SINGLE_SELECT:
      return <QuestionSimple
        questionDisplay={questionDisplay}
        answer={infoList[questionDisplay.id]}
        onAnswerChange={onAnswerChange}
        readOnly={readOnly}
        errorMessage={validationErrors[questionDisplay.id]}
      />;
    case QTYPE_INSTRUCTION_ONLY:
      return <QuestionInstructionOnly
        questionDisplay={questionDisplay}
      />
    default:
      return <QuestionSimple
        questionDisplay={questionDisplay}
        answer={infoList[questionDisplay.id]}
        onAnswerChange={onAnswerChange}
        readOnly={readOnly}
        errorMessage={validationErrors[questionDisplay.id]}
      />;
  }
}

export default Question;
