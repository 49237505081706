import { Box, Tab, Tabs } from '@mui/material';
import { useHome } from '../../context/homeProvider';

export default function ServiceTabs() {
    const { servicesDisplay, tabValue, handleTabClick } = useHome();
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '90%',
        }}>
            <Box sx={{
                borderBottom: 1,
                borderColor: 'divider',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Tabs value={tabValue} onChange={handleTabClick} aria-label="service tabs" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                    {servicesDisplay && servicesDisplay.map((category, index) => (
                        <Tab label={category.displayText} key={index} value={category.sequenceNumber} />
                    ))}
                </Tabs>
            </Box>
        </Box>
    );
}