import { Box, Fab, Typography } from '@mui/material';
import * as React from 'react';
import ServicesDisplay from './servicesDisplay';
import SearchBar from './searchBar';
import { useHome } from '../../context/homeProvider';
import { useDialog } from '../../context/dialogProvider';
import { BackDropLoading } from '../loading/backDropLoading';
import ShareIcon from '@mui/icons-material/Share';
import { DIALOG_TYPE_INFO } from '../../constants/dialog';
import { useNavigate } from 'react-router-dom';

export default function HomeDisplay() {
  const navigate = useNavigate();
  const { mainTexts } = useHome();
  const { isLoading, openDialog } = useDialog();
  const mainWidth = '1060px';
  if (isLoading) {
    console.log('isLoading');
    return (
      <BackDropLoading />
    );
  } else {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px',
        alignSelf: 'stretch',
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
          padding: '30px 40px 40px 40px',
          width: mainWidth,
          // alignSelf: 'stretch',
          borderRadius: '20px',
          bgcolor: 'bg_dark.main',
          // boxShadow: 2,
        }}>
          <Typography variant="h4">
            {mainTexts && mainTexts.title}
          </Typography>
          <Typography variant="h6">
            {mainTexts && mainTexts.subtitle}
          </Typography>
          <SearchBar />
        </Box>

        <ServicesDisplay />

        <Fab
          variant='extended'
          color='primary'
          style={{
            position: 'fixed',
            bottom: '500px',  // distance from the bottom of the screen
            right: '16px',   // distance from the right side of the screen
            zIndex: 1000,    // make sure it floats above other elements
          }}
          onClick={
            () => openDialog(DIALOG_TYPE_INFO, {
              title: mainTexts.freeProgramTitle,
              description: mainTexts.freeProgramDescription,
              confirmBtn: mainTexts.freeProgramActionText,
            }, () => {navigate('/pricing')})}>
          <ShareIcon sx={{ mr: 1 }} />
          {mainTexts.freeProgramButtonText}
        </Fab>
      </Box>
    );
  }
}