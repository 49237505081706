import { Backdrop, CircularProgress } from "@mui/material";

export function BackDropLoading() {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.tooltip + 100 }}
            open={true}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}