import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import SignIn from '../signin/signIn';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


export default function SigninDialog({ open, handleClose, signInDisplay }) {
  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      aria-labelledby="alert-content-title"
      aria-describedby="alert-content-description"
      PaperProps={{
        sx: {
          borderRadius: '40px',
        }
      }}
      sx={{
        '& .MuiDialog-paper': {
          overflow: 'hidden',
        }
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
          zIndex: 1000
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          width: '455px',
          height: '455px',
          position: 'absolute',
          right: '-175px',
          top: '-260px',
          borderRadius: '455px',
          background: '#ffecc066',
          mixBlendMode: 'multiply'
        }}
      ></Box>
      <DialogContent>
        <SignIn signInDisplay={signInDisplay} />
      </DialogContent>
    </Dialog>
  )
}