import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MarkdownParser from '../dataDisplay/markdown';


export default function InfoDialog({ open, handleClose, handleConfirm, content }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-content-title"
      aria-describedby="alert-content-description"
      maxWidth='md'
      sx={{
        '& .MuiDialog-paper': {
          minWidth: '300px',
        }
      }}
    >
      <DialogTitle id="alert-content-title">
        {content.title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText id="alert-content-description" sx={{ whiteSpace: 'pre-line', fontSize: '16px' }}>
          <MarkdownParser text={content.description} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {content.cancelBtn && (
          <Button sx={{ boxShadow: 'none' }} onClick={() => handleClose()} autoFocus>{content.cancelBtn}</Button>
        )}
        {content.confirmBtn && (
          <Button variant='contained' sx={{ boxShadow: 'none' }} onClick={() => handleConfirm()} autoFocus>{content.confirmBtn}</Button>
        )}
      </DialogActions>
    </Dialog>
  )
}