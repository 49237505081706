import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Questionnaire from "../questionnaire/questionnaire";
import React from "react";

export default function QuestionnaireViewerDialog({ open, handleClose, content }) {
    return (
        <Dialog open={open} fullWidth sx={{ 
            '& .MuiDialog-paper': {
                minWidth: '750px',
            },
         }}>
            <IconButton
            aria-label="close"
                onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ width: '100%' }}>
                {content.flowId && <Questionnaire flowid={content.flowId} readOnly={true} env={content.env} fullWidth />}
            </DialogContent>
        </Dialog>
    )
}