import { auth } from './firebaseConfig';

const authFetch = async (url, options = {}) => {

  console.log('authFetch: ', url)

  options.credentials = 'include'

  const user = auth.currentUser;
  if (user) {
    const idToken = await user.getIdToken();
    if (!options.headers) {
      options.headers = {};
    }
    options.headers['Authorization'] = `Bearer ${idToken}`; // Update the token in the header
  } else {
    //console.log('firebase user not found')
  }

  const response = await fetch(process.env.REACT_APP_API_URL + url, options);
  
  if (!response.ok) {
    if (response.status === 303) {
      const res = await response.json();
      window.location.href = res.url;
      return res;
    } else if (response.status === 401 || response.status === 403) {
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
    } else if (response.status === 400 || response.status === 500) {
      return response.json().then(error => {
        if (error.redirectTo) {
          setTimeout(() => {
            window.location.href = error.redirectTo;
          }, 3000);
          return;
        }
        throw error;
      });
    }
    throw new Error(`Response not OK: ${response.status}`);
  }

  if (response.headers.get('Content-Type').includes('application/json')) {
    return await response.json();
  }
  return response;
};

export default authFetch;
