import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Collapse, Divider, ListItemButton, Box } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { getAllSectionIDs } from '../../../utils/questionnaire/section';
import { useInternal } from '../../../context/internalProvider';

function SectionsFlowDisplay({ startingSectionID }) {
  const { sections, questions } = useInternal();
  const [flowSections, setFlowSections] = useState([]);
  const [open, setOpen] = useState({});

  useEffect(() => {
    if (startingSectionID !== '') {
      const sectionIDs = getAllSectionIDs(startingSectionID, sections);
      setFlowSections(sections.filter(s => sectionIDs.has(s.id)));
    } else {
      setFlowSections([]);
    }
  }, [startingSectionID, sections]);

  const handleExpand = (section) => {
    setOpen((prevOpen) => ({ ...prevOpen, [section]: !prevOpen[section] }));
  };

  return (
    <Box>
      {startingSectionID !== '' && <List
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {flowSections.map((section) => (
          <React.Fragment key={section.id}>
            <ListItemButton onClick={() => handleExpand(section.id)}>
              <ListItemText primary={section.id} />
              {open[section.id] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open[section.id]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {section.questionIDs.map((qID, index) => (
                  <ListItem key={`${section.id}-${qID}`} sx={{ pl: 4 }}>
                    <ListItemText primary={qID} secondary={questions? questions.find(q => q.id === qID)?.summary : ""}/>
                  </ListItem>
                ))}
              </List>
            </Collapse>
            <Divider />
          </React.Fragment>
        ))}
      </List>}
    </Box>
  );
}

export default SectionsFlowDisplay;
