import { Badge, Box, Button } from '@mui/material';
import { getIcon } from '../../utils/assets';

export default function FeaturedButton({ button, handleArticleButtonClick }) {
    return (
        <Badge color='third' max={9999} badgeContent={button.optionPoints && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {<img src={getIcon('icon-coins')} height={'15px'} alt='coins'></img>}{button.optionPoints}
            </Box>
        )}>
            <Button variant='outlined' sx={{ border: 2, padding: 0.5 }} onClick={(event) => handleArticleButtonClick(event, button.optionId, button.productIds)}> {
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {button.optionIcon && <img src={getIcon(button.optionIcon)} alt={button.optionId} height={'20px'} />}
                    {button.optionText}
                </Box>
            } </Button>
        </Badge>
    );
}