import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Typography, Button, Paper } from '@mui/material';
import { getQuestionDisplay } from '../../../utils/questionnaire/question';
import Question from '../../questionnaire/question';
import { AVAILABLE_LOCALES } from '../../../constants/locale';
import { useInternal } from '../../../context/internalProvider';

function SectionPreview({ section, onEdit }) {
  const { questions } = useInternal();

  const [selectedLocale, setSelectedLocale] = useState('en');
  const [questionDisplays, setQuestionDisplays] = useState([]);

  useEffect(() => {
    setQuestionDisplays(section.questionIDs.map((qID) => {
      const q = questions.find(q => q.id === qID);
      return getQuestionDisplay(q, selectedLocale, questions);
    }));
  }, [section, questions, selectedLocale]);

  const handleLocaleChange = (event, newValue) => {
    setSelectedLocale(newValue);
  };

  if (!section) {
    return <Typography>Select a section to view its details.</Typography>;
  }

  return (
    <Paper elevation={3} sx={{ p: 2, ml: 3, mt: 2, mb: 2, mr: 3, overflow: 'auto' }}>
      <Tabs value={selectedLocale} onChange={handleLocaleChange} aria-label="Locale tabs" variant="scrollable" scrollButtons="auto">
        {AVAILABLE_LOCALES.map((locale) => (
          <Tab label={locale} value={locale} key={locale} />
        ))}
      </Tabs>

      <Typography variant="h6" sx={{ mt: 2, textAlign: 'center' }} >{section.title[selectedLocale]}</Typography>

      {questionDisplays.map((qd) => (
        <Question key={qd.id} sx={{ mt: 2 }} questionDisplay={qd} infoList={{}} validationErrors={{}} />
      ))}

      <Button onClick={onEdit} variant="contained" color="primary" sx={{ mt: 2 }}>
        Edit Section
      </Button>
    </Paper>
  );
}

export default SectionPreview;