const { FLOWTYPE_PS, FLOWTYPE_PSE, FLOWTYPE_PSP, FLOWTYPE_RL, FLOWTYPE_RLE,
  FLOWTYPE_RLP, FLOWTYPE_SS, FLOWTYPE_CV, FLOWTYPE_CVE, FLOWTYPE_CVP, FLOWTYPE_ER,
  FLOWTYPE_ES, FLOWTYPE_SOP, FLOWTYPE_OOO, FLOWTYPE_SSES, FLOWTYPE_RLR, FLOWTYPE_ML, FLOWTYPE_AS } = require("../constants/flow");

function getIcon(icon, size = '', color = 'default') {
  try {
    return require(`../assets/icons/${icon}-${color}${size}.png`);
  } catch (error) {
    return require(`../assets/icons/${icon}${size}.png`);
  }
}


function getFlowTypeIcon({ flowType, size = '', color = 'default', isDefault = true }) {
  let icon;
  switch (flowType) {
    case FLOWTYPE_PS:
      icon = `iconPS-${color}${size}`;
      break;
    case FLOWTYPE_PSE:
      icon = `iconPSE-${color}${size}`;
      break;
    case FLOWTYPE_PSP:
      icon = `iconPSP-${color}${size}`;
      break;
    case FLOWTYPE_RL:
      icon = `iconRL-${color}${size}`;
      break;
    case FLOWTYPE_RLE:
      icon = `iconRLE-${color}${size}`;
      break;
    case FLOWTYPE_RLP:
      icon = `iconRLP-${color}${size}`;
      break;
    case FLOWTYPE_SS:
      icon = `iconSS-${color}${size}`;
      break;
    case FLOWTYPE_CV:
      icon = `iconCV-${color}${size}`
      break;
    case FLOWTYPE_CVE:
      icon = `iconCVE-${color}${size}`;
      break;
    case FLOWTYPE_CVP:
      icon = `iconCVP-${color}${size}`;
      break;
    case FLOWTYPE_SOP:
      icon = `iconSOP-${color}${size}`;
      break;
    case FLOWTYPE_ER:
      icon = `iconER-${color}${size}`;
      break;
    case FLOWTYPE_ES:
      icon = `iconER-${color}${size}`;
      break;
    case FLOWTYPE_OOO:
      icon = `iconOOO-${color}${size}`;
      break;
    case FLOWTYPE_SSES:
      icon = `iconSSES-${color}${size}`;
      break;
    case FLOWTYPE_RLR:
      icon = `iconRLR-${color}${size}`;
      break;
    case FLOWTYPE_ML:
      icon = `iconPNE-${color}${size}`;
      break;
    case FLOWTYPE_AS:
      icon = `iconPS-${color}${size}`;
      break;
    default:
      if (isDefault) {
        icon = 'iconPS-default';
      } else {
        icon = 'iconPS-primary@2x';
      }
      break;
  }
  return require(`../assets/icons/${icon}.png`)
}

function getImagePNG(image) {
  return require(`../assets/images/${image}.png`)
}

function getImage(image) {
  return require(`../assets/images/${image}`)
}

function getBtnIcon(optionId) {
  switch (optionId) {
    case 'download-button':
      return getIcon('icon-download');
    case 'send-email-button':
      return getIcon('icon-mail-forward');
    case 'rename-doc-button':
      return getIcon('icon-edit@2x');
    case 'check-info-button':
      return getIcon('icon-file-description');
    default:
      return getIcon('icon-edit@2x');
  }
}

module.exports = {
  getIcon,
  getFlowTypeIcon,
  getImagePNG,
  getImage,
  getBtnIcon
}