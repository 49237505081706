// service id
export const PERSONAL_STATEMENT_SERVICE = 'personal-statement-service';
export const PERSONAL_STATEMENT_EVALUATION_SERVICE = 'personal-statement-evaluation-service';
export const PERSONAL_STATEMENT_POLISH_SERVICE = 'personal-statement-polish-service';
export const STATEMENT_OF_PURPOSE_SERVICE = 'statement-of-purpose-service';
export const SCHOOL_SELECTION_SERVICE = 'school-selection-service';
export const RECOMMENDATION_LETTER_SERVICE = 'recommendation-letter-service';
export const RECOMMENDATION_LETTER_EVALUATION_SERVICE = 'recommendation-letter-evaluation-service';
export const RECOMMENDATION_LETTER_POLISH_SERVICE = 'recommendation-letter-polish-service';
export const CV_COMPOSE_SERVICE = 'cv-compose-service';
export const CV_POLISH_SERVICE = 'cv-polish-service';
export const CV_EVALUATION_SERVICE = 'cv-evaluation-service';
export const EXPERT_REVIEW_SERVICE = 'expert-review-service';
export const EXPERT_CUSTOMIZED_SERVICE = 'expert-customized-service';
export const ONE_ON_ONE_CONSULTATION_SERVICE = '1-on-1-consultation-service';
export const SCHOOL_SELECTION_EXPERT_SERVICE = 'school-selection-expert-service';
export const MEDITATE_SERVICE = 'meditate-service';
export const RECOMMENDATION_LETTER_REQUEST_SERVICE = 'recommendation-letter-request-service';
export const MOTIVATION_LETTER_SERVICE = 'professional-network-email-service';
export const APPLICATION_SUBMISSION_SERVICE = 'application-submission-service';