import * as React from 'react';
import Box from '@mui/material/Box';
import { useArticle } from '../../context/articleProvider';
import FeaturedButton from './featuredButton';
import { Grid } from '@mui/material';
import DefaultButton from './defaultButton';



export default function ArticleButtonGroup() {
    const { btnGroups, handleArticleButtonClick } = useArticle();
    return (
        <Grid container sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        }}>
            <Grid item xs={4} sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '6px',
            }}>
                {btnGroups && btnGroups.default && (
                    btnGroups.default.map((btn, index) => (
                        <DefaultButton key={index} type={btn.btnType} btn={btn} index={index} handleArticleButtonClick={handleArticleButtonClick} />
                    ))
                )}
            </Grid>
            <Grid item xs={4} sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '6px',
            }}>
                {btnGroups && btnGroups.templateType && (
                    btnGroups.templateType.map((btn, index) => (
                        <FeaturedButton key={index} button={btn} handleArticleButtonClick={handleArticleButtonClick} />
                    ))
                )}
            </Grid>
            <Grid item xs={4} sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '6px',
            }}>
                <Box sx={{ width: '70px' }}></Box>
                {btnGroups && btnGroups.featured && (
                    btnGroups.featured.map((btn, index) => (
                        <FeaturedButton key={index} button={btn} handleArticleButtonClick={handleArticleButtonClick}/>
                    ))
                )}
            </Grid>
        </Grid>
    );
}