import React from "react";
import { Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import UserDataViewer from "../lydia/userDataViewer";

export default function UserDataViewerDialog({ open, handleClose, content }) {
    return (
        <Dialog open={open} fullWidth sx={{ 
            '& .MuiDialog-paper': {
                minWidth: '750px',
            },
         }}>
            <IconButton
            aria-label="close"
                onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ width: '100%' }}>
                <Typography variant="h6" gutterBottom>User Data</Typography>
                {content && (<UserDataViewer data={content} />)}
            </DialogContent>
        </Dialog>
    );
}