import * as React from 'react';
import HomeAppBar from '../components/appbar/appBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import { Helmet } from 'react-helmet';

export default function About() {
    return (
        <>
            <Helmet>
                <meta name="description" content='关于我们.' />
            </Helmet>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column', // This will stack items vertically.
                alignItems: 'center', // This will center items horizontally in the container.
                justifyContent: 'center', // This will center items vertically in the container.
                height: '100vh', // This will make the container the full height of the viewport.
                pt: 20, // This will add padding to the top of the container.
                pb: 20, // This will add padding to the bottom of the container.
            }}>
                <HomeAppBar />
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column', // Stack items vertically
                    alignItems: 'center', // Center items horizontally
                    justifyContent: 'center', // Center items vertically
                    height: '100%', // Take up all available space in the flex container.
                    width: '70%',
                }}>
                    <Slide direction="right" in={true} timeout={500}>
                        <Typography variant="h1" component="h1" gutterBottom sx={{ fontFamily: "Protest Strike, sans-serif", opacity: '0.85' }}>
                            What we do
                        </Typography>
                    </Slide>
                    <Slide direction="right" in={true} timeout={600}>
                        <Typography variant="h6" component="h2" gutterBottom sx={{ fontFamily: "Protest Strike, sans-serif", opacity: '0.85' }}>
                            Take the LYDE - We are a group of young and talented entrepreneurs and engineers. Our groundbreaking idea aims to contribute to a better tomorrow. We provide smart solutions for individuals and companies of all sizes and continuously strive to evolve and improve for unparalleled, dedicated service.
                        </Typography>
                    </Slide>
                    <Slide direction="right" in={true} timeout={700}>
                        <Typography variant="h6" component="h2" gutterBottom sx={{ fontFamily: "Protest Strike, sans-serif", opacity: '0.85' }}>
                            At Lyde, we firmly believe that the right understanding of higher education and technological edge can pave the way to a successful future in academia or business. We value feedback from our clients. Contact us today to schedule a meeting with one of our representatives or request a demo.
                        </Typography>
                    </Slide>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column', // Stack items vertically
                    alignItems: 'center', // Center items horizontally
                    justifyContent: 'center', // Center items vertically
                    width: '30%',
                    marginTop: '2rem',
                    marginBottom: '2rem'
                }}>
                    <Fade in={true} timeout={1200}>
                        <Divider orientation="horizontal" flexItem />
                    </Fade>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column', // Stack items vertically
                    alignItems: 'center', // Center items horizontally
                    justifyContent: 'center', // Center items vertically
                    height: '100%', // Take up all available space in the flex container.
                    width: '70%',
                }}>
                    <Slide direction="left" in={true} timeout={800}>
                        <Typography variant="h1" component="h1" gutterBottom sx={{ fontFamily: "Protest Strike, sans-serif", opacity: '0.85' }}>
                            Who we are
                        </Typography>
                    </Slide>
                    <Slide direction="left" in={true} timeout={900}>
                        <Typography variant="h6" component="h2" gutterBottom sx={{ fontFamily: "Protest Strike, sans-serif", opacity: '0.85' }}>
                            LYDE the team - Our passion for producing meaningful information for clients is what sets us apart. Embracing the capacity of AI-powered content generation, the Lyde team believes in the potential of our creativity and resourcefulness, and it truly shows in all of our work. We work tirelessly to bring you a better tomorrow, and we’re proud of every single member of staff. To learn more about the incredible Lyde team, please get in touch with us for more details.
                        </Typography>
                    </Slide>
                </Box>

            </Box>
        </>

    );
}