import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getImagePNG } from '../../utils/assets';

export default function IntroCard({ card, handleBtnClick }) {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: { xs: '80%', md: '60%' },
            padding: '20px',
            userSelect: 'none',
            '&:hover': {
                transition: 'transform 0.5s ease-in-out',
                transform: 'translateY(-5px)',
                borderRadius: '10px',
            }
        }}>
            <Box>
                <img src={getImagePNG(card.img)} alt='intro card' height={'70%'} width={'90%'} />
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                pt: '20px'
            }}>
                <Typography variant='h5' color={'primary.main'} sx={{ textAlign: 'left', fontSize: { xs: 'h6.fontSize', md: 'h5.fontSize' } }} gutterBottom>{card.title}</Typography>
                <Typography variant='landing_subtitle' sx={{ fontSize: { xs: 'h7.fontSize', md: 'landing_subtitle.fontSize' } }}>
                    {card.description}
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                gap: '20px',
                mt: '20px'
            }}>
                {card.buttons.map((button, index) => {
                    if (button.style === 'outlined') {
                        return (
                            (
                                <Button key={index} variant={button.style} onClick={() => handleBtnClick(button.id, card.sampleId)} sx={{
                                    display: 'flex',
                                    width: 'wrap',
                                    border: '2px solid',
                                    borderRadius: '10px',
                                    '&:hover': {
                                        backgroundColor: 'primary.main',
                                        color: 'primary.contrastText',
                                        border: '2px solid',
                                    }
                                }}>
                                    {button.displayText}
                                </Button>
                            )
                        )
                    } else {
                        return (
                            (
                                <Button key={index} variant={button.style} onClick={() => handleBtnClick(button.id, card.sampleId)} sx={{
                                    display: 'flex',
                                    width: 'wrap',
                                    borderRadius: '10px',
                                    backgroundColor: 'transparent',
                                    color: 'primary.main',
                                    boxShadow: '0 0 0 0',
                                    padding: '5px 10px 5px 0px',
                                    '&:hover': {
                                        backgroundColor: 'primary.main',
                                        color: 'primary.contrastText',
                                        boxShadow: '0 0 0 0',
                                    }
                                }}>
                                    <ChevronRightIcon />
                                    {button.displayText}
                                </Button>
                            )
                        )
                    }
                })}
            </Box>
        </Box>
    );
}