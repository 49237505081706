import React from 'react';
import { Box, Fab, Fade, IconButton, Typography } from '@mui/material';
import coverImage from '../../assets/images/landing_p1.jpg';
import coverImage2 from '../../assets/images/landing_xianfeng.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IntroCard from './introCard';
import authFetch from '../../utils/auth';
import { BackDropLoading } from '../loading/backDropLoading';
import { useAuth } from '../../context/authProvider';
import { useNavigate } from 'react-router-dom';
import { getIcon } from '../../utils/assets';
import TryBtn from './tryBtn';
import BottomBar from '../bottomBar/bottomBar';
import AskQuestionBtn from './askQuestionBtn';
import FAQAccordian from './FAQAccordian';
import ShareIcon from '@mui/icons-material/Share';
import { DIALOG_TYPE_INFO } from '../../constants/dialog';
import { useDialog } from '../../context/dialogProvider';
import Carousel from 'react-material-ui-carousel';

export default function LandingDisplay() {
    const { openDialog } = useDialog();
    const { openSignInDialog, isLoggedIn, isMobileAccess } = useAuth();
    const mainContentRef = React.useRef(null);
    const [landingDisplay, setLandingDisplay] = React.useState(null);
    // const [showCards, setShowCards] = React.useState(false);
    const navigate = useNavigate();

    const scrollToContent = () => {
        mainContentRef.current?.scrollIntoView({ behavior: 'smooth' });
        // setShowCards(true);
    };

    const handleBtnClick = (buttonId, sampleId = null) => {
        switch (buttonId) {
            case 'start_test_btn':
            case 'login_and_try_btn':
                if (isLoggedIn) {
                    navigate('/home');
                } else {
                    openSignInDialog();
                }
                break;
            case 'see_sample_btn':
                navigate('/sample', { state: { sampleId: sampleId } });
                break;
            case 'contact_us_btn':
                navigate('/contact');
                break;
            default:
                break;
        }
    }

    React.useEffect(() => {
        authFetch('/public/get-landing-display')
            .then(data => {
                setLandingDisplay(data.landingDisplay);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);

    if (!landingDisplay) {
        return (
            <BackDropLoading />
        );
    } else {
        const mainCover = (
            <Box sx={{
                backgroundImage: `url(${coverImage})`,
                backgroundSize: 'cover', // Cover the entire box area
                backgroundPosition: 'center',
                height: { xs: '100vh', md: '80vh' },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    textAlign: 'left',
                    width: { xs: '90vw', md: '60vw' }, // Responsive width
                    height: { xs: 'auto', md: '50vh' },
                }}>
                    <Fade in={true} timeout={1500}>
                        <Typography variant='landing_subtitle' sx={{ fontSize: { xs: 'h7.fontSize', md: 'landing_subtitle.fontSize' } }}> {landingDisplay.subtitle} </Typography>
                    </Fade>
                    <Fade in={true} timeout={2500}>
                        <Box sx={{ mt: '10px', display: 'flex', flexDirection: 'column' }}>
                            <Box>
                                <Typography variant='landing_title' color={''} sx={{ ml: '-10px', fontSize: { xs: 'h2.fontSize', md: 'landing_title.fontSize' } }} > {landingDisplay.title} </Typography>
                            </Box>
                            {/* <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                ml: '15px',
                            }}>
                                <Typography variant='landing_slang' color={''} sx={{}} > {landingDisplay.slang} </Typography>
                            </Box> */}
                        </Box>
                    </Fade>
                    <Fade in={true} timeout={3500}>
                        <Box sx={{ mt: '20px' }}>
                            <TryBtn landingDisplay={landingDisplay} handleBtnClick={handleBtnClick} />
                        </Box>
                    </Fade>
                </Box>
                <Box sx={{
                    display: 'flex',
                    position: 'relative',
                    flexDirection: 'column',
                    justifyContent: 'end',
                    top: '70px',
                }}>
                    <Fade in={true} timeout={3500}>
                        <IconButton onClick={scrollToContent} sx={{
                            '&:hover': {
                                transform: 'translateY(5px)',
                                backgroundColor: 'transparent',
                            },

                        }}>
                            <ExpandMoreIcon sx={{ fontSize: '50px', color: 'subtitle.secondary' }} />
                        </IconButton>
                    </Fade>
                </Box>
            </Box>
        );

        const secondCover = (
            <Box sx={{
                backgroundImage: `url(${coverImage2})`,
                backgroundSize: 'cover', // Cover the entire box area
                backgroundPosition: 'center',
                height: { xs: '100vh', md: '80vh' },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                    cursor: 'pointer'
                }
            }}
                onClick={() => { window.open("https://info.foxiapply.com/xfjh", "_blank"); }}></Box>
        );

        const coverList = [mainCover, secondCover];
        return (
            <React.Fragment>
                <Carousel interval={5000} navButtonsAlwaysVisible={!isMobileAccess} indicators={true} animation='fade' duration={1000}>
                    {coverList.map((cover, index) => (
                        <Box key={index}>
                            {cover}
                        </Box>
                    ))}
                </Carousel>
                <Box ref={mainContentRef} sx={{ height: '100px' }}></Box>
                <Box sx={{ bgcolor: 'bg1.main', display: 'flex', flexDirection: 'column', alignItems: 'center', pb: '100px' }}>
                    <Box>
                        <Typography variant='h4' sx={{ textAlign: 'center' }} gutterBottom> {landingDisplay.content.title} </Typography>
                        {/* <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            {landingDisplay.content.descriptions.map((description, index) => (
                                <Typography key={index} variant='landing_subtitle' color={description.emphasized ? 'primary.main' : 'subtitle.secondary'} sx={{
                                    textAlign: 'center',
                                    fontWeight: description.emphasized ? 700 : 500
                                }}> &nbsp;{description.text}&nbsp; </Typography>
                            ))}
                        </Box> */}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: '70px',
                        gap: { xs: '0px', md: '100px' },
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: { xs: 'center', md: 'flex-end' },
                            gap: '100px',
                        }}>
                            {landingDisplay.serviceCards.serviceCardsLeft.map((card, index) => (
                                <IntroCard key={index} card={card} handleBtnClick={handleBtnClick} />
                            ))}
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: { xs: 'center', md: 'flex-start' },
                            gap: '100px'
                        }}>
                            {landingDisplay.serviceCards.serviceCardsRight.map((card, index) => (
                                <IntroCard key={index} card={card} handleBtnClick={handleBtnClick} />
                            ))}
                        </Box>
                    </Box>
                    <Box>
                        <TryBtn landingDisplay={landingDisplay} handleBtnClick={handleBtnClick} />
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100vw',
                    padding: '80px 0 170px 0',
                    bgcolor: 'bg_dark.main',
                    gap: '100px',
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: { xs: 'center', md: 'flex-start' },
                        height: '100%',
                        position: 'sticky',
                        mt: '20px',
                    }}>
                        <Typography variant='h5' sx={{ textAlign: 'left' }}> {landingDisplay.faqTitle} </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            gap: '40px',
                            mt: '20px',
                            pl: '2px',
                        }}>
                            {/* <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                                <img src={getIcon('icon-wechat')} alt='wechat' />
                                <Typography variant='landing_subtitle' sx={{ textAlign: 'center' }}> lydeio </Typography>
                            </Box> */}
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                                <img src={getIcon('icon-mail')} alt='wechat' />
                                <Typography variant='landing_subtitle' sx={{ textAlign: 'center' }}> support@foxiapply.com </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ mt: '50px', ml: '-8px' }}>
                            <AskQuestionBtn landingDisplay={landingDisplay} handleBtnClick={handleBtnClick} />
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <FAQAccordian faqs={landingDisplay.faqs} />
                    </Box>
                </Box>
                <BottomBar />
                <Fab
                    variant='extended'
                    color='primary'
                    sx={{
                        position: 'fixed',
                        bottom: '500px',  // distance from the bottom of the screen
                        right: '16px',   // distance from the right side of the screen
                        zIndex: 1000,    // make sure it floats above other elements
                        width: 'wrap',
                        fontSize: { xs: '11px', md: '14px' },
                    }}
                    onClick={
                        () => openDialog(DIALOG_TYPE_INFO, {
                            title: landingDisplay.freeProgramTitle,
                            description: landingDisplay.freeProgramDescription,
                            confirmBtn: landingDisplay.freeProgramActionText,
                        }, () => { navigate('/pricing') })}>
                    <ShareIcon sx={{ mr: { xs: 0, md: 1 } }} />
                    {isMobileAccess ? '' : landingDisplay.freeProgramButtonText}
                </Fab>
            </React.Fragment>
        )
    }
}