import React from 'react';
import { Typography, Box, Stack } from '@mui/material';
import SectionTitle from './sectionTitle';

function displayExperience(item, index) {
    return (
        <Box key={index} mb={2}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontStyle: 'italic' }} color={'subtitle.secondary'}>{item.title} / {item.location} / {item.timePeriod}</Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold', fontStyle: 'italic' }} color={'subtitle.secondary'}>{item.subtitle}</Typography>
            {item.additionalDetails.map(((line, lineIdx) => (
                <Stack direction="row" alignItems="flex-start" spacing={1} key={lineIdx}>
                    <ul style={{ paddingLeft: '20px' }}>
                        <li><Typography variant="body2">{line}</Typography></li>
                    </ul>
                </Stack>
            )))}
        </Box>
    );
}

function CVTemplateV3({ resumeData }) {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h3" sx={{ textAlign: 'left', fontWeight: 'bold' }}>{resumeData.name}</Typography>
            <Typography variant="subtitle2" sx={{ textAlign: 'left', fontWeight: 'bold', mb: '20px' }}>{resumeData.basicInfo}</Typography>
            {resumeData.education && (
                <>
                    <SectionTitle title={'Education'} />
                    {resumeData.education.map((edu, index) => (
                        <Box key={index} mb={2}>
                            <Typography variant="body1" textAlign={'left'} sx={{ fontWeight: 'bold', fontStyle: 'italic' }} color={'subtitle.secondary'}>{edu.institution} / {edu.location} / {edu.timePeriod}</Typography>
                            <Typography variant="body2" textAlign={'left'} sx={{ fontWeight: 'bold', fontStyle: 'italic' }} color={'subtitle.secondary'}>{edu.degree}</Typography>
                            {edu.additionalDetails.map(((line, lineIdx) => (
                                <Stack direction="row" alignItems="flex-start" spacing={1} key={lineIdx}>
                                    <ul style={{ paddingLeft: '20px' }}>
                                        <li><Typography variant="body2">{line}</Typography></li>
                                    </ul>
                                </Stack>
                            )))}
                        </Box>
                    ))}</>
            )}
            {resumeData.professionalExperiences && resumeData.professionalExperiences.length > 0 && (
                <>
                    <SectionTitle title={'Professional Experiences'} />
                    {resumeData.professionalExperiences.map((exp, index) => displayExperience(exp, index))}
                </>
            )}
            {resumeData.researchExperiences && resumeData.researchExperiences.length > 0 && (
                <>
                    <SectionTitle title={'Research Experiences'} />
                    {resumeData.researchExperiences.map((exp, index) => displayExperience(exp, index))}
                </>
            )}
            {resumeData.extracurricularActivities && resumeData.extracurricularActivities.length > 0 && (
                <>
                    <SectionTitle title={'Extracurricular Activities'} />
                    {resumeData.extracurricularActivities.map((exp, index) => displayExperience(exp, index))}
                </>
            )}
            {resumeData.publications && resumeData.publications.length > 0 && (
                <>
                    <SectionTitle title={'Publications'} />
                    {resumeData.publications.map(((line, lineIdx) => (
                        <Stack direction="row" alignItems="flex-start" spacing={1} key={lineIdx}>
                            <ul style={{ paddingLeft: '20px' }}>
                                <li><Typography variant="body2">{line}</Typography></li>
                            </ul>
                        </Stack>
                    )))}
                </>
            )}
            {resumeData.skills && (
                <>
                    <SectionTitle title={'Skills & Interests'} />
                    {resumeData.skills.technical && (
                        <Stack direction={'row'} gap={'5px'}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Technical:</Typography>
                            <Typography variant="body1" >{resumeData.skills.technical}</Typography>
                        </Stack>
                    )}
                    {resumeData.skills.languages && (
                        <Stack direction={'row'} gap={'5px'}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Languages:</Typography>
                            <Typography variant="body1" >{resumeData.skills.languages}</Typography>
                        </Stack>
                    )}
                    {resumeData.skills.laboratory && (
                        <Stack direction={'row'} gap={'5px'}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Laboratory:</Typography>
                            <Typography variant="body1" >{resumeData.skills.laboratory}</Typography>
                        </Stack>
                    )}
                    {resumeData.skills.interests && (
                        <Stack direction={'row'} gap={'5px'}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Interests:</Typography>
                            <Typography variant="body1" >{resumeData.skills.interests}</Typography>
                        </Stack>
                    )}
                </>
            )}
        </Box>
    );
}

export default CVTemplateV3;
