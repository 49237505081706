import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { getBtnIcon } from '../../utils/assets';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { BTN_TYPE_BASIC, BTN_TYPE_MENU } from '../../constants/article';

export default function DefaultButton({type, btn, index, handleArticleButtonClick}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleMenuItemClick = (event, optionId, productIds) => {
        handleArticleButtonClick(event, optionId, productIds);
        handleMenuClose();
    };

    switch (type) {
        case BTN_TYPE_BASIC:
            return (
                <Tooltip key={index} title={btn.optionText} placement="top">
                    <IconButton variant='outlined' key={index} onClick={(event) => handleArticleButtonClick(event, btn.optionId, btn.productIds)} sx={{
                        border: '1.5px solid',
                        borderColor: '#AAAAAA',
                        borderRadius: '10px',
                        width: '35px',
                        height: '35px',
                    }}>
                        <img src={getBtnIcon(btn.optionId)} alt={btn.optionId} />
                    </IconButton>
                </Tooltip>
            )
        case BTN_TYPE_MENU:
            return (
                <Tooltip key={index} title={btn.optionText} placement="top">
                    <div>
                        <IconButton
                            variant='outlined'
                            onClick={handleMenuOpen}
                            sx={{
                                border: '1.5px solid',
                                borderColor: '#AAAAAA',
                                borderRadius: '10px',
                                width: '35px',
                                height: '35px',
                            }}
                        >
                            <img src={getBtnIcon(btn.optionId)} alt={btn.optionId} />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            {btn.btnMenuItems && btn.btnMenuItems.map((menuItem, index) => (
                                <MenuItem key={index} onClick={(event) => handleMenuItemClick(event, menuItem.optionId, menuItem.productIds)}>
                                    {menuItem.optionText}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                </Tooltip>
            );
        default:
            return null;
    }
}