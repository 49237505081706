export function countWordsAndChineseCharacters(text) {
    let count = 0;
    let inEnglishWord = false;
    
    for (let char of text) {
        if (isChinese(char)) {
            count++; // Count Chinese character as a word
            inEnglishWord = false; // Reset English word state
        } else if (/\s/.test(char)) {
            inEnglishWord = false; // Reset English word state on whitespace
        } else if (!inEnglishWord) {
            count++; // Count start of English word
            inEnglishWord = true;
        }
    }
    
    return count;
}

function isChinese(char) {
    const regex = /[\u4e00-\u9fa5]/;
    return regex.test(char);
}