import React from 'react';

const SectionTitle = ({ title }) => {
    const styles = {
        container: {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
        },
        line: {
            flex: 1,
            borderBottom: '1px solid #686865',
            margin: '0 10px',
        },
        title: {
            margin: 0,
            color: '#191919',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: 'Alibaba PuHuiTi Regular',
            fontSize: '20px',
        },
    };

    return (
        <div style={styles.container}>
            <span style={styles.line}></span>
            <h2 style={styles.title}>{title}</h2>
            <span style={styles.line}></span>
        </div>
    );
};

export default SectionTitle;
