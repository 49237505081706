import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { Box, CardActionArea } from '@mui/material';
import { STATUS_COMPLETED, STATUS_GENERATING, STATUS_INPROGRESS } from '../../constants/flow';
import { getFlowTypeIcon } from '../../utils/assets';

function StatusLabel({ status, statusDisplay }) {
  switch (status) {
    case STATUS_INPROGRESS:
      return <Chip label={statusDisplay} size='small' sx={{ borderRadius: '4px', bgcolor: '#BB9681', color: '#FFFFFF' }} />;
    case STATUS_GENERATING:
      return <Chip label={statusDisplay} size='small' sx={{ borderRadius: '4px', bgcolor: '#BCA6AA', color: '#FFFFFF' }} />;
    case STATUS_COMPLETED:
      return <Chip label={statusDisplay} color="success" size='small' sx={{ borderRadius: '4px' }} />;
    default:
      return <Chip label={'Unknown'} color="primary" size='small' sx={{ borderRadius: '4px' }} />;
  };
}

function FlowCardBanner({ flowType, flowTypeDisplay }) {
  if (flowType === undefined) {
    return null;
  }

  return <Box>
    <Typography variant="subtitle2" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
      <img src={getFlowTypeIcon({ flowType: flowType })} alt='' style={{ width: 32, height: 32, marginRight: 15, padding: 3 }} />
      <Box sx={{
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        maxWidth: '100%',
        lineHeight: 1.02,
      }}>
        {flowTypeDisplay ? flowTypeDisplay : 'Unknown type'}
      </Box>
    </Typography>
  </Box>;
}

export default function FlowCard({ flowCard }) {
  const navigate = useNavigate();
  const handleCardClick = (event) => {
    if (event.metaKey || event.ctrlKey)
      return; // If the user is using keyboard shortcut, let the default browser behavior happen
    
    event.preventDefault();
    navigate(flowCard.url);
  };

  return (
    <Card sx={{
      width: '245px',
      height: '240px',
      borderRadius: '20px',
      boxShadow: 'none'
    }}>
      <CardActionArea href={flowCard.url} component="a" onClick={handleCardClick} sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        padding: '20px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '20px',
        background: '#FFF',
      }}>
        <FlowCardBanner flowType={flowCard.type} flowTypeDisplay={flowCard.typeDisplay} />
        <Typography
          variant="h6"
          component="div"
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2, // Limit text to two lines
            height: '58px',
            width: '100%',
          }}
        >
          {flowCard.title}
        </Typography>
        <Typography variant='caption'>
          {new Date(flowCard.time).toLocaleString('zh')}
        </Typography>
        <Box>
          <StatusLabel status={flowCard.status} statusDisplay={flowCard.statusDisplay} />
        </Box>
      </CardActionArea>
    </Card>
  );
}