import * as React from "react";
import { Box, Button, Typography, Divider, FormControl, Select, MenuItem, Card, IconButton } from "@mui/material";
import authFetch from '../../utils/auth';
import { getIcon } from "../../utils/assets";
import { DIALOG_TYPE_CONFIRM, DIALOG_TYPE_INFO } from "../../constants/dialog";
import { useDialog } from "../../context/dialogProvider";

export default function PricingSubslide({ card, isLoggedIn, openSignInDialog, texts }) {
    const { openDialog } = useDialog();
    const [currency, setCurrency] = React.useState('USD');
    const [referralCode, setReferralCode] = React.useState(null);
    const [selectedOption, setSelectedOption] = React.useState(null);

    const handleCurrencyChange = (event) => {
        setCurrency(event.target.value);
    }

    const handleRadioChecked = (option) => {
        setSelectedOption(option.productID);
    }

    const handleCheckout = (productID, currency) => {
        if (!isLoggedIn) {
            openSignInDialog();
        } else {
            authFetch(`/payment/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ productID: productID, currency: currency })
            })
            .then(res => {
                window.location.href = res.url;
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    const handleReferralCodeGeneration = () => {
        authFetch('/user/gen-referral-code', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(data => {
                setReferralCode(data.referralCode);
            })
            .catch(error => {
                console.error('Error:', error);
                openDialog(DIALOG_TYPE_CONFIRM, {
                    title: error.errorTitle,
                    description: error.errorsInfo[0].message,
                    confirmBtn: error.btnTexts ? error.btnTexts.confirmBtn : 'Confirm',
                }, () => { window.location.href = error.redirectURL });
            });
    }

    React.useEffect(() => {
        if (card.checkout) {
            setSelectedOption(card.productOptions[0].productID);
        }
    }, [card]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'center',
                gap: '20px',
                alignSelf: 'stretch',
                pt: 10,
            }}
        >
            <Box>
                <IconButton onClick={() => window.location.href = '/pricing'} >
                    <img src={getIcon('icon-back')} alt={card.title} />
                </IconButton>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    width: '1100px',
                    alignItems: 'flex-start',
                    gap: '70px',
                    alignSelf: 'stretch',
                }}
            >
                <Card sx={{
                    display: 'flex',
                    width: '350px',
                    padding: '40px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '10px',
                    borderRadius: '24px',
                    border: '1px solid #F0F0EB',
                    bgcolor: 'bg_dark.main',
                    '&:hover': {
                        boxShadow: 5,
                    },
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: '10px',
                            alignSelf: 'stretch',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                alignSelf: 'stretch',
                                fontFamily: '"Alibaba PuHuiTi"',
                            }}
                        >
                            {card.title}
                        </Typography>
                        <Box>
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    alignSelf: 'stretch',
                                    color: 'subtitle.secondary',
                                }}
                            >
                                {card.subslideDescription}
                            </Typography>
                        </Box>
                    </Box>
                    <Divider variant='fullWidth' />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: '10px',
                            alignSelf: 'stretch',
                            justifyContent: 'space-between',
                            flexGrow: 1, // this is the key to make the card grow to fill the space
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: '15px',
                                alignSelf: 'stretch',
                                justifyContent: 'flex-end',
                                flexGrow: 1, // this is the key to make the card grow to fill the space
                            }}
                        >
                            <Box sx={{
                                mt: '10px',
                                mb: '10px',
                                width: '100%',
                                color: 'subtitle.secondary',
                            }}>
                                <Divider />
                            </Box>
                            <Typography
                                variant="subtitle1"
                                color={'subtitle.secondary'}
                            >
                                {card.supportedFeaturesTitle}
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                maxWidth: '100%',
                                flexWrap: 'wrap',
                                gap: '10px',
                            }}>
                                {Object.keys(card.features).map((feature, index) => (
                                    <Box key={index} sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                    }}>
                                        {card.features[feature].enabled ? <img src={getIcon('icon-check')} alt={card.features[feature].displayText} /> : <img src={getIcon('icon-x')} alt={card.features[feature].displayText} />}
                                        <Typography variant="subtitle2" color={'subtitle.secondary'}>
                                            {card.features[feature].displayText}
                                        </Typography>
                                    </Box>

                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Card>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '20px',
                        flex: '1 0 auto',
                        alignSelf: 'stretch',
                    }}
                >
                    {card.productOptions.map((option, index) => {
                        if (card.checkout) {
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        padding: '20px 30px',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '20px',
                                        alignSelf: 'stretch',
                                        borderRadius: '20px',
                                        border: '1px solid #F0F0EB',
                                        background: '#FFF',
                                        boxShadow: '0 10px 40px 0 rgba(0, 0, 0, 0.1)',
                                        '&:hover': {
                                            boxShadow: 5,
                                            cursor: 'pointer',
                                        },
                                    }}
                                    onClick={() => handleRadioChecked(option)}
                                    key={index}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                        {selectedOption && selectedOption === option.productID ?
                                            <img src={getIcon('radio-checked')} alt={option.pointsText} /> : <img src={getIcon('radio')} alt={option.pointsText} />}
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                        flex: '1 0 0',
                                    }}>
                                        <Typography variant="h6" color={'primary.main'}>
                                            {option.allPriceTexts[currency]}
                                        </Typography>

                                        {option.discount && (
                                            <Typography variant="subtitle2" color="error">
                                                {option.discount}
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box>
                                        <Typography variant="subtitle2" color={'subtitle.secondary'}>
                                            {option.pointsText}
                                        </Typography>
                                    </Box>
                                </Box>
                            )
                        } else {
                            return (
                                <Box key={index} sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center' }}>
                                    {referralCode && (
                                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center' }}>
                                            <Typography variant="h4" sx={{ textAlign: 'center' }} color='primary'>
                                                {referralCode}
                                            </Typography>
                                            <Typography variant="subtitle2" sx={{ textAlign: 'left', mt: 4 }}>
                                                {option.instructions}
                                            </Typography>
                                        </Box>
                                    )}
                                    {!referralCode && (
                                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center' }}>
                                            <Button variant="contained" color="primary" onClick={() => handleReferralCodeGeneration()} sx={{
                                                justifyContent: "center",
                                                borderRadius: '10px',
                                            }}>
                                                <Typography variant="h7" color={'primary.contrastText'}>
                                                    {option.defaultPriceText}
                                                </Typography>
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            )
                        }
                    })}
                    <Box sx={{
                        width: '100%',
                        color: 'subtitle.secondary',
                    }}>
                        <Divider />
                    </Box>
                    {card.productOptions.length > 1 && (
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '20px',
                            width: '100%',
                            height: 'auto',
                        }}>
                            <FormControl size='small' sx={{
                                mt: 2,
                            }}>
                                <Select
                                    labelId="currency-type-select-label"
                                    id="currency-type-select"
                                    value={currency}
                                    onChange={handleCurrencyChange}
                                    sx={{
                                        border: '1px solid #F0F0EB',
                                    }}
                                >
                                    {Object.entries(card.currencies).map(([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                            <Typography variant="subtitle2" color={'subtitle.secondary'}>
                                                {value}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Box>
                                <Button variant="contained" color="secondary" onClick={() => handleCheckout(selectedOption, currency)} sx={{
                                    display: 'flex',
                                    width: '210px',
                                    padding: '16px 36px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '8px',
                                    borderRadius: '20px',
                                    background: '#D97757',
                                }}>
                                    <Typography variant="h7" color='primary.contrastText'>
                                        {card.checkoutText}
                                    </Typography>
                                    <img src={getIcon('arrow-right')} alt='arrow-right' />
                                </Button>
                            </Box>
                        </Box>
                    )}
                    <Box sx={{
                        width: '100%',
                    }}>
                        <Button variant='outlined' color='primary'
                            onClick={
                                () => openDialog(DIALOG_TYPE_INFO, {
                                    title: texts.freeProgramTitle,
                                    description: texts.freeProgramDescription,
                                    cancelBtn: texts.freeProgramActionText,
                                })}>
                            {texts.freeProgramButtonText}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
