import React from 'react';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function AskQuestionBtn({ landingDisplay, handleBtnClick }) {
    return (
        <Button variant='contained' onClick={() => handleBtnClick('contact_us_btn')} sx={{
            width: { xs: '180px', md: '250px' },
            height: { xs: '60px', md: '70px' },
            borderRadius: '20px',
            boxShadow: '0 0 0 0',
            fontWeight: 400,
            fontSize: { xs: '15px', md: '18px' },
            gap: '10px',
            bgcolor: 'bg_bottom.main',
            '&:hover': {
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
            }
        }}>
            {landingDisplay.askQuestionBtnText}
            <ArrowForwardIcon />
        </Button>
    )
}