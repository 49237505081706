import React from 'react';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import SectionsFlowDisplay from './sectionsFlowDisplay';
import { useInternal } from '../../../context/internalProvider';

function FlowConfigPreview({ flowConfig, onEdit, onCreate, onEditGeneration }) {
  const { sections, questions } = useInternal();
  return (
    <Paper elevation={3} sx={{ p: 2, ml: 3, mt: 2, mb: 2, mr: 3, overflow: 'auto' }}>
      <Stack direction="row" display="flex" justifyContent="center" >
        <Typography variant="h5" sx={{ mt: 2 }} >{flowConfig.id}</Typography>
      </Stack>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
          Flow content
        </Typography>
      </Box>
      <SectionsFlowDisplay startingSectionID={flowConfig.startingSectionID} sections={sections} questions={questions} />
      {onEdit && <Button onClick={onEdit} variant="contained" color="primary" sx={{ mt: 2 }}>
        Edit Flow Config
      </Button>}
      {onCreate && <Button onClick={onCreate} variant="contained" color="primary" sx={{ mt: 2, ml: 3 }}>
        Create Flow
      </Button>}
      {onEditGeneration && <Button onClick={onEditGeneration} variant="contained" color="primary" sx={{ mt: 2, ml: 3 }}>
        Edit Flow Generation
      </Button>}
    </Paper>
  );
}

export default FlowConfigPreview;
