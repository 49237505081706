import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Question from './question';
import authFetch from '../../utils/auth';
import { ACTION_ID_EDIT, ACTION_ID_FINISH, ACTION_ID_NEXT, ACTION_ID_PAY, ACTION_ID_PREV, ACTION_ID_PROCEED, ACTION_ID_REVIEW, ACTION_ID_SAVE } from '../../constants/flow';
import { KeyValueList } from '../dataDisplay/keyValueList';
import { QTYPE_INFO_INSERT, QTYPE_QUESTION_GROUP, QTYPE_TEXT_INPUT, TEXT_INPUT_PARAMETERS } from '../../constants/question';
import { countWordsAndChineseCharacters } from '../../utils/questionnaire/wordcount';
import { BackDropLoading } from '../loading/backDropLoading';
import { useDialog } from '../../context/dialogProvider';

function Questionnaire({ flowid, readOnly, env }) {
  const { showSuccessMessage } = useDialog();
  const [infoList, setInfoList] = useState({});
  const [sectionTitle, setSectionTitle] = useState('');
  const [questionDisplays, setQuestionDisplays] = useState([]);
  const [actions, setActions] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [openDataViewer, setOpenDataViewer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  // Validation for a single basic question
  const validateQuestionBasic = (qd, answer) => {
    let isValid = true;
    let errorMessage = '';

    // Mandatory check
    if (qd.isMandatory && (!answer || answer.trim() === '')) {
      isValid = false;
      errorMessage = errorMessages.mandatory;
    }

    if (qd.type === QTYPE_TEXT_INPUT) {
      const wordCount = countWordsAndChineseCharacters(answer);
      const maxWordCount = TEXT_INPUT_PARAMETERS[qd.textInputType].maxWordCount;
      if (wordCount > maxWordCount) {
        isValid = false;
        errorMessage = errorMessages.wordCount;
      }
    }

    return { isValid, errorMessage };
  };

  // Validation for a group of questions (including insertable groups)
  const validateGroupQuestion = (groupQuestion, groupAnswers) => {
    const errors = {};

    groupAnswers.forEach((instance, index) => {
      groupQuestion.childQuestionDisplays.forEach(cqd => {
        const answer = instance[cqd.id];
        const { isValid, errorMessage } = validateQuestionBasic(cqd, answer);
        if (!isValid) {
          if (!errors[index]) {
            errors[index] = {};
          }
          errors[index][cqd.id] = errorMessage;
        }
      });
    });

    return errors;
  };

  const handleValidationBeforeAction = (action) => {
    const newValidationErrors = {};

    questionDisplays.forEach(qd => {
      if (qd.type === QTYPE_INFO_INSERT) {
        // Assuming 'insertableGroup' identifies your insertable group questions
        const groupAnswers = JSON.parse(infoList[qd.id] || '[]');
        const errors = validateGroupQuestion(qd, groupAnswers);
        if (Object.keys(errors).length > 0) {
          newValidationErrors[qd.id] = errors;
        }
      } else if (qd.type === QTYPE_QUESTION_GROUP) {
        qd.childQuestionDisplays.forEach(cqd => {
          const answer = infoList[cqd.id] || '';
          const { isValid, errorMessage } = validateQuestionBasic(cqd, answer);
          if (!isValid) {
            newValidationErrors[cqd.id] = errorMessage;
          }
        })
      } else {
        // Simple questions
        const answer = infoList[qd.id] || '';
        const { isValid, errorMessage } = validateQuestionBasic(qd, answer);
        if (!isValid) {
          newValidationErrors[qd.id] = errorMessage;
        }
      }
    });

    setValidationErrors(newValidationErrors);

    // Check if there are any errors before proceeding
    if (Object.keys(newValidationErrors).length === 0) {
      action();
    }
  };

  useEffect(() => {
    if (readOnly) {
      getInfoPage(flowid, env);
    } else {
      getCurrentPage(flowid);
    }
  }, [flowid, readOnly, env]);

  const handleAnswerChange = (questionId, newValue) => {
    setInfoList(prev => ({ ...prev, [questionId]: newValue }));
  };

  function processPage(data) {
    setSectionTitle(data.sectionTitle);
    setQuestionDisplays(data.questionDisplays);
    setActions(data.actions);
    setInfoList(data.infoList);
    setIsAdmin(data.isAdmin);
    setErrorMessages(data.errorMessages);
    setLoading(false);
  }

  function getInfoPage(flowid, env) {
    setLoading(true);
    authFetch('/flow/get-info-page', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ flowid: flowid, env: env }),
    })
      .then((data) => {
        console.log('getInfoPage')
        setSectionTitle(data.sectionTitle);
        setQuestionDisplays(data.questionDisplays);
        setActions(data.actions);
        setInfoList(data.infoList);
        setIsAdmin(data.isAdmin);
        setErrorMessages(data.errorMessages);
        setLoading(false);
      })
      .catch((error) => console.error("There was an error!", error));
  }

  function getCurrentPage(flowid) {
    setLoading(true);
    authFetch('/flow/get-current-page', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ flowid: flowid }),
    })
      .then((data) => {
        console.log('getCurrentPage')
        setSectionTitle(data.sectionTitle);
        setQuestionDisplays(data.questionDisplays);
        setActions(data.actions);
        setInfoList(data.infoList);
        setIsAdmin(data.isAdmin);
        setErrorMessages(data.errorMessages);
        setLoading(false);
      })
      .catch((error) => console.error("There was an error!", error));
  }

  function getNextPage() {
    setLoading(true);
    authFetch('/flow/get-next-page', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ flowid: flowid, infoList: infoList }),
    })
      .then((data) => {
        console.log('getNextPage')
        processPage(data);
      })
      .catch((error) => console.error("There was an error!", error));
  }

  function getPrevPage() {
    setLoading(true);
    authFetch('/flow/get-prev-page', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ flowid: flowid, infoList: infoList }),
    })
      .then((data) => {
        console.log('getPrevPage')
        processPage(data);
      })
      .catch((error) => console.error("There was an error!", error));
  }

  function getReviewPage() {
    setLoading(true);
    authFetch('/flow/get-review-page', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ flowid: flowid, infoList: infoList }),
    })
      .then((data) => {
        console.log('getReviewPage')
        console.log(data)
        processPage(data);
      })
      .catch((error) => console.error("There was an error!", error));
  }

  function save() {
    setLoading(true);
    authFetch('/flow/save', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ flowid: flowid, infoList: infoList }),
    })
      .then((data) => {
        console.log(data)
        setLoading(false);
        showSuccessMessage(data.message);
      })
      .catch((error) => console.error("There was an error!", error));
  }

  function submit() {
    setLoading(true);
    authFetch('/flow/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ flowid: flowid, infoList: infoList }),
    })
      .then((data) => {
        console.log('submit')
        console.log(data)
        window.location.reload();
      })
      .catch((error) => console.error("There was an error!", error));
  }

  function proceed() {
    setLoading(true);
    authFetch('/flow/proceed', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ flowid: flowid, infoList: infoList }),
    })
      .then((data) => {
        console.log('proceed')
        console.log(data)
        window.location.reload();
      })
      .catch((error) => console.error("There was an error!", error));
  }

  function pay() {
    setLoading(true);
    authFetch('/flow/pay', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ flowid: flowid, infoList: infoList }),
    })
      .then((data) => {
        console.log('pay')
        console.log(data)
        window.location.href = data.redirectURL;
      })
      .catch((error) => console.error("There was an error!", error));
  }

  const handleAction = (event) => {
    const actionID = event.target.value;
    switch (actionID) {
      case ACTION_ID_NEXT:
        handleValidationBeforeAction(getNextPage);
        break;
      case ACTION_ID_PREV:
        getPrevPage();
        break;
      case ACTION_ID_SAVE:
        save();
        break;
      case ACTION_ID_REVIEW:
        handleValidationBeforeAction(getReviewPage);
        break;
      case ACTION_ID_EDIT:
        getCurrentPage(flowid);
        break;
      case ACTION_ID_FINISH:
        handleValidationBeforeAction(submit);
        break;
      case ACTION_ID_PROCEED:
        handleValidationBeforeAction(proceed);
        break;
      case ACTION_ID_PAY:
        handleValidationBeforeAction(pay);
        break;
      default:
        break;
    }
  };

  const handleCloseDataViewer = () => {
    setOpenDataViewer(false);
  };

  const handleOpenDataViewer = () => {
    setOpenDataViewer(true);
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
      flex: '1 0 0',
      alignSelf: 'stretch',
      padding: '0px 20px 20px 20px',
    }}>
      {loading ? (
        <BackDropLoading />
      ) : (
        <Box
          component="form"
          onSubmit={(e) => e.preventDefault()}
          sx={{
            display: 'flex',
            width: '70%',
            minWidth: '600px',
            maxWidth: '1000px',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <Typography variant="h5" sx={{ mt: 2, textAlign: 'center' }}>
            {sectionTitle}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mb: 2,
              p: 2,
              width: '100%',
            }}
          >
            {questionDisplays.map(qd => (
              <Question
                key={qd.id}
                questionDisplay={qd}
                infoList={infoList}
                onAnswerChange={handleAnswerChange}
                readOnly={readOnly}
                validationErrors={validationErrors}
                sx={{ mt: 2 }}
              />
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center', // Center align the action buttons
              flexWrap: 'wrap', // Ensures buttons wrap in smaller screens
              gap: '16px', // Space between buttons
              pt: 2, // Padding top for spacing from questions
            }}
          >
            {actions.map(action => (
              <Button
                key={action.id}
                value={action.id}
                onClick={handleAction}
                variant="contained"
                sx={{ mt: 2, ml: 2 }}
              >
                {action.displayText}
              </Button>
            ))}
            {isAdmin && (
              <Button
                onClick={handleOpenDataViewer}
                variant="contained"
                sx={{ mt: 2, ml: 2 }}
                color='secondary'
              >
                Admin
              </Button>
            )}
          </Box>
        </Box>
      )}
      <Dialog open={openDataViewer} onClose={handleCloseDataViewer} fullWidth>
        <DialogTitle>Data Viewer</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDataViewer}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <KeyValueList data={infoList} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Questionnaire;