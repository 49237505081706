import React from 'react';
import { Typography, Box } from '@mui/material';
import MarkdownParser from '../dataDisplay/markdown';

function QuestionInstructionOnly({ questionDisplay }) {
  return (
    <Box sx={{
      p: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    }}>
      <Typography variant="subtitle2" style={{ display: "inline-block", whiteSpace: "pre-line" }}>
        {questionDisplay.isMandatory && <span style={{ color: 'red' }}>*</span>}{questionDisplay.instructionDisplay}
      </Typography>
      {questionDisplay.hasStaticContent && (
        <MarkdownParser text={questionDisplay.staticContentDisplay}/>
      )}
    </Box>
  );
}

export default QuestionInstructionOnly;
