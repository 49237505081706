import React, { useState, useEffect, createContext, useContext } from 'react';
import authFetch from '../utils/auth';

const InternalContext = createContext();

export const useInternal = () => useContext(InternalContext);

const InternalProvider = ({ children }) => {

  const [questions, setQuestions] = useState([]);
  const [sections, setSections] = useState([]);
  const [flowConfigs, setFlowConfigs] = useState([]);
  const [prompts, setPrompts] = useState([]);
  const [generations, setGenerations] = useState([]);

  const fetchQuestions = () => {
    authFetch('/dev/list-questions')
      .then((data) => {
        setQuestions(data.questions);
      })
      .catch((error) => console.error("There was an error!", error));
  };

  const fetchSections = () => {
    authFetch('/dev/list-sections')
      .then((data) => {
        setSections(data.sections);
      })
      .catch((error) => console.error("There was an error!", error));
  };

  const fetchFlowConfigs = () => {
    authFetch('/dev/list-flowconfigs')
      .then((data) => {
        setFlowConfigs(data.flowConfigs);
      })
      .catch((error) => console.error("There was an error!", error));
  };

  const fetchPrompts = () => {
    authFetch('/dev/list-prompts')
      .then((data) => {
        setPrompts(data.prompts);
      })
      .catch((error) => console.error("There was an error!", error));
  };

  const fetchGenerations = () => {
    authFetch('/dev/list-generations')
      .then((data) => {
        setGenerations(data.generations);
      })
      .catch((error) => console.error("There was an error!", error));
  };

  useEffect(() => {
    fetchQuestions();
    fetchSections();
    fetchFlowConfigs();
    fetchPrompts();
    fetchGenerations();
  }, []);

  return (
    <InternalContext.Provider value={{ questions, sections, flowConfigs, prompts, generations, fetchQuestions, fetchSections, fetchFlowConfigs, fetchPrompts, fetchGenerations }}>
      {children}
    </InternalContext.Provider>
  );

}

export default InternalProvider;
