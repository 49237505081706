import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const JsonViewer = ({ data }) => {
  // Function to render data with the assumption that all fields should be expanded by default
  const renderData = (data, level = 0) => {
    if (Array.isArray(data)) {
      return data.map((item, index) => (
        <Accordion key={index} elevation={level} defaultExpanded>
          <AccordionSummary expandIcon={typeof item === 'object' ? <ExpandMoreIcon /> : null}>
            <Typography component="span" color="primary.main">Index: {index}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {typeof item === 'object' && item !== null ? renderData(item, level + 1) : <Typography component="span" sx={{ whiteSpace: 'pre-line' }}>{item.toString()}</Typography>}
          </AccordionDetails>
        </Accordion>
      ));
    } else {
      return Object.entries(data).map(([key, value]) => (
        <Accordion key={key} elevation={level}>
          <AccordionSummary expandIcon={typeof value === 'object' ? <ExpandMoreIcon /> : null}>
            <Typography component="span" color="primary.main" style={{ flexGrow: 0 }}>{key}: </Typography>
            {typeof value === 'object' ? null : <Typography component="span" style={{ marginLeft: '5px', whiteSpace: 'pre-line' }}>{value.toString()}</Typography>}
          </AccordionSummary>
          {typeof value === 'object' && (
            <AccordionDetails>
              {value !== null ? renderData(value, level + 1) : null}
            </AccordionDetails>
          )}
        </Accordion>
      ));
    }
  };

  return (
    <div style={{ margin: '20px' }}>
      {renderData(data)}
    </div>
  );
};

export default JsonViewer;
