import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Box, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import videoStand from '../../assets/videos/stand_fall.mp4';
import videoPray from '../../assets/videos/pray_fall.mp4';

const Pray = ({ onClose, displayText, showAction }) => {
  const [currentVideo, setCurrentVideo] = useState('stand');

  const handleNextVideo = () => {
    setCurrentVideo('pray');
  };

  const handleVideoEnd = (video) => {
    if (video === 'pray') {
      onClose(true);
    }
  };

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <ReactPlayer
        url={videoStand}
        playing={currentVideo === 'stand'}
        loop
        width="100%"
        height="400px"
        muted
        onEnded={() => handleVideoEnd('stand')}
        style={{ display: currentVideo === 'stand' ? 'block' : 'none' }}
        playsInline
        config={{ file: { attributes: { autoPlay: true, controls: false } } }}
      />
      <ReactPlayer
        url={videoPray}
        playing={currentVideo === 'pray'}
        width="100%"
        height="400px"
        muted
        onEnded={() => handleVideoEnd('pray')}
        style={{ display: currentVideo === 'pray' ? 'block' : 'none' }}
        playsInline
        config={{ file: { attributes: { autoPlay: true, controls: false } } }}
      />
      <IconButton
        onClick={onClose}
        sx={{ position: 'absolute', top: 8, right: 8, color: 'white' }}
      >
        <CloseIcon />
      </IconButton>
      {showAction && (
        <Button
          onClick={handleNextVideo}
          variant="contained"
          color="primary"
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
        >
          {displayText}
        </Button>
      )}
    </Box>
  );
};

export default Pray;