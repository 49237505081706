import React from 'react';
import { Box, Typography } from '@mui/material';
import contactEmailIcon from '../../assets/icons/contactEmailIcon.png';
import contactAddressIcon from '../../assets/icons/contactAddressIcon.png';
import ContactForm from './contactForm';
import bottomImage from '../../assets/images/river_city.jpg';
import BottomBar from '../bottomBar/bottomBar';
import authFetch from '../../utils/auth';


function ContactDisplay() {
  const [contactDisplay, setContactDisplay] = React.useState({});
  React.useEffect(() => {
    authFetch('/public/get-contact-display')
      .then((data) => {
        setContactDisplay(data);
      })
      .catch((error) => console.error("There was an error!", error));
  }, []);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'stretch',
      height: 'calc(100vh - 64px)',
    }}>
      <Box sx={{
        display: 'flex',
        paddingTop: '60px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
        alignSelf: 'stretch',
      }}>
        <Typography variant="h5">
          {contactDisplay.titleText}
        </Typography>
        <Typography variant="caption">
        {contactDisplay.descriptionText}
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        padding: '80px 0 170px 0',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '100px',
        flex: '1 0 auto',
        alignSelf: 'stretch',
      }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '100px',
          width: '1000px',
        }}>
          <Box sx={{
            display: 'flex',
            width: '400px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '40px',
            flexShrink: 0,
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              alignSelf: 'stretch',
            }}>
              <img src={contactEmailIcon} alt="contactEmail" style={{ width: 60, height: 60 }} />
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '2px',
                flex: '1 0 0',
              }}>
                <Typography variant="body2">
                  {contactDisplay.companyEmailTitle}
                </Typography>
                <Typography variant="caption_small">
                  {contactDisplay.companyEmailContent}
                </Typography>
              </Box>
            </Box>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              alignSelf: 'stretch',
            }}>
              <img src={contactAddressIcon} alt="contactAddress" style={{ width: 60, height: 60 }} />
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '2px',
                flex: '1 0 0',
              }}>
                <Typography variant="body2">
                  {contactDisplay.companyAddressTitle}
                </Typography>
                <Typography variant="caption_small">
                  {contactDisplay.companyAddressContent}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '12px',
            alignSelf: 'stretch',
          }}>
            {contactDisplay.formDisplay && <ContactForm formDisplay={contactDisplay.formDisplay} />}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: 354,
          width: '100%',
          flexShrink: 0,
          alignSelf: 'stretch',
          backgroundImage: `url(${bottomImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
      </Box>
      <BottomBar />
    </Box>
  )
}

export default ContactDisplay;