import React, { useEffect, useRef, useState } from 'react';
import { Typography, MenuItem, Select, FormControl, TextField, Box, Button, Collapse, Checkbox } from '@mui/material';
import { QTYPE_MULTI_SELECT, QTYPE_SINGLE_SELECT, QTYPE_TEXT_INPUT, TEXT_INPUT_PARAMETERS, TEXT_TYPE_SHORT } from '../../constants/question';
import authFetch from '../../utils/auth';
import { countWordsAndChineseCharacters } from '../../utils/questionnaire/wordcount';
import HelpIcon from '@mui/icons-material/Help';
import UploadFileIcon from '@mui/icons-material/UploadFile';

function QuestionSimple({ questionDisplay, answer, onAnswerChange, readOnly, errorMessage }) {
  const fileInputRef = useRef(null);
  const [hintOpen, setHintOpen] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [maxWordCount, setMaxWordCount] = useState(10);
  const [mutiSelectAnswers, setMutiSelectAnswers] = useState([]);
  const [selectedText, setSelectedText] = useState("");

  useEffect(() => {
    if (questionDisplay.type === QTYPE_TEXT_INPUT) {
      setMaxWordCount(TEXT_INPUT_PARAMETERS[questionDisplay.textInputType].maxWordCount);
    }
    if (questionDisplay.type === QTYPE_TEXT_INPUT && answer !== undefined) {
      setWordCount(countWordsAndChineseCharacters(answer));
    }
    if (questionDisplay.type === QTYPE_SINGLE_SELECT && answer !== undefined) {
      setWordCount(countWordsAndChineseCharacters(answer));
      if (questionDisplay.showTextWhenSelected && questionDisplay.options) {
        questionDisplay.options.forEach(option => {
          if (option.value === answer) {
            setSelectedText(option.textDisplay);
          }
        });
      }
    }
    if (questionDisplay.type === QTYPE_MULTI_SELECT && answer) {
      setMutiSelectAnswers(answer.split(','));
    }
  }, [answer, questionDisplay]);

  const handleChange = (event) => {
    const val = event.target.value;
    if (onAnswerChange) {
      if (questionDisplay.type === QTYPE_MULTI_SELECT) {
        onAnswerChange(questionDisplay.id, val.toString());
      } else {
        onAnswerChange(questionDisplay.id, val);
      }
    }
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('file', event.target.files[0]);

    try {
      const response = await authFetch('/action/upload-file', {
        method: 'POST',
        body: formData,
      });
      if (onAnswerChange) {
        const val = answer ? answer + response.content : response.content;
        onAnswerChange(questionDisplay.id, val);;
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }

    fileInputRef.current.value = "";
  };

  return (
    <Box sx={{
      mt: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    }}>
      <Box sx={{
        display: 'flex',
        gap: '10px',
        alignSelf: 'stretch',
      }}>
        <Box sx={{
          display: 'flex',
          padding: '10px 15px',
          alignItems: 'center',
          gap: '10px',
          borderRadius: '20px',
          backgroundColor: 'bg_skin.main',
        }}>
          <Typography variant="body1" sx={{ fontWeight: 'bold', lineHeight: '20px' }} style={{ display: "inline-block", whiteSpace: "pre-line" }}>
            {questionDisplay.isMandatory && <span style={{ color: 'red' }}>*</span>}{questionDisplay.instructionDisplay}
          </Typography>
        </Box>
        {!readOnly && questionDisplay.hasHint && (
          <HelpIcon
            onClick={() => setHintOpen(!hintOpen)}
            aria-expanded={hintOpen}
            variant="text"
            fontSize='small'
            color="primary"
            sx={{
              alignSelf: 'center',
            }}
          />
        )}
      </Box>
      <Collapse in={hintOpen} sx={{ ml: 1 }}>
        <Typography variant="caption_small" color="text.secondary" style={{ display: "inline-block", whiteSpace: "pre-line" }}>
          {questionDisplay.hintDisplay}
        </Typography>
      </Collapse>
      <Box sx={{
        display: 'flex',
        padding: '30px 30px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '20px',
        alignSelf: 'stretch',
        borderRadius: '20px',
        backgroundColor: 'bg_dark.main',
      }}>
        {questionDisplay.type === QTYPE_SINGLE_SELECT && !questionDisplay.showTextWhenSelected && questionDisplay.options && (
          <FormControl fullWidth>
            <Select
              value={answer || ''}
              onChange={handleChange}
              displayEmpty
              readOnly={readOnly}
            >
              {questionDisplay.options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.textDisplay}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {questionDisplay.type === QTYPE_SINGLE_SELECT && questionDisplay.showTextWhenSelected && questionDisplay.options && (
          <>
            <FormControl fullWidth>
              <Select
                value={answer || ''}
                onChange={handleChange}
                displayEmpty
                readOnly={readOnly}
              >
                {questionDisplay.options.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="subtitle2" style={{ display: "inline-block", whiteSpace: "pre-line" }} sx={{ mt: 1 }}>
              {selectedText}
            </Typography>
            </FormControl>
          </>
        )}
        {questionDisplay.type === QTYPE_MULTI_SELECT && questionDisplay.options && (
          <FormControl fullWidth>
            <Select
              multiple
              value={mutiSelectAnswers || []}
              onChange={handleChange}
              displayEmpty
              readOnly={readOnly}
            >
              {questionDisplay.options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  <Checkbox checked={mutiSelectAnswers.indexOf(option.value) > -1} />
                  {option.textDisplay}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {questionDisplay.type === QTYPE_TEXT_INPUT && (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '5px',
            flex: '1 0 0',
            alignSelf: 'stretch',
          }}>
            {!readOnly && questionDisplay.hasUpload && (
              <Button
                variant="text"
                component="label"
                sx={{
                  padding: '1px 2px',
                  fontSize: '0.8rem',
                  textTransform: 'none',
                }}
              >
                <UploadFileIcon color='primary' fontSize='small' />
                {questionDisplay.uploadBtnDisplay}
                <input
                  type="file"
                  hidden
                  onChange={handleUpload}
                  accept=".txt, application/pdf, .docx"
                  ref={fileInputRef}
                />
              </Button>
            )}
            <TextField
              value={answer || ''}
              onChange={handleChange}
              fullWidth
              multiline={questionDisplay.textInputType !== TEXT_TYPE_SHORT}
              rows={TEXT_INPUT_PARAMETERS[questionDisplay.textInputType].inputRows}
              variant="outlined"
              InputProps={{
                readOnly: readOnly,
              }}
            />
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              {errorMessage ? (
                <Typography color="error" variant="caption_small">{errorMessage}</Typography>
              ) : (
                <Typography variant="caption_small" sx={{ visibility: 'hidden' }}>Placeholder</Typography>
              )}
              <Typography
                variant="caption_small"
                sx={{
                  color: wordCount > maxWordCount ? 'error.main' : 'secondary',
                }}
              >
                {`${questionDisplay.wordCountText}: ${wordCount}/${maxWordCount}`}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default QuestionSimple;
