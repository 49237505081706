import React from 'react';
import { Box, Typography } from '@mui/material';
import logo from '../../assets/logo_white.png';
import { useNavigate } from 'react-router-dom';


function BottomBar() {
  const navigate = useNavigate();
  return (
    <Box sx={{
      display: 'flex',
      height: '120px',
      padding: { xs: '20px 30px', md: '40px 110px' },
      justifyContent: 'space-between',
      alignItems: 'center',
      flexShrink: 0,
      alignSelf: 'stretch',
      bgcolor: 'bg_bottom.main',
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '10px',
        flex: '1 0 0',
      }}>
        <img src={logo} alt='' style={{ verticalAlign: 'middle', width: 100, height: 15.6 }} />
        <Typography variant='caption_small' color='primary.contrastText'>
          2024 © Lyde Technology and Information Services Limited
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '30px',
        flex: '1 0 0',
      }}>
        {/*<Typography variant='caption_small' color='primary.contrastText'>
          About us
        </Typography>*/}
        <Typography variant='caption_small' color='primary.contrastText' sx={{ cursor: 'pointer' }} onClick={() => navigate('/terms')}>
          Terms of services
        </Typography>
        <Typography variant='caption_small' color='primary.contrastText' sx={{ cursor: 'pointer' }} onClick={() => navigate('/privacy')}>
          Privacy policy
        </Typography>
      </Box>
    </Box>
  )
}

export default BottomBar;