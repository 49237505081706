export const STATUS_ALL = 'all'
export const STATUS_INPROGRESS = 'in-progress';
export const STATUS_GENERATING = 'generating';
export const STATUS_COMPLETED = 'completed';

export const FLOW_STATUS_LST = [STATUS_ALL, STATUS_INPROGRESS, STATUS_GENERATING, STATUS_COMPLETED];

export const FLOWTYPE_ALL = 'all';
export const FLOWTYPE_PS = 'personal_statement';
export const FLOWTYPE_PSE = 'personal_statement_evaluation';
export const FLOWTYPE_PSP = 'personal_statement_polish';
export const FLOWTYPE_RL = 'recommendation_letter';
export const FLOWTYPE_RLE = 'recommendation_letter_evaluation';
export const FLOWTYPE_RLP = 'recommendation_letter_polish';
export const FLOWTYPE_SS = 'school_selection';
export const FLOWTYPE_SOP = 'statement_of_purpose';
export const FLOWTYPE_SOPE = 'statement_of_purpose_evaluation';
export const FLOWTYPE_SOPP = 'statement_of_purpose_polish';
export const FLOWTYPE_CV = 'cv_compose';
export const FLOWTYPE_CVE = 'cv_evaluation';
export const FLOWTYPE_CVP = 'cv_polish';
export const FLOWTYPE_ES = 'expert_service';
export const FLOWTYPE_ER = 'expert_review';
export const FLOWTYPE_OOO = 'one_on_one';
export const FLOWTYPE_SSES = 'school_selection_expert_service';
export const FLOWTYPE_RLR = 'recommendation_letter_request';
export const FLOWTYPE_ML = 'motivation_letter';
export const FLOWTYPE_AS = 'application_submission';
export const FLOWTYPE_DEFAULT = "default";

export const FLOWTYPE_LST = [FLOWTYPE_ALL,
    FLOWTYPE_PS, FLOWTYPE_PSE, FLOWTYPE_PSP,
    FLOWTYPE_RL, FLOWTYPE_RLE, FLOWTYPE_RLP,
    FLOWTYPE_CV, FLOWTYPE_CVE, FLOWTYPE_CVP,
    FLOWTYPE_SOP, FLOWTYPE_SS,
    FLOWTYPE_ES, FLOWTYPE_ER, FLOWTYPE_OOO, FLOWTYPE_RLR, FLOWTYPE_ML];

export const ACTION_ID_NEXT = 'NEXT';
export const ACTION_ID_PREV = 'PREV';
export const ACTION_ID_FINISH = 'FINISH';
export const ACTION_ID_SAVE = 'SAVE';
export const ACTION_ID_REVIEW = 'REVIEW';
export const ACTION_ID_EDIT = 'EDIT';
export const ACTION_ID_PROCEED = 'PROCEED';
export const ACTION_ID_PAY = 'PAY';