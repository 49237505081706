import * as React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import Box from '@mui/material/Box';
import HomeAppBar from '../appbar/appBar';

export default function WithAppBarLayout({ content }) {
  const location = useLocation();

  // Define paths that don't need AppBar
  const noAppBarPaths = [
    { path: '/fpdf63457427/:flowid/:template' }
  ];

  // Check if the current path matches any of the noAppBarPaths
  const isNoAppBarPath = noAppBarPaths.some(({ path }) =>
    matchPath(path, location.pathname)
  );

  if (isNoAppBarPath) {
    return <>{content}</>;
  }

  return (
    <Box sx={{
      bgcolor: 'bg1.main',
    }}>
      <HomeAppBar />
      {content}
    </Box>
  );
}
