import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import GenerationPreview from '../internal/generations/generationPreview';
import JsonViewer from '../dataDisplay/jsonDisplay';


export function GenerationViewer({ generation, flowData, prompts }) {
  return (
    <Box>
      <Grid container spacing={2}>
        {generation && (
          <Grid item xs={12} md={5}>
            <Typography variant="h6" sx={{ mb: 2 }}>Generation Plan</Typography>
            <GenerationPreview generation={generation} infoList={flowData.infoList} prompts={prompts} />
          </Grid>
        )}
        {flowData && (
          <Grid item xs={12} md={7}>
            <Typography variant="h6" sx={{ mb: 2 }}>Actual Generation</Typography>
            <Typography variant="body1"><strong>Generation started at:</strong> {new Date(flowData.generationStartedAt).toLocaleString()}</Typography>
            <Typography variant="body1"><strong>Generation completed at:</strong> {new Date(flowData.generationCompletedAt).toLocaleString()}</Typography>
            <JsonViewer data={flowData.gptHistory} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}