// GPT Models
export const GPT_MODELS = [
    'gpt-4o',
    'gpt-4-turbo',
    'gpt-4-turbo-2024-04-09',
    'gpt-4-turbo-preview',
    'gpt-4-1106-preview',
    'gpt-4-0125-preview',
    'gpt-3.5-turbo-16k',
]

export const GPT_PARAMETERS = {
    temperature: {
        label: 'Temperature',
        min: 0,
        max: 2,
        step: 0.01,
        default: 1,
    },
    max_tokens: {
        label: 'Max Tokens',
        min: 1,
        max: 4095,
        step: 1,
        default: 4095,
    },
    top_p: {
        label: 'Top P',
        min: 0,
        max: 1,
        step: 0.01,
        default: 1,
    },
    frequency_penalty: {
        label: 'Frequency Penalty',
        min: 0,
        max: 2,
        step: 0.01,
        default: 0,
    },
    presence_penalty: {
        label: 'Presence Penalty',
        min: 0,
        max: 2,
        step: 0.01,
        default: 0,
    },
}