import { Box, Button, TextField } from "@mui/material";
import { END_TIME, START_TIME } from "../../constants/lydia";

export default function DateTimeFilter({
    env,
    params,
    handleFilterInputChange,
    fetchMetrics,
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 2,
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Box>
                <TextField
                    fullWidth
                    label="Start Time"
                    name={START_TIME}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={params[START_TIME]}
                    onChange={(e) => handleFilterInputChange(e)}
                    variant="outlined"
                />
            </Box>
            <Box>
                <TextField
                    fullWidth
                    label="End Time"
                    name={END_TIME}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={params[END_TIME]}
                    onChange={(e) => handleFilterInputChange(e)}
                    variant="outlined"
                />
            </Box>
            <Box>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => fetchMetrics(env, params)}
                >
                    Apply
                </Button>
            </Box>
        </Box>

    );
}