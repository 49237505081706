import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { getIcon } from "../../utils/assets";
import { PROPERTY_TYPE_DELIVERY_CONTENT, PROPERTY_TYPE_ESTIMATED_DELIVERY_TIME, PROPERTY_TYPE_PRICE, PROPERTY_TYPE_SERVICE } from "../../constants/pricing";

export default function PricingTableSection({ category, property }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center' }}>
            <Paper sx={{
                // position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'bg_label.main',
                width: '150px',
                height: 'auto',
                minHeight: '80px',
                padding: '10px',
                borderRadius: '20px 0 0 20px',
                userSelect: 'none',
                '&.MuiPaper-root': {
                    boxShadow: 0,
                },
                '&:hover': {
                    boxShadow: '0 4px 20px 5px rgba(0, 0, 0, 0.1)'
                },
            }}>
                <Typography variant="subtitle2" sx={{ textAlign: 'center', fontWeight: 700, color: 'bg_label.contrastText' }}>{category.displayText}</Typography>
            </Paper>
            <Paper sx={{
                // position: 'relative',
                bgcolor: 'bg2.main',
                width: `calc(100% - 100px)`,
                height: 'wrap',
                padding: '10px',
                userSelect: 'none',
                '&.MuiPaper-root': {
                    boxShadow: 0,
                },
                '&:hover': {
                    boxShadow: '0 4px 20px 5px rgba(0, 0, 0, 0.1)'
                },
            }}>
                <Box sx={{ padding: '0 40px 0 30px' }}>
                    {category.services.map((service, index) => (
                        <Grid key={index} container sx={{ display: 'flex', alignItems: 'center', minHeight: '50px' }}>
                            <Grid item xs={property[PROPERTY_TYPE_SERVICE].columnWidth} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle2">{service[PROPERTY_TYPE_SERVICE]}</Typography>
                            </Grid>
                            <Grid item xs={property[PROPERTY_TYPE_PRICE].columnWidth} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {service[PROPERTY_TYPE_PRICE].icon && (
                                    <img src={getIcon(service[PROPERTY_TYPE_PRICE].icon)} height={'15px'} alt='coins'></img>
                                )}
                                <Typography variant="subtitle2" 
                                    color={service[PROPERTY_TYPE_PRICE].icon ? '' : 'primary.main'} 
                                    sx={{ fontWeight: service[PROPERTY_TYPE_PRICE].icon ? '400' : '700' }}> {service[PROPERTY_TYPE_PRICE].displayText}</Typography>
                            </Grid>
                            <Grid item xs={property[PROPERTY_TYPE_ESTIMATED_DELIVERY_TIME].columnWidth} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle2" sx={{ maxWidth: '72%' }}>{service[PROPERTY_TYPE_ESTIMATED_DELIVERY_TIME]}</Typography>
                            </Grid>
                            <Grid item xs={property[PROPERTY_TYPE_DELIVERY_CONTENT].columnWidth} sx={{ display: 'flex', flexDirection: 'column' }} gap={'5px'}>
                                {service[PROPERTY_TYPE_DELIVERY_CONTENT].map((content, index) => (
                                    <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left', gap: '8px' }}>
                                        {content.icon && <img src={getIcon(content.icon)} height={'15px'} width={'15px'} alt='icon' />}
                                        <Typography variant="subtitle2" sx={{ lineHeight: 1.5 }}>
                                            {content.displayText}
                                        </Typography>
                                    </Box>
                                ))}
                            </Grid>
                            {index < category.services.length - 1 && (
                                <Grid item xs={12}><Divider /></Grid>
                            )}
                        </Grid>
                    ))}
                </Box>
            </Paper>
        </Box>
    )
}