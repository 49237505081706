import React from 'react';
import { Button, Paper, Typography, Chip, Box, Stack } from '@mui/material';
import StepDisplay from './stepDisplay';

function GenerationPreview({ generation, onEdit, infoList, prompts }) {
  return (
    <Paper elevation={3} sx={{ p: 2, ml: 3, mt: 2, mb: 2, mr: 3, overflow: 'auto' }}>
      <Stack direction="row" display="flex" justifyContent="center" >
        <Typography variant="h6" sx={{ mt: 2, mb: 2 }} >{generation.id}</Typography>
      </Stack>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
        <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
          Associated Flow
        </Typography>
        <Chip label={generation.flowConfigID} color="primary" variant="outlined" size="small" sx={{ fontWeight: 'bold', ml: 3 }} />
      </Box>
      {generation.steps.map((step, index) => (
        <Box key={`step-${index}`}>
          <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
            Step {index + 1}
          </Typography>
          <StepDisplay step={step} infoList={infoList} prompts={prompts}/>
        </Box>
      ))}
      {onEdit && <Button onClick={onEdit} variant="contained" color="primary" sx={{ mt: 2 }}>
        Edit Flow Config
      </Button>}
    </Paper>
  );
}

export default GenerationPreview;
