import { useEffect, useState } from 'react';
import { Backdrop, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../../context/authProvider';

const MobileAccessHandler = () => {
	const [isBackdropOpen, setIsBackdropOpen] = useState(false);
	const { isMobileAccess } = useAuth();

	useEffect(() => {
		setIsBackdropOpen(isMobileAccess);
	}, [isMobileAccess]);

	return (
		<Backdrop open={isBackdropOpen} sx={{
			flexDirection: 'column',
			zIndex: 2000,
			backgroundColor: 'rgba(0, 0, 0, 0.9)',
		}}>
			<Grid sx={{
				position: 'absolute',
				right: 5,
				top: 5,
			}}>
				<IconButton onClick={() => setIsBackdropOpen(false)}>
					<CloseIcon sx={{ color: 'white' }} />
				</IconButton>
			</Grid>
			<Grid sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				ml: 5,
				mr: 5,
			}}>
				<Typography variant='h6' color={"white"}>
					为更好的使用体验，请使用电脑端访问。
				</Typography>
				<Typography variant='h6' color={"white"}>
					For the best experience, please access the site using a desktop computer.
				</Typography>
			</Grid>
		</Backdrop >
	)
};

export default MobileAccessHandler;
