import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function RenameDocDialog({ open, handleClose, handleConfirm, content }) {
  const [newDocName, setNewDocName] = React.useState(content.docName);
  const handleNewDocNameChange = (event) => {
    setNewDocName(event.target.value);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '640px',
          maxWidth: 'none',
          padding: '5px 0px 10px 0px',
        }
      }}
    >
      <DialogTitle>{content.title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          required
          margin="dense"
          id="new-doc-name"
          name="newDocName"
          // label="New Document Name"
          fullWidth
          variant="standard"
          placeholder={content.docName.toString()}
          onChange={handleNewDocNameChange}
        />
        <DialogContentText sx={{
          fontSize: '0.8em',
        }}>
          {content.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{ boxShadow: 'none' }} onClick={() => handleClose()}>{content.cancelBtn}</Button>
        <Button sx={{ boxShadow: 'none' }} variant='contained' onClick={() => handleConfirm([newDocName])}>{content.confirmBtn}</Button>
      </DialogActions>
    </Dialog>
  );
}