import * as React from 'react';
import authFetch from '../utils/auth';
import { fetchDialogInfo } from '../utils/action';
import { useDialog } from './dialogProvider';
import { DIALOG_TYPE_CONFIRM, DIALOG_TYPE_CONSUMPTION_CONFIRM, DIALOG_TYPE_PRAY } from '../constants/dialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { CV_COMPOSE_SERVICE, CV_EVALUATION_SERVICE, CV_POLISH_SERVICE, EXPERT_CUSTOMIZED_SERVICE, EXPERT_REVIEW_SERVICE, MEDITATE_SERVICE, ONE_ON_ONE_CONSULTATION_SERVICE, PERSONAL_STATEMENT_EVALUATION_SERVICE, PERSONAL_STATEMENT_POLISH_SERVICE, PERSONAL_STATEMENT_SERVICE, MOTIVATION_LETTER_SERVICE, RECOMMENDATION_LETTER_EVALUATION_SERVICE, RECOMMENDATION_LETTER_POLISH_SERVICE, RECOMMENDATION_LETTER_REQUEST_SERVICE, RECOMMENDATION_LETTER_SERVICE, SCHOOL_SELECTION_EXPERT_SERVICE, SCHOOL_SELECTION_SERVICE, STATEMENT_OF_PURPOSE_SERVICE, APPLICATION_SUBMISSION_SERVICE } from '../constants/home';

const HomeContext = React.createContext();

export const useHome = () => React.useContext(HomeContext);

export const HomeProvider = ({ children }) => {
  const navigate = useNavigate();
  const { setIsLoading, openDialog } = useDialog();
  const location = useLocation();
  const [mainTexts, setMainTexts] = React.useState({});
  const [servicesDisplay, setServicesDisplay] = React.useState(null);
  const [tabValue, setTabValue] = React.useState('1');
  const [searchOptions, setSearchOptions] = React.useState(null);
  const refs = React.useRef({});

  const handleSearchClick = (event, value) => {
    if (value && value.sequenceNumber) {
      setTabValue(value.sequenceNumber);
      refs.current[value.sequenceNumber].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      const offset = 100;
      const element = refs.current[value.sequenceNumber].current;
      const elementPosition = element.getBoundingClientRect().top - offset;
      window.scrollTo({
        top: elementPosition,
        behavior: 'smooth'
      });
    }
  };

  const handleTabClick = (event, newValue) => {
    setTabValue(newValue);
    const offset = 100;
    const element = refs.current[newValue].current;
    const elementPosition = element.getBoundingClientRect().top - offset;
    window.scrollTo({
      top: elementPosition,
      behavior: 'smooth'
    });
  };

  const handleScroll = React.useCallback(() => {
    const currentScrollPosition = window.scrollY;
    let selectedTab = null;
    Object.keys(refs.current).forEach((key) => {
      const ref = refs.current[key];
      if (ref.current && ref.current.offsetTop < currentScrollPosition + window.innerHeight / 2) {
        selectedTab = key;
      }
    });
    if (selectedTab !== null) {
      setTabValue(selectedTab);
    }
  }, []);

  const handleCreateFlow = async (flowType) => {
    setIsLoading(true);
    try {
      const resp = await authFetch('/flow/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ flowType: flowType })
      });

      window.location.href = resp.flowURL;

    } catch (error) {
      openDialog(DIALOG_TYPE_CONFIRM, {
        title: error.errorTitle,
        description: error.errorsInfo[0].message,
        confirmBtn: error.btnTexts ? error.btnTexts.confirmBtn : 'Confirm',
      }, () => { navigate(error.redirectURL) });
    } finally {
      setIsLoading(false);
    }
  };

  const serviceActionCreator = (service) => {
    const action = {
      actionId: service.id,
      actionData: {}
    };
    switch (service.id) {
      case PERSONAL_STATEMENT_SERVICE:
      case PERSONAL_STATEMENT_EVALUATION_SERVICE:
      case PERSONAL_STATEMENT_POLISH_SERVICE:
      case STATEMENT_OF_PURPOSE_SERVICE:
      case SCHOOL_SELECTION_SERVICE:
      case RECOMMENDATION_LETTER_SERVICE:
      case RECOMMENDATION_LETTER_EVALUATION_SERVICE:
      case RECOMMENDATION_LETTER_POLISH_SERVICE:
      case CV_COMPOSE_SERVICE:
      case CV_EVALUATION_SERVICE:
      case CV_POLISH_SERVICE:
      case EXPERT_REVIEW_SERVICE:
      case ONE_ON_ONE_CONSULTATION_SERVICE:
      case APPLICATION_SUBMISSION_SERVICE:
      case SCHOOL_SELECTION_EXPERT_SERVICE:
      case RECOMMENDATION_LETTER_REQUEST_SERVICE:
      case MOTIVATION_LETTER_SERVICE:
        action.actionData = {
          hasFlow: true,
          flowType: service.flowType,
          showConfirmDialog: true,
          points: service.points,
          dialogType: DIALOG_TYPE_CONSUMPTION_CONFIRM,
          consumptionUrl: '/flow/create'
        };
        break;
      case EXPERT_CUSTOMIZED_SERVICE:
      case MEDITATE_SERVICE:
        break;
      default:
        console.error('Service not found');
    };

    return action;
  }

  const handleServiceCardClick = async (service) => {
    const action = serviceActionCreator(service);
    if (action.actionData.showConfirmDialog) {
      const request = {
        dialogType: action.actionData.dialogType,
        consumptionUrl: action.actionData.consumptionUrl,
        flowType: action.actionData.flowType,
      }
      await fetchDialogInfo(request, async (info) => {
        openDialog(action.actionData.dialogType, info, async () => {
          await handleCreateFlow(service.flowType);
        })
      });
    }
    if (action.actionId === MEDITATE_SERVICE) {
      authFetch('/misc/get-meditate-info')
        .then(data => {
          openDialog(DIALOG_TYPE_PRAY, data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
    if (action.actionId === EXPERT_CUSTOMIZED_SERVICE) {
      navigate('/contact');
    }
  }

  React.useEffect(() => {
    authFetch('/home/get-home-display', {
      method: 'GET'
    })
      .then((data) => {
        setMainTexts(data.mainTexts);
        setServicesDisplay(data.servicesDisplay);
        const newRefs = {};
        data.servicesDisplay.forEach((category) => {
          newRefs[category.sequenceNumber] = React.createRef();
        });
        refs.current = newRefs;
        setSearchOptions(enrichSearchOptions(data));

        // wiat the DOM to be rendered
        setTimeout(() => {
          if (location.state && location.state.sequenceNumber && refs.current[location.state.sequenceNumber].current) {
            refs.current[location.state.sequenceNumber].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setTabValue(location.state.sequenceNumber);
          }
        }, 100);
      })
      .catch((error) => console.error('There was an error!', error));

  }, [location.state]);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // remove the event listener when the component is unmounted
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <HomeContext.Provider value={{ mainTexts, servicesDisplay, tabValue, searchOptions, refs, handleSearchClick, handleTabClick, handleServiceCardClick }}>
      {children}
    </HomeContext.Provider>
  );
}

function enrichSearchOptions(data) {
  const options = [];
  data.servicesDisplay.forEach((category) => {
    category.services.forEach((service) => {
      options.push({
        id: service.id,
        category: category.displayText,
        displayText: service.displayText,
        sequenceNumber: category.sequenceNumber
      });
    });
  });
  return options;
}