import React, { useState } from 'react';
import { Grid, Autocomplete, TextField, Dialog, IconButton, Button, Box, Tabs, Tab } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import QuestionBuilder from './questions/questionBuilder';
import authFetch from '../../utils/auth';
import QuestionPreview from './questions/questionPreview';
import SectionBuilder from './sections/sectionBuilder';
import SectionPreview from './sections/sectionPreview';
import FlowConfigBuilder from './flowConfigs/flowConfigBuilder';
import FlowConfigPreview from './flowConfigs/flowConfigPreview';
import { useNavigate } from 'react-router-dom';
import PromptPreview from './prompts/promptPreview';
import PromptBuilder from './prompts/promptBuilder';
import GenerationBuilder from './generations/generationBuilder';
import GenerationPreview from './generations/generationPreview';
import FlowGenerationBuilder from './flowConfigs/flowGenerationBuilder';
import { useInternal } from '../../context/internalProvider';

const TAB_QUESTIONS = 0;
const TAB_SECTIONS = 1;
const TAB_FLOWS = 2;
const TAB_PROMPTS = 3;
const TAB_GENERATIONS = 4;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function QuestionnaireManager() {
  const navigate = useNavigate();
  const { sections, questions, generations, flowConfigs, prompts, fetchSections, fetchQuestions, fetchFlowConfigs, fetchPrompts, fetchGenerations } = useInternal();

  const [selectedTab, setSelectedTab] = React.useState(TAB_QUESTIONS);

  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [isQuestionDialogOpen, setIsQuestionDialogOpen] = useState(false);

  const [currentSection, setCurrentSection] = useState(null);
  const [isSectionDialogOpen, setIsSectionDialogOpen] = useState(false);

  const [currentFlowConfig, setCurrentFlowConfig] = useState(null);
  const [isFlowConfigDialogOpen, setIsFlowConfigDialogOpen] = useState(false);
  const [isFlowGenerationDialogOpen, setIsFlowGenerationDialogOpen] = useState(false);

  const [currentPrompt, setCurrentPrompt] = useState(null);
  const [isPromptDialogOpen, setIsPromptDialogOpen] = useState(false);

  const [currentGeneration, setCurrentGeneration] = useState(null);
  const [isGenerationDialogOpen, setIsGenerationDialogOpen] = useState(false);


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleAddQuestion = () => {
    setCurrentQuestion(null);
    setIsQuestionDialogOpen(true);
  };

  const handleSelectQuestion = (question) => {
    setCurrentQuestion(question);
  };

  const handleOpenQuestionDialog = () => {
    setIsQuestionDialogOpen(true);
  }

  const handleCloseQuestionDialog = () => {
    setIsQuestionDialogOpen(false);
  };

  const handleQuestionSubmit = () => {
    handleCloseQuestionDialog();
    setCurrentQuestion(null);
    fetchQuestions();
  };

  const handleAddSection = () => {
    setCurrentSection(null);
    setIsSectionDialogOpen(true);
  };

  const handleSelectSection = (section) => {
    setCurrentSection(section);
  };

  const handleOpenSectionDialog = () => {
    setIsSectionDialogOpen(true);
  }

  const handleCloseSectionDialog = () => {
    setIsSectionDialogOpen(false);
  };

  const handleSectionSubmit = () => {
    handleCloseSectionDialog();
    setCurrentSection(null);
    fetchSections();
  };

  const handleSelectFlowConfig = (flowConfig) => {
    setCurrentFlowConfig(flowConfig);
  };

  const handleAddFlowConfig = () => {
    setCurrentFlowConfig(null);
    setIsFlowConfigDialogOpen(true);
  };

  const handleOpenFlowConfigDialog = () => {
    setIsFlowConfigDialogOpen(true);
  }

  const handleCloseFlowConfigDialog = () => {
    setIsFlowConfigDialogOpen(false);
  };

  const handleFlowConfigSubmit = () => {
    handleCloseFlowConfigDialog();
    setCurrentFlowConfig(null);
    fetchFlowConfigs();
  };

  const handleAddPrompt = () => {
    setCurrentPrompt(null);
    setIsPromptDialogOpen(true);
  };

  const handleSelectPrompt = (prompt) => {
    setCurrentPrompt(prompt);
  };

  const handleOpenPromptDialog = () => {
    setIsPromptDialogOpen(true);
  }

  const handleClosePromptDialog = () => {
    setIsPromptDialogOpen(false);
  };

  const handlePromptSubmit = () => {
    handleClosePromptDialog();
    setCurrentPrompt(null);
    fetchPrompts();
  };

  const handleAddGeneration = () => {
    setCurrentGeneration(null);
    setIsGenerationDialogOpen(true);
  };

  const handleSelectGeneration = (generation) => {
    setCurrentGeneration(generation);
  };

  const handleOpenGenerationDialog = () => {
    setIsGenerationDialogOpen(true);
  }

  const handleCloseGenerationDialog = () => {
    setIsGenerationDialogOpen(false);
  };

  const handleGenerationSubmit = () => {
    handleCloseGenerationDialog();
    setCurrentGeneration(null);
    fetchGenerations();
  };

  const handleOpenFlowGenerationDialog = () => {
    setIsFlowGenerationDialogOpen(true);
  }

  const handleCloseFlowGenerationDialog = () => {
    setIsFlowGenerationDialogOpen(false);
  };

  const handleCreateFlow = async () => {
    try {
      const resp = await authFetch('/flow/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ flowType: currentFlowConfig.id })
      });
      navigate(resp.flowURL);
    } catch (error) {
      console.error("There was an error!", error);
    }
  }

  return (
    <Grid container spacing={2} sx={{mt: 3}}>
      <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', ml: 5 }}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Questions" id='tab-questions' />
          <Tab label="Sections" id='tab-sections' />
          <Tab label="Flows" id='tab-flows' />
          <Tab label="Prompts" id='tab-prompts' />
          <Tab label="Generations" id='tab-generations' />
        </Tabs>
      </Box>
      <Grid item xs={12} md={8}>
        <CustomTabPanel value={selectedTab} index={TAB_QUESTIONS}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddQuestion}
            sx={{ mb: 2, ml: 3, mt: 3, boxShadow: 'none' }} // Margin bottom for spacing
          >
            Add New Question
          </Button>
          <Autocomplete
            disablePortal
            id="question-search"
            options={questions}
            value={currentQuestion}
            getOptionLabel={(option) => option.id || ""}
            sx={{ width: '100%', p: 3 }}
            renderInput={(params) => <TextField {...params} label="Search Questions" />}
            onChange={(event, value) => handleSelectQuestion(value)}
          />
          {currentQuestion !== null ? (
            <QuestionPreview question={currentQuestion} onEdit={handleOpenQuestionDialog} />
          ) : (<></>)}
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={TAB_SECTIONS}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddSection}
            sx={{ mb: 2, ml: 3, mt: 3, boxShadow: 'none' }} // Margin bottom for spacing
          >
            Add New Section
          </Button>
          <Autocomplete
            disablePortal
            id="section-search"
            options={sections}
            value={currentSection}
            getOptionLabel={(option) => option.id || ""}
            sx={{ width: '100%', p: 3 }}
            renderInput={(params) => <TextField {...params} label="Search Sections" />}
            onChange={(event, value) => handleSelectSection(value)}
          />
          {currentSection !== null ? (
            <SectionPreview section={currentSection} onEdit={handleOpenSectionDialog} />
          ) : (<></>)}
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={TAB_FLOWS}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddFlowConfig}
            sx={{ mb: 2, ml: 3, mt: 3, boxShadow: 'none' }} // Margin bottom for spacing
          >
            Add New Flow Config
          </Button>
          <Autocomplete
            disablePortal
            id="flow-config-search"
            options={flowConfigs}
            value={currentFlowConfig}
            getOptionLabel={(option) => option.id || ""}
            sx={{ width: '100%', p: 3 }}
            renderInput={(params) => <TextField {...params} label="Search Flow configs" />}
            onChange={(event, value) => handleSelectFlowConfig(value)}
          />
          {currentFlowConfig !== null ? (
            <FlowConfigPreview
              flowConfig={currentFlowConfig}
              onEdit={handleOpenFlowConfigDialog}
              onCreate={handleCreateFlow}
              onEditGeneration={handleOpenFlowGenerationDialog}
            />
          ) : (<></>)}
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={TAB_PROMPTS}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddPrompt}
            sx={{ mb: 2, ml: 3, mt: 3, boxShadow: 'none' }} // Margin bottom for spacing
          >
            Add New Prompt
          </Button>
          <Autocomplete
            disablePortal
            id="prompt-search"
            options={prompts}
            value={currentPrompt}
            getOptionLabel={(option) => option.id || ""}
            sx={{ width: '100%', p: 3 }}
            renderInput={(params) => <TextField {...params} label="Search Prompts" />}
            onChange={(event, value) => handleSelectPrompt(value)}
          />
          {currentPrompt !== null ? (
            <PromptPreview
              prompt={currentPrompt}
              onEdit={handleOpenPromptDialog}
            />
          ) : (<></>)}
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={TAB_GENERATIONS}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddGeneration}
            sx={{ mb: 2, ml: 3, mt: 3, boxShadow: 'none' }} // Margin bottom for spacing
          >
            Add New Generation
          </Button>
          <Autocomplete
            disablePortal
            id="generation-search"
            options={generations}
            value={currentGeneration}
            getOptionLabel={(option) => option.id || ""}
            sx={{ width: '100%', p: 3 }}
            renderInput={(params) => <TextField {...params} label="Search Generations" />}
            onChange={(event, value) => handleSelectGeneration(value)}
          />
          {currentGeneration !== null ? (
            <GenerationPreview
              generation={currentGeneration}
              onEdit={handleOpenGenerationDialog}
              prompts={prompts}
            />
          ) : (<></>)}
        </CustomTabPanel>
      </Grid>

      <Dialog
        open={isQuestionDialogOpen}
        aria-labelledby="question-builder-dialog"
        maxWidth="md"
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseQuestionDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <QuestionBuilder questionProp={currentQuestion} onSubmit={handleQuestionSubmit} />
      </Dialog>

      <Dialog
        open={isSectionDialogOpen}
        aria-labelledby="section-builder-dialog"
        maxWidth="md"
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseSectionDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <SectionBuilder
          sectionProp={currentSection}
          onSubmit={handleSectionSubmit}
        />
      </Dialog>

      <Dialog
        open={isFlowConfigDialogOpen}
        aria-labelledby="flow-config-builder-dialog"
        maxWidth="md"
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseFlowConfigDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <FlowConfigBuilder
          flowConfigProp={currentFlowConfig}
          onSubmit={handleFlowConfigSubmit}
        />
      </Dialog>

      <Dialog
        open={isFlowGenerationDialogOpen}
        aria-labelledby="flow-generation-builder-dialog"
        maxWidth="md"
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseFlowGenerationDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <FlowGenerationBuilder
          flowConfigProp={currentFlowConfig}
          onSubmit={() => {
            handleFlowConfigSubmit();
            handleCloseFlowGenerationDialog();
          }}
        />
      </Dialog>

      <Dialog
        open={isPromptDialogOpen}
        aria-labelledby="prompt-builder-dialog"
        maxWidth="md"
      >
        <IconButton
          aria-label="close"
          onClick={handleClosePromptDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <PromptBuilder
          promptProp={currentPrompt}
          onSubmit={handlePromptSubmit}
        />
      </Dialog>

      <Dialog
        open={isGenerationDialogOpen}
        aria-labelledby="generation-builder-dialog"
        maxWidth="md"
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseGenerationDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <GenerationBuilder
          generationProp={currentGeneration}
          onSubmit={handleGenerationSubmit}
        />
      </Dialog>
    </Grid>
  );
}

export default QuestionnaireManager;
