import * as React from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import googleIcon from '../../assets/icons/iconGoogle.png';
import emailIcon from '../../assets/icons/iconEmailFrame.png';
import { useAuth } from '../../context/authProvider';
import logo from '../../assets/logo_black.png';

function Footer(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'See '}
      <Link color="inherit" href="/terms">
        terms of service
      </Link>
      {' and '}
      <Link color="inherit" href="/privacy">
        privacy policy
      </Link>
      {'.'}
    </Typography>
    /*
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://foxiapply.com/">
        LYDE
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
    */
  );
}

export default function SignIn({ signInDisplay }) {
  const { signInWithAuthing, signInWithGoogle } = useAuth();

  return (
    <Box
      sx={{
        paddingY: '50px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'bg2.main',
        gap: '40px',
        width: '480px',
      }}
    >
      <Typography variant="h5" color="primary">
        {signInDisplay.title ? signInDisplay.title : 'Sign in'}
      </Typography>
      <Box>
        <img src={logo} alt='' style={{ verticalAlign: 'middle', width: 160, height: 25.1, }} />
      </Box>
      <Typography variant="caption_small" sx={{ width: 360 }}>
        {signInDisplay.description ? signInDisplay.description : 'Your account will be automatically created the first time you sign in.'}
      </Typography>
      <Box sx={{
        padding: '0 40px',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'stretch',
        gap: '20px',
      }}>
        <Button fullWidth
          variant="outlined"
          sx={{
            display: 'flex',
            height: '68px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'stretch',
            borderRadius: '20px',
            borderColor: '#D97757',
          }}
          onClick={signInWithAuthing}
        >
          <img src={emailIcon} alt="Email" style={{ width: 40, height: 40, marginRight: 20 }} />
          <Typography variant="body1" fontWeight='500' color='primary.main'>
            {signInDisplay.emailOptionText ? signInDisplay.emailOptionText : 'Sign in with Email'}
          </Typography>
        </Button>
        <Button fullWidth
          variant="outlined"
          sx={{
            display: 'flex',
            height: '68px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'stretch',
            borderRadius: '20px',
            borderColor: '#1976D2',
          }}
          onClick={signInWithGoogle}
        >
          <img src={googleIcon} alt="Google" style={{ width: 40, height: 40, marginRight: 20 }} />
          <Typography variant="body1" fontWeight='500' color='#1976D2'>
            {signInDisplay.googleOptionText ? signInDisplay.googleOptionText : 'Sign in with Google'}
          </Typography>
        </Button>
      </Box>
      <Footer />
    </Box>
  );
}