export function getQuestionDisplay(q, locale, questions) {
  let childQuestionDisplays = [];
  if (q.childQuestionIDs) {
    for (const qID of q.childQuestionIDs) {
      const cq = questions.find(qq => qq.id === qID);
      childQuestionDisplays.push(getQuestionDisplay(cq, locale, questions));
    }
  }
  const wordCountTextMap = {
    'en': 'Word Count',
    'zh': '字数',
    'zh-HK': '字數',
  }
  return {
    id: q.id,
    isMandatory: q.isMandatory,
    type: q.type,
    instructionDisplay: q.instruction[locale],
    hasHint: q.hasHint,
    hintDisplay: q.hasHint ? q.hint[locale] : undefined,
    summary: q.summary,
    // for select type question
    options: q.options !== undefined ? q.options.map((option) => ({
      value: option.value,
      textDisplay: option.text[locale],
    })) : undefined,
    // for text input question
    textInputType: q.textInputType !== undefined ? q.textInputType : undefined,
    hasUpload: q.hasUpload,
    wordCountText: wordCountTextMap[locale],
    childQuestionDisplays: childQuestionDisplays,
    infoBlockName: q.infoBlockName? q.infoBlockName[locale] : undefined,
  }
}