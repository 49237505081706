import React from 'react';
import { Chip, Typography } from '@mui/material';

function convertUserTier(tier) {
	switch (tier) {
		case 1:
			return 'Free';
		case 2:
			return 'Paid';
		case 3:
			return 'Subscribed';
		default:
			return 'Unknown';
	}
}

function setChipColor(tier) {
	switch (tier) {
		case 1:
			return 'secondary';
		case 2:
			return 'success';
		case 3:
			return 'primary';
		default:
			return 'error';
	}
}

export default function UserTierChip({ tier }) {
	return (
		<Chip label={
			<Typography variant='card_chip' color='white'>{convertUserTier(tier)}</Typography>
		} size='small' color={setChipColor(tier)} sx={{ width: 'wrap', height: '19px' }} />
	);
}