import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import { GenerationViewer } from '../lydia/generationViewer'

export default function GenerationViewerDialog({ open, handleClose, content }) {
    const [selectedFlowGeneration, setSelectedFlowGeneration] = React.useState(null);
    const [selectedFlow, setSelectedFlow] = React.useState(null);

    React.useEffect(() => {
        setSelectedFlowGeneration(content.selectedFlowGeneration);
        setSelectedFlow(content.selectedFlow);
    }, [content]);

    return (
        <Dialog open={open} fullWidth maxWidth='xl'>
            <DialogTitle>Generation Viewer</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                {selectedFlowGeneration && selectedFlow && <GenerationViewer generation={selectedFlowGeneration} flowData={selectedFlow} />}
            </DialogContent>
        </Dialog>
    )
}